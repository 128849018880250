import React, { useEffect, useState } from "react";
import moment from "moment";
import styles from "./tenantlist.module.css";
import { Link, useHistory } from "react-router-dom";
import { Home, Search, Calendar, Plus, X, ArrowRight, Filter, ArrowLeft } from "react-feather";
import Helmet from "react-helmet";
import { TableLoader } from "../../components/LoaderC";
import Footer from "../../components/Footer";
import axios from "axios";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import AddForm from "./components/AddForm";
import { FormControl, InputLabel, MenuItem, Select, Typography, Snackbar, Slide, IconButton, Dialog, DialogTitle, DialogContent, TablePagination } from "@mui/material";
import DateRangePicker from "rsuite/DateRangePicker";
import PeoplePageTitle from "./components/PeoplePageTitle";

const StatusFilterList = [
  { value: "all", label: "All" },
  { value: "Active", label: "Active" },
  { value: "Archived", label: "Archived" },
];

const TenantList = () => {
  const redirect = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [contentLoading, setContentLoading] = useState(true);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [filterData, setFilterData] = useState({ searchKey: "", view: "", property: "", created: "" });
  const [page, setPage] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [perPage, setPerPage] = useState(8);

  useEffect(() => {
    const getPropertyList = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_APPLICANT_API_URL + "v1/get-all-properties", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        let propertyData = response.data.data;

        propertyData = [{ value: "all", label: "Any", apartments: [] }].concat(propertyData);
        setPropertyList(propertyData);

        if (localStorage.getItem("propertyFilterData")) {
          let filterPropertyId = localStorage.getItem("propertyFilterData");
          localStorage.removeItem("propertyFilterData");

          setFilterData(prev => { return { ...prev, property: parseInt(filterPropertyId) } });
        }
      });
    }

    getPropertyList();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const getTenantList = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_TENANT_API_URL + "v1/tenants", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        setAllData(response.data.data);
      }).catch(() => {
        setContentLoading(false);
      });
    }

    getTenantList();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (allData.length > 0) {
      let filteredDataTemp = allData;

      if (filterData.searchKey !== "") {
        let inputVal = filterData.searchKey;
        inputVal = inputVal.toLowerCase();
        filteredDataTemp = filteredDataTemp.filter((item) => {
          return (item.property.name.toLowerCase().includes(inputVal) || item.apartment.apt_no.toLowerCase().includes(inputVal) || item.first_tenant.full_name.toLowerCase().includes(inputVal) || item.first_tenant.phone.toLowerCase().includes(inputVal) || item.first_tenant.email.toLowerCase().includes(inputVal));
        });
      }

      if (filterData.view !== "" && filterData.view !== "all") {
        if (filterData.view === "Active") {
          filteredDataTemp = filteredDataTemp.filter((i) => i.is_archived === 0);
        }
        if (filterData.view === "Archived") {
          filteredDataTemp = filteredDataTemp.filter((i) => i.is_archived === 1);
        }
      }
      if (filterData.property !== "" && filterData.property !== "all") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.property.id === filterData.property);
      }

      if (filterData.created && filterData.created !== "") {
        filteredDataTemp = filteredDataTemp.filter((i) => {
          var dateR = filterData.created;
          if (moment(moment(i.created_at).format("YYYY-MM-DD")).isBetween(moment(dateR[0]).format("YYYY-MM-DD"), moment(dateR[1]).format("YYYY-MM-DD"), undefined, [])) {
            return true;
          }
          return false;
        });
      }

      setFilteredData(filteredDataTemp);
    }
  }, [allData, filterData]);


  const addTenant = (data) => {
    setOpenAddForm(false);
    setSnackbarMsg("Tenant(s) have been added successfully.");
    setSnackbarOpen(true);

    setAllData((prev) => {
      return [data].concat(prev);
    });
  }

  return (<>
    <Helmet title="Tenants" />

    <div className={`${styles.pageTitleWrap}`}>
      <PeoplePageTitle defaultPage={'Tenants'} />
      <Typography display="inline" className={`${styles.pageTitle} ${styles.Big}`}>Tenants</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>People</li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>Tenants</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.BodyCon}`}>
      <div className={`${styles.mainCardDiv}`}>
        <div className={`${styles.mainCard}`}>

          {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

          {!contentLoading && (<div className="control-pane">
            <div className={`${styles.Row}`}>

              <div className={`${styles.SearchFilterSec}`}>
                <div className={`${styles.SearchCon}`}>
                  <div className={`${styles.SearchConSec}`}>
                    <Search />
                    <input type="text" placeholder="Search" onChange={(e) => setFilterData(prev => {
                      return { ...prev, searchKey: e.target.value }
                    })} />
                  </div>
                </div>

                <div className={`${styles.FilterSec}`}>
                  <div className={filterOpen === true ? `${styles.FilterFieldSec} ${styles.Open}` : `${styles.FilterFieldSec}`}>
                    <p className={`${styles.FilterTitle}`}>Filters<span onClick={(e) => setFilterOpen(false)}><ArrowRight /></span></p>
                    <div className={`${styles.FilterCard} ${styles.Select} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Property</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Property"
                          value={filterData?.property}
                          onChange={(e) => setFilterData(prev => {
                            return { ...prev, property: e.target.value }
                          })}
                        >
                          {propertyList.map((i) => {
                            return (<MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Status"
                          onChange={(e) => setFilterData(prev => {
                            return { ...prev, view: e.target.value }
                          })}
                        >
                          {StatusFilterList.map((i, index) => {
                            return (<MenuItem key={index} value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} Filter`}>
                      <div className={`${styles.DashFilterSec} DashFilterSec`}>
                        <DateRangePicker
                          format={"M/d/yy"}
                          placeholder={"Filter by date"}
                          placement={"bottomEnd"}
                          caretAs={Calendar}
                          showOneCalendar
                          onChange={(e) => setFilterData(prev => {
                            return { ...prev, created: e }
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <button className={`${styles.FilterBu}`} onClick={(e) => { setFilterOpen(true); }}><Filter /></button>
                  <button className={`${styles.AddNoteBu}`} onClick={(e) => { setOpenAddForm(true); }}><Plus /> <span>Tenant</span></button>
                </div>
              </div>

              <div className={`${styles.NotesRow}`}>

                {filteredData.map((item, index) => {
                  return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Link className={`${styles.ProspectCard}`} key={index} to={`/people/tenants/details/${item.id}`}>
                    <div className={`${styles.ProspectCardUnder}`}>
                      <div className={`${styles.ProspectCardHeadSection}`}>
                        <div className={`${styles.ProsIMGSec}`}>
                          {(item?.first_tenant?.user_image !== '') ? <img src={item?.first_tenant?.user_image} className={`${styles.ProspectCardImg}`} alt="" /> : <p>{item?.first_tenant?.initial}</p>}
                        </div>

                        <div className={`${styles.HeadConSec}`}>
                          <div className={`${styles.prospectNameActionSec}`}>
                            <p className={`${styles.ProsUserName} ${styles.LimitName}`}>
                              {item?.first_tenant?.full_name}
                              {item?.first_tenant?.verification_status === 1 && (<img src="/static/img/verified.svg" className={`${styles.Varified}`} alt="" />)}
                            </p>
                            {item?.is_archived === 1 && <span className={`${styles.ArchivedBadge}`}>Archived</span>}
                          </div>
                          <p className={`${styles.ProsUserName}`}>{item?.first_tenant?.phone}</p>
                        </div>
                      </div>

                      <div className={`${styles.ProspectCardBodySec}`}>
                        <ul>
                          <li>
                            <p className={`${styles.Title}`}><img src="/static/img/bank-icon.svg" alt="" />Balance :</p>
                            <p className={`${styles.Content}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); localStorage.setItem("propertyFilterData", item?.property_id); localStorage.setItem("apartmentFilterData", item?.apt_id); redirect.push('/payments'); }}>{(item.balance < 0 ? <span className={`${styles.InfoStatusBadge} ${styles.Resolved}`}>{item?.balancestr} Over Paid</span> : (item.balance === 0 ? <span className={`${styles.InfoStatusBadge} ${styles.Resolved}`}>{item?.balancestr} Balance</span> : <span className={`${styles.InfoStatusBadge} ${styles.Ongoing}`}>{item?.balancestr} Due</span>))}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}><img src="/static/img/RequestM-Black.svg" alt="" />Maintenance :</p>
                            <p className={`${styles.Content}`}>
                              {item?.requests?.total === 0 && "-"}
                              {(item?.requests?.total > 0 && item?.requests?.open > 0) && <span className={`${styles.InfoStatusBadge} ${styles.Open}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); localStorage.setItem("propertyFilterData", item?.property_id); localStorage.setItem("apartmentFilterData", item?.apt_id); localStorage.setItem("statusFilterData", "Open"); redirect.push('/cases/maintenance-request'); }}>{item?.requests?.open} Open</span>}
                              {(item?.requests?.total > 0 && item?.requests?.ongoing > 0) && <span className={`${styles.InfoStatusBadge} ${styles.Ongoing}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); localStorage.setItem("propertyFilterData", item?.property_id); localStorage.setItem("apartmentFilterData", item?.apt_id); localStorage.setItem("statusFilterData", "In-Progress"); redirect.push('/cases/maintenance-request'); }}>{item?.requests?.ongoing} Ongoing</span>}
                            </p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}><img src="/static/img/offline-deviceB.svg" alt="" />Offline Devices :</p>
                            <p className={`${styles.Content}`}>{item?.devices?.offline === 0 ? '-' : <span className={`${styles.InfoStatusBadge} ${styles.Cancel}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); localStorage.setItem("propertyFilterData", item?.property_id); localStorage.setItem("aptFilterData", item?.apt_id); localStorage.setItem("statusFilterData", 0); redirect.push('/smart-devices/list'); }}>{item?.devices?.offline} Offline Devices</span>}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}><img src="/static/img/LeaseIconB.svg" alt="" />Lease :</p>
                            <p className={`${styles.Content} ${styles.Hover}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); localStorage.setItem("leasePropertyFilterData", item?.property_id); localStorage.setItem("leaseApartmentFilterData", item?.apt_id); redirect.push('/leases'); }}>{moment(item?.leases?.end_date).format('MM/DD/YYYY')}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}><img src="/static/img/home.svg" alt="" />Property :</p>
                            <p className={`${styles.Content} ${styles.Hover}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); redirect.push('/properties/details/' + item?.property_id); }}>{item?.property?.name}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}><img src="/static/img/rooms.svg" alt="" />Unit :</p>
                            <p className={`${styles.Content}`}>{item?.apartment?.apt_no}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>) : null;
                })}

                {filteredData.length > 0 && (<div className="Pagination TablePaginationNew List">
                  <TablePagination
                    count={filteredData.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                      { label: '4', value: 4 },
                      { label: '8', value: 8 },
                      { label: '16', value: 16 },
                      { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                      setPage(0);
                      setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Tenants per Page:'}
                  />
                </div>)}

                {!contentLoading && filteredData.length === 0 && (<div className={`${styles.NoDataMain}`}>
                  <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/no-tenant.png" alt="No Data" />
                  </div>
                  <p className={`${styles.NoDataText}`}>Unfortunately!</p>
                  <p className={`${styles.NoDataTextSub}`}>we couldn't find a tenant at this time.</p>
                </div>)}

              </div>
            </div>
          </div>)}
        </div>
      </div>
    </div>

    <Dialog
      open={openAddForm}
      onClose={(e) => { setOpenAddForm(false); }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm CustomWidthPops"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => { setOpenAddForm(false); }}><ArrowLeft /></button>
        Add Tenant
        <button onClick={(e) => { setOpenAddForm(false); }}>
          <X />
        </button>
      </DialogTitle>
      <DialogContent className={`${styles.RequestBodyPop}`}>
        <div className={`${styles.AddTenant}`}><AddForm closeAddForm={setOpenAddForm.bind(this)} addTenant={addTenant.bind(this)} /></div>
      </DialogContent>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </>)

}


export default withAuthenticationRequired(TenantList, {
  onRedirecting: () => <Loader />,
});