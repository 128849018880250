import React, { useState, useEffect } from "react";
import styles from "./propertydetails.module.css";
import { Link, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import { Home, X, ArrowLeft } from "react-feather";
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, IconButton, Slide } from "@mui/material";
import Footer from "../../components/Footer";
import axios from "axios";
import { TableLoader } from "../../components/LoaderC";
import { toast } from "react-smart-toaster";
import Loader from "../../components/Loader";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../components/AddLogs";
import { useAuth0 } from "@auth0/auth0-react";
import EditPropertyFormCom from "./components/EditPropertyFormCom";
import PropertyCard from "./components/PropertyCard";
import SimpleTabs from "./components/SimpleTabs";

const PropertyDetails = ({ history, match }) => {
  const { id } = match.params;
  const redirect = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [propertyData, setPropertyData] = useState({});
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    async function fetchPropertyDetails() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      setContentLoading(true);
      await axios.get(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        setPropertyData(response.data.data);
      }).catch(error => {
        setContentLoading(false);
      });
    }

    fetchPropertyDetails();
  }, [getAccessTokenSilently, id]);

  const onEditSubmitLocal = (data) => {
    setSnackbarMsg(data.message);
    setSnackbarOpen(true);

    let rData = data.data;
    setPropertyData((prev) => {
      return {
        ...prev,
        address: rData?.address,
        city: rData?.city,
        email: rData?.email,
        fulladdress: rData?.fulladdress,
        landlord_agent: rData?.landlord_agent,
        latitude: rData?.latitude,
        longitude: rData?.longitude,
        name: rData?.name,
        no_of_floor: rData?.no_of_floor,
        phone: rData?.phone,
        state: rData?.state,
        zip: rData?.zip,
        new_image: rData?.new_image,
      };
    });
  }

  const archivePropertyConfirm = async () => {
    setLoading(true);
    setArchiveModalOpen(false);
    let is_archived = 1 - propertyData.is_archived;
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    axios.put(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties-new/" + propertyData.id, { is_archived: is_archived }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);
      let respData = response.data.data;
      setPropertyData((prev) => {
        return { ...prev, is_archived: is_archived };
      });

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let logData = {
        title: respData.is_archived ? "Property is archived" : "Property is unarchived",
        description: [
          "Name: " + respData.name,
          "Address: " + respData.fulladdress,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  const deletePropertyConfirm = async () => {
    setLoading(true);
    setDeleteModalOpen(false);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    return axios.delete(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + propertyData.id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let respData = response.data.data;

      let logData = {
        title: "Property is deleted",
        description: [
          "Name: " + respData.name,
          "Address: " + respData.fulladdress,
        ],
      };
      AddLogCallAPI(logData, token);

      setTimeout(() => {
        redirect.push("/properties/list");
      }, 2000);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  return <>
    {loading && <Loader />}



    <Helmet title={propertyData?.name} />

    <div className={`${styles.mainCard}`}>
      {contentLoading && (<div className="Loader TableLoader">
        <TableLoader />
      </div>)}

      {!contentLoading && (<div className={`${styles.SMProCusRow}`}>
        <div className={`${styles.BreadcrumbHeadSec}`}>

          <nav aria-label="breadcrumb">
            <ol className={`${styles.breadcrumb}`}>
              <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Properties</li>
              <li className={`${styles.breadcrumbItem}`}><Link to={"/properties/list"}>All Properties</Link></li>
              <li className={`${styles.breadcrumbItem} ${styles.active}`}>{propertyData?.name}</li>
            </ol>
          </nav>

          <div className={`${styles.PageTitleSec}`}>
            <div>
              <button onClick={() => redirect.goBack()} className={`${styles.BackArrow}`}><ArrowLeft /></button>
              <Typography display="inline" className={`${styles.pageTitle}`}>{propertyData?.name}</Typography>
            </div>
          </div>
        </div>

        <PropertyCard data={propertyData} setEditFormOpen={setEditFormOpen.bind(this)} setArchiveModalOpen={setArchiveModalOpen.bind(this)} setDeleteModalOpen={setDeleteModalOpen.bind(this)} />

        <div className={`${styles.SMProCusBigDiv}`}>
          <div className={`${styles.card}`}>
            <div className={`${styles.cardbody}`}>
              <div className={`${styles.SMBigSideBody} TabMainClientProfile ClientDetails`}>
                <SimpleTabs
                  property_id={propertyData?.id}
                  property_name={propertyData?.name}
                  url_token={propertyData?.url_token}
                  no_of_floor={propertyData?.no_of_floor}
                  layouts={propertyData?.layouts}
                  property_units={propertyData?.property_units}
                  rental_application={propertyData?.rental_application}
                  app_permissions={propertyData?.app_permissions}
                  app_theme_options={propertyData?.app_theme_options}
                  late_fee_data={{ 'is_auto_late_fee': propertyData?.is_auto_late_fee, 'grace_period': propertyData?.grace_period, 'type_of_fee': propertyData?.type_of_fee, 'fee_amount': propertyData?.fee_amount }}
                  doorlock_password={propertyData?.doorlock_password}
                  lease_documents={propertyData?.lease_documents}
                  setSnackbarOpen={setSnackbarOpen.bind(this)}
                  setSnackbarMsg={setSnackbarMsg.bind(this)}
                  setPropertyData={setPropertyData.bind(this)}
                  prospect_criteria={{ min_credit_score: propertyData.min_credit_score, min_income_multiplier: propertyData.min_income_multiplier }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>)}
    </div>

    <Dialog
      open={archiveModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setArchiveModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to {propertyData?.is_archived === 1 ? "unarchive" : "archive"} the Property
        Record? <button onClick={(e) => setArchiveModalOpen(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">
              {propertyData?.is_archived === 1 ? "The Property Record will be unarchived immediately.You can archive it at any time." : "The Property Record will be archived immediately.You can unarchive it at any time."}
            </label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => archivePropertyConfirm()} className="CancelPopupBU">
          {propertyData?.is_archived === 1 ? "Unarchive" : "Archive"}
        </Button>
        <Button onClick={(e) => setArchiveModalOpen(false)} className="SubmitPopupBU">Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={deleteModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setDeleteModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to delete the Property Record? <button onClick={(e) => setDeleteModalOpen(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The Property Record will be removed immediately.You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => deletePropertyConfirm()} className="CancelPopupBU">Delete</Button>
        <Button onClick={(e) => setDeleteModalOpen(false)} className="SubmitPopupBU">Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={editFormOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setEditFormOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setEditFormOpen(false)}><ArrowLeft /></button>
        <span>Edit Property</span>
        <button onClick={(e) => setEditFormOpen(false)}>
          <X />
        </button>
      </DialogTitle>
      <DialogContent>
        <div className={`${styles.ProfileCardEditSec}`}>
          <EditPropertyFormCom setEditFormOpen={setEditFormOpen.bind(this)} itemData={propertyData} onSubmit={onEditSubmitLocal.bind(this)} />
        </div>

      </DialogContent>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </>
}

export default withAuthenticationRequired(PropertyDetails, {
  onRedirecting: () => <Loader />,
});