import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./rentroll.module.css";
import { ArrowRight, Filter, Home, Search } from "react-feather";
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import axios from "axios";
import { TableLoader } from "../../components/LoaderC";
import moment from "moment";
import { Typography, FormControl, InputLabel, Select, MenuItem, TablePagination } from "@mui/material";

const RentRoll = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [contentLoading, setContentLoading] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [allData, setAllData] = useState([]);
  const [filterredData, setFilterredData] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(8);
  const [filterData, setFilterData] = useState({ searchKey: '', property: '', apartment: '', payment_status: '', view: '' });
  const [propertyList, setPropertyList] = useState([]);
  const [apartmentList, setApartmentList] = useState([]);

  useEffect(() => {
    const fetchAllProperties = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      axios(process.env.REACT_APP_PAYMENT_API_URL + 'v1/property-list', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        let propertyData = response.data.data;

        propertyData = [{ value: 'all', label: 'All', apartments: [] }].concat(propertyData);
        setPropertyList(propertyData);

        if (localStorage.getItem("propertyFilterData")) {
          let filterPropertyId = localStorage.getItem("propertyFilterData");
          localStorage.removeItem("propertyFilterData");

          setFilterData(prev => { return { ...prev, property: parseInt(filterPropertyId) } });

          let apartmentListTemp = [];
          propertyData?.map(i => {
            if (i.value === parseInt(filterPropertyId)) {
              apartmentListTemp = i.apartments;
            }
            return null;
          });

          apartmentListTemp = [{ value: 'all', label: 'All', apartments: [] }].concat(apartmentListTemp);
          setApartmentList(apartmentListTemp);
        }

        if (localStorage.getItem("apartmentFilterData")) {
          let apartmentFilter = localStorage.getItem("apartmentFilterData");
          localStorage.removeItem("apartmentFilterData");
          apartmentFilter = parseInt(apartmentFilter);

          setFilterData(prev => { return { ...prev, apartment: parseInt(apartmentFilter) } });
        }

      });
    };
    fetchAllProperties();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    async function getAllData() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_PAYMENT_API_URL + "v1/rent-rolls", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        setAllData(response.data.data);
      }).catch((error) => {
        setContentLoading(false);
      });
    }
    getAllData();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    let filterredDataTemp = allData;

    if (filterData.searchKey !== "") {
      let inputVal = filterData.searchKey;
      inputVal = inputVal.toLowerCase();
      filterredDataTemp = filterredDataTemp.filter((item) => {
        return (item.tenant_name.toLowerCase().includes(inputVal) || item.property_name.toLowerCase().includes(inputVal) || item.apt_name.toLowerCase().includes(inputVal) || item.formatted_monthly_rent.toLowerCase().includes(inputVal) || item.formatted_balance.toLowerCase().includes(inputVal));
      });
    }

    if (filterData.property !== "" && filterData.property !== "all") {
      filterredDataTemp = filterredDataTemp.filter((i) => i.property_id === filterData.property);
    }

    if (filterData.apartment !== "" && filterData.apartment !== "all") {
      filterredDataTemp = filterredDataTemp.filter((i) => i.apt_id === filterData.apartment);
    }

    if (filterData.view !== "" && filterData.view !== "all") {
      if (filterData.view === "active") {
        filterredDataTemp = filterredDataTemp.filter((i) => i.is_archived === 0);
      }
      if (filterData.view === "archived") {
        filterredDataTemp = filterredDataTemp.filter((i) => i.is_archived === 1);
      }
    }

    if (filterData.payment_status !== "" && filterData.payment_status !== "all") {
      filterredDataTemp = filterredDataTemp.filter((i) => i.status === filterData.payment_status);
    }

    setFilterredData(filterredDataTemp);
  }, [allData, filterData]);

  const selectProperty = (e) => {
    let apartmentListTemp = [];
    propertyList?.map(i => {
      if (i.value === e) {
        apartmentListTemp = i.apartments;
      }
      return null;
    });

    apartmentListTemp = [{ value: 'all', label: 'All', apartments: [] }].concat(apartmentListTemp);
    setApartmentList(apartmentListTemp);
  }

  return (<React.Fragment>
    <Helmet title="Payment Rent Roll" />

    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>Rent Roll</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Payment</li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>Rent Roll</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.BodyCon}`}>
      <div className={`${styles.mainCardDiv}`}>
        <div className={`${styles.mainCard}`}>
          <div className="control-pane">

            {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

            <div className={`${styles.Row}`}>
              {!contentLoading && <>
                <div className={`${styles.SearchFilterSec}`}>
                  <div className={`${styles.SearchCon}`}>
                    <div className={`${styles.SearchConSec}`}>
                      <Search />
                      <input type="text" placeholder="Search" onChange={(e) => setFilterData(prev => { return { ...prev, searchKey: e.target.value } })} />
                    </div>
                  </div>

                  <div className={`${styles.FilterSec}`}>
                    <div className={filterOpen === true ? `${styles.FilterFieldSec} ${styles.Open}` : `${styles.FilterFieldSec}`}>
                      <p className={`${styles.FilterTitle}`}>Filters<span onClick={(e) => setFilterOpen(false)}><ArrowRight /></span></p>
                      <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width220} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Property</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Property"
                            value={filterData?.property}
                            onChange={(e) => { setFilterData(prev => { return { ...prev, property: e.target.value } }); selectProperty(e.target.value); }}
                          >
                            {propertyList?.map((i) => {
                              return (<MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>);
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width160} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Apartment</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Apartment"
                            value={filterData?.apartment}
                            onChange={(e) => { setFilterData(prev => { return { ...prev, apartment: e.target.value } });; }}
                          >
                            {apartmentList?.map((i) => {
                              return (<MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>);
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width160} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Status</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Status"
                            onChange={(e) => { setFilterData(prev => { return { ...prev, view: e.target.value } });; }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="archived">Archived</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width160} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Payment Status</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Payment Status"
                            onChange={(e) => { setFilterData(prev => { return { ...prev, payment_status: e.target.value } });; }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value={0}>Outstanding</MenuItem>
                            <MenuItem value={1}>Balance</MenuItem>
                            <MenuItem value={2}>Over Paid</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <Link className={`${styles.FilterBu}`} onClick={(e) => setFilterOpen(true)}><Filter /></Link>
                  </div>
                </div>

                <div className={`${styles.NotesRow}`}>
                  {filterredData.map((item, index) => {
                    return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Link className={`${styles.ProspectCard}`} to={"/payment/rent-roll/" + item.id + "/details"} key={index}>
                      <div className={`${styles.ProspectCardUnder}`}>
                        <div className={`${styles.ProspectCardHeadSection}`}>
                          <div className={`${styles.ProsIMGSec}`}>
                            {item?.tenant_image !== "" ? <img src={item?.tenant_image} className={`${styles.ProspectCardImg}`} alt="" /> : <p>{item?.tenant_initial}</p>}
                          </div>

                          <div className={`${styles.HeadConSec}`}>
                            <div className={`${styles.prospectNameActionSec}`}>
                              <p className={`${styles.ProsUserName}`}>{item.tenant_name} {item?.no_of_tenant > 1 && '+' + (item?.no_of_tenant - 1)}</p>
                            </div>
                            {item.balance < 0 && <div className={`${styles.InsideConCheck}`}>
                              <img src="/static/img/RollCheck.svg" alt="" />
                              <p>${item.formatted_balance} Over Paid</p>
                            </div>}
                            {item.balance === 0 && <div className={`${styles.InsideConCheck}`}>
                              <img src="/static/img/RollCheck.svg" alt="" />
                              <p>${item.formatted_balance} Balance</p>
                            </div>}
                            {item.balance > 0 && <div className={`${styles.InsideConCheck}`}>
                              <img src="/static/img/RollPending.svg" alt="" />
                              <p>${item.formatted_balance} Due</p>
                            </div>}
                          </div>
                        </div>
                        <div className={`${styles.ProspectCardBodySec}`}>
                          <ul>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/credit-icon.svg" alt="" />Last Payment :</p>
                              <p className={`${styles.Content}`}>{item.last_payment_date ? '$' + item.formatted_last_payment_amount : ' - '}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/calendar.svg" alt="" />Payment Date :</p>
                              <p className={`${styles.Content}`}>{item.last_payment_date ? moment(item.last_payment_date).format("MM/DD/YYYY") : ' - '}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/bill-icon.svg" alt="" />Monthly Rent :</p>
                              <p className={`${styles.Content}`}>${item.formatted_monthly_rent}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/home.svg" alt="" />Property :</p>
                              <p className={`${styles.Content}`}>{item.property_name}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/rooms.svg" alt="" />Unit :</p>
                              <p className={`${styles.Content}`}>{item.apt_name}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Link>) : null;
                  })}

                  {filterredData.length > 0 && (<div className="Pagination TablePaginationNew List">
                    <TablePagination
                      count={filterredData.length}
                      color="primary"
                      page={page}
                      rowsPerPage={perPage}
                      onPageChange={(e, v) => setPage(v)}
                      rowsPerPageOptions={[
                        { label: '4', value: 4 },
                        { label: '8', value: 8 },
                        { label: '16', value: 16 },
                        { label: 'All', value: -1 },
                      ]}
                      onRowsPerPageChange={(e) => {
                        setPage(0);
                        setPerPage(e.target.value);
                      }}
                      labelRowsPerPage={'Payments per Page:'}
                    />
                  </div>)}

                  {filterredData.length === 0 && (<div className={`${styles.NoDataMain}`}>
                    <div className={`${styles.NoDataIMG}`}>
                      <img src="/static/img/no-payment.png" alt="No Data" />
                    </div>
                    <p className={`${styles.NoDataText}`}>Unfortunately!</p>
                    <p className={`${styles.NoDataTextSub}`}>We haven't received any payment at this time.</p>
                  </div>)}

                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </React.Fragment>);
}

export default withAuthenticationRequired(RentRoll, {
  onRedirecting: () => <Loader />,
});