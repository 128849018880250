import styles from "../leases.module.css";
import { Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import { ListManager } from "react-beautiful-dnd-grid";
import { toast } from "react-smart-toaster";
import { AddLogCallAPI } from "../../../components/AddLogs";

function MoneyField(props) {
    return (<NumberFormat customInput={TextField} prefix="" displayType="input" type="text" thousandSeparator={true} allowNegative={false} decimalScale={2} fixedDecimalScale {...props} />);
}

function ListElement({ item: { id, title, index } }) {
    return <Chip className={'DropListNew'} key={id} label={index + 1 + ". " + title} />;
}

function sortList(list) {
    let listTemp = list.slice().sort((first, second) => first.order - second.order);
    listTemp = listTemp.map((i, index) => {
        return { ...i, index: index };
    });
    return listTemp;
}

const RenewLeaseForm = ({ setShowRenewForm, setLoading, setSnackbarOpen, setSnackbarMsg, onSubmitLocal, renewData }) => {
    const { getAccessTokenSilently } = useAuth0();
    const schema = yup.object().shape({
        primary_id: yup.string(),
        property: yup.string().required("This field is Required"),
        tenant: yup.string().required("This field is Required"),
        security_amount: yup.string().required("This field is Required").test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0).test("max-4-digit", "Security Amount can not be more than 6 digits.", (value) => {
            return parseInt(value?.replace(/,/g, "")) < 10000;
        }),
        rent: yup.string().required("This field is Required").test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0).test("max-4-digit", "Rent can not be more than 6 digits.", (value) => {
            return parseInt(value?.replace(/,/g, "")) < 10000;
        }),
        lease_term: yup.string().required("This field is Required"),
        start_date: yup.date().required("This field is Required"),
        end_date: yup.date().required("This field is Required"),
        next_bill_date: yup.date().required("This field is Required").nullable(),
        lease_type: yup.string().required("This field is Required"),
        docume_ids: yup.array().when("is_existing", (is_existing) => {
            if (is_existing && is_existing === "No")
                return yup.array().required("This field is Required");
            else return yup.array();
        }),
        new_security_amount: yup.string().when("lease_type", (lease_type) => {
            if (lease_type && lease_type === "Renewal")
                return yup.string().required("This field is Required").test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0).test("max-4-digit", "New Security Amount can not be more than 6 digits.", (value) => {
                    return parseInt(value?.replace(/,/g, '')) < 10000;
                });
            else return yup.string();
        }),
    });

    const [selectDocument, setSelectDocument] = useState([]);
    const [selectDocumentIds, setSelectDocumentIds] = useState([]);
    const [PropertyList, setPropertyList] = useState([]);
    const [TenantList, setTenantList] = useState([]);
    const [LeaseDocumentList, setLeaseDocumentList] = useState([]);
    const [selectLeaseType, setSelectLeaseType] = useState("Renewal");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sortedLeaseDocuments, setSortedLeaseDocuments] = useState(sortList([]));

    const { register, handleSubmit, control, errors, setValue, getValues, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            property: "",
            tenant: "",
            lease_type: "Renewal",
            lease_term: "1 Year",
            is_existing: "No"
        }
    });

    useEffect(() => {
        if (renewData) {
            setPropertyList([{ value: renewData?.property_id, label: renewData?.property_name }]);
            setValue('property', renewData?.property_id);

            setTenantList([{ value: renewData?.tenant_id, label: renewData?.tenant_name + ' - ' + renewData?.apt_no }]);
            setValue('tenant', renewData?.tenant_id);
            setValue('security_amount', renewData?.security_amount);
            setValue('primary_id', renewData?.lease_id);

            let startDateNew = dayjs(renewData?.end_date).add(1, "day");
            let endDateNew = dayjs(startDateNew).add(1, "year").subtract(1, "day");
            setValue("start_date", startDateNew);
            setValue("end_date", endDateNew);
            setValue("next_bill_date", startDateNew);
        }
    }, [renewData, setValue]);

    useEffect(() => {
        async function fetchLeaseDocuments() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios.get(process.env.REACT_APP_PAYMENT_API_URL + "v1/document-list/add-lease/" + renewData?.property_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setLeaseDocumentList(response.data.data);
            }).catch((error) => {
                setLeaseDocumentList([]);
            });
        }
        fetchLeaseDocuments();
    }, [getAccessTokenSilently, renewData?.property_id]);

    useEffect(() => {
        let ids = selectDocument.map((i) => {
            return i.id;
        });

        let docs = selectDocument.map((i, index) => {
            return { ...i, order: index, index: index };
        });

        setSelectDocumentIds(ids);

        setSortedLeaseDocuments(docs);
    }, [selectDocument]);

    const reorderList = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        const list = sortedLeaseDocuments;

        if (destinationIndex === 0) {
            list[sourceIndex].order = list[0].order - 1;

            setSortedLeaseDocuments(sortList(list));
            return;
        }

        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order = list[list.length - 1].order + 1;
            setSortedLeaseDocuments(sortList(list));
            return;
        }

        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
            setSortedLeaseDocuments(sortList(list));
            return;
        }

        list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
        setSortedLeaseDocuments(sortList(list));
    }

    const onSubmit = async (data) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        let docume_ids = "";
        if (data.is_existing === "No") {
            let dIds = sortedLeaseDocuments?.map((i) => {
                return i.id;
            });
            docume_ids = dIds.join();
        }

        let newSecurityAmount = 0;
        if (data.lease_type === "Renewal") {
            newSecurityAmount = data.new_security_amount.replace(/,/g, "") - data.security_amount.replace(/,/g, "");
            if (newSecurityAmount < 0) {
                newSecurityAmount = 0;
            }
        }

        let postData = {
            rent: data.rent.replace(/,/g, ""),
            security_amount: data.security_amount.replace(/,/g, ""),
            start_date: dayjs(data.start_date).format("YYYY-MM-DD"),
            end_date: dayjs(data.end_date).format("YYYY-MM-DD"),
            next_bill_date: dayjs(data.next_bill_date).format("YYYY-MM-DD"),
            tenant_id: data.tenant,
            first_rent: data.rent.replace(/,/g, ""),
            lease_term: data.lease_term,
            is_esign: data.is_existing === "Yes" ? 0 : 1,
            is_renew: data.lease_type === "Renewal" ? 1 : 0,
            new_security_amount: data.lease_type === "Renewal" ? newSecurityAmount : 0,
            primary_id: data?.primary_id,
            docume_ids: docume_ids
        };

        setLoading(true);
        return axios.post(process.env.REACT_APP_PAYMENT_API_URL + "v1/add-lease-renew", postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let message = response.data.message;

            let logData = {
                title: "A new lease is added",
                description: [
                    "Tenant: " + response.data?.data?.tenant_name,
                    "Property: " + response.data?.data?.property_name + " - " + response.data?.data?.apt_no,
                    "Lease Term: " + dayjs(response.data?.data?.start_date).format("MM/DD/YYYY") + " - " + dayjs(response.data?.data?.end_date).format("MM/DD/YYYY"),
                ],
            };
            AddLogCallAPI(logData, token);

            setSnackbarMsg(message);
            setSnackbarOpen(true);

            setShowRenewForm(false);
            onSubmitLocal(response.data.data);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<div className={`${styles.AddSection}`}>
        <form className={`${styles.AddLayoutForm}`} onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register("primary_id")} />
            <input type="hidden" {...register("is_existing")} />
            <div className={`${styles.SMSmallSideBody} TabMainClientProfile ClientDetails`}>
                <div className={`${styles.AddUserMain}`}>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroup} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Property*</InputLabel>
                                <Controller
                                    name="property"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            {...field}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Property*"
                                        >
                                            {PropertyList.map((item) => {
                                                return (<MenuItem value={item.value}>{item.label}</MenuItem>);
                                            })}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors.property && (<p className={`${styles.ErrorM}`}>{errors.property.message}</p>)}
                        </div>
                        <div className={`${styles.FormGroup} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tenant*</InputLabel>
                                <Controller
                                    name="tenant"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            {...field}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Tenant*"
                                        >
                                            {TenantList.map((item) => {
                                                return (<MenuItem value={item.value}>{item.label}</MenuItem>);
                                            })}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors.tenant && (<p className={`${styles.ErrorM}`}>{errors.tenant.message}</p>)}
                        </div>
                        <div className={`${styles.FormGroup} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Lease type*</InputLabel>
                                <Controller
                                    name="lease_type"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            {...field}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Lease type*"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setSelectLeaseType(e.target.value);
                                            }}
                                        >
                                            {/*<MenuItem value="New">New Lease</MenuItem>*/}
                                            <MenuItem value="Renewal">Lease Renewal</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors.lease_type && (<p className={`${styles.ErrorM}`}>{errors.lease_type.message}</p>)}
                        </div>
                        <div className={`${styles.FormGroup} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Lease Term*</InputLabel>
                                <Controller
                                    name="lease_term"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            {...field}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Lease Term*"
                                            onChange={(e) => {
                                                field.onChange(e);

                                                if (e.target.value === "6 Months") {
                                                    setValue("end_date", dayjs(getValues("start_date")).add(6, "months").subtract(1, "day"));
                                                }
                                                if (e.target.value === "1 Year") {
                                                    setValue("end_date", dayjs(getValues("start_date")).add(1, "year").subtract(1, "day"));
                                                }
                                                if (e.target.value === "2 Years") {
                                                    setValue("end_date", dayjs(getValues("start_date")).add(2, "years").subtract(1, "day"));
                                                }
                                                if (e.target.value === "3 Years") {
                                                    setValue("end_date", dayjs(getValues("start_date")).add(3, "years").subtract(1, "day"));
                                                }
                                            }}
                                        >
                                            <MenuItem value="6 Months">6 Months</MenuItem>
                                            <MenuItem value="1 Year">1 Year</MenuItem>
                                            <MenuItem value="2 Years">2 Years</MenuItem>
                                            <MenuItem value="3 Years">3 Years</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors.lease_term && (<p className={`${styles.ErrorM}`}>{errors.lease_term.message}</p>)}
                        </div>
                        {selectLeaseType === "New" && (<div className={`${styles.FormGroup} FormGroup`}>
                            <Controller
                                name="security_amount"
                                control={control}
                                render={(field) => (
                                    <MoneyField
                                        {...field}
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Security Amount*"
                                        value={field.value ? field.value : ""}
                                    />
                                )}
                            />
                            {errors.security_amount && (<p className={`${styles.ErrorM}`}>{errors.security_amount.message}</p>)}
                        </div>)}
                        {selectLeaseType === "Renewal" && (<>
                            <div className={`${styles.FormGroup} FormGroup`}>
                                <Controller
                                    name="security_amount"
                                    control={control}
                                    render={(field) => (
                                        <MoneyField
                                            {...field}
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Old Security Amount*"
                                            value={field.value ? field.value : ""}
                                        />
                                    )}
                                />
                                {errors.security_amount && (<p className={`${styles.ErrorM}`}>{errors.security_amount.message}</p>)}
                            </div>
                            <div className={`${styles.FormGroup} FormGroup`}>
                                <Controller
                                    name="new_security_amount"
                                    control={control}
                                    render={(field) => (
                                        <MoneyField
                                            {...field}
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="New Security Amount*"
                                            value={field.value ? field.value : ""}
                                        />
                                    )}
                                />
                                {errors.new_security_amount && (<p className={`${styles.ErrorM}`}>{errors.new_security_amount.message}</p>)}
                            </div>
                        </>)}
                        <div className={`${styles.FormGroup} FormGroup`}>
                            <Controller
                                name="rent"
                                control={control}
                                render={(field) => (
                                    <MoneyField
                                        {...field}
                                        id="outlined-basic"
                                        variant="outlined"
                                        label={(selectLeaseType === 'Renewal') ? "New Monthly Rent*" : "Monthly Rent*"}
                                        value={field.value ? field.value : ""}
                                    />
                                )}
                            />
                            {errors.rent && (<p className={`${styles.ErrorM}`}>{errors.rent.message}</p>)}
                        </div>
                        <div className={`${styles.FormGroup} FormGroup`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="start_date"
                                    control={control}
                                    render={(field) => (
                                        <DatePicker
                                            {...field}
                                            label="Start Date*"
                                            disabled
                                            renderInput={(params) => <TextField {...params} />}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                        />
                                    )}
                                />
                                {errors.start_date && (<p className={`${styles.ErrorM}`}>{errors.start_date.message}</p>)}
                            </LocalizationProvider>
                        </div>
                        <div className={`${styles.FormGroup} FormGroup`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="end_date"
                                    control={control}
                                    render={(field) => (
                                        <DatePicker
                                            {...field}
                                            label="End Date*"
                                            disabled
                                            renderInput={(params) => <TextField {...params} />}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                        />
                                    )}
                                />
                                {errors.end_date && (<p className={`${styles.ErrorM}`}>{errors.end_date.message}</p>)}
                            </LocalizationProvider>
                        </div>
                        <div className={`${styles.FormGroup} FormGroup`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="next_bill_date"
                                    control={control}
                                    render={(field) => (
                                        <DatePicker
                                            {...field}
                                            label="Next Bill Date*"
                                            renderInput={(params) => <TextField {...params} />}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                        />
                                    )}
                                />
                                {errors.next_bill_date && (<p className={`${styles.ErrorM}`}>{errors.next_bill_date.message}</p>)}
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupFull} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select property lease documents*</InputLabel>
                                <Controller
                                    name="docume_ids"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectDocument}
                                            onChange={(e) => {
                                                setSelectDocument(e.target.value);
                                                setValue("docume_ids", e.target.value);
                                            }}
                                            multiple
                                            input={
                                                <OutlinedInput label="Select property lease documents*" />
                                            }
                                            onClick={(e) => {
                                                if ((typeof (e.target?.parentElement?.classList?.contains('MenuListNew')) !== 'undefined' && e.target?.parentElement?.classList.contains('MenuListNew')) || (typeof (e.target?.parentElement?.parentElement?.classList?.contains('MenuListNew')) !== 'undefined' && e.target?.parentElement?.parentElement?.classList.contains('MenuListNew'))) {
                                                    setDropdownOpen(true);
                                                } else if (e.target.localName === 'span' && e.target.parentElement.classList.contains('DropListNew')) {
                                                    setDropdownOpen(false);
                                                } else if (e.target.localName === 'div' && ((typeof (e.target?.parentElement?.classList?.contains('DropListNew')) !== 'undefined' && e.target?.parentElement?.classList.contains('DropListNew')) || (typeof (e.target?.parentElement?.parentElement?.classList?.contains('DropListNew')) !== 'undefined' && e.target?.parentElement?.parentElement?.classList.contains('DropListNew')))) {
                                                    setDropdownOpen(false);
                                                } else {
                                                    setDropdownOpen(prev => !prev);
                                                }
                                            }}
                                            open={dropdownOpen}
                                            renderValue={(e) => (
                                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, }} className={'DropListNew'}>
                                                    <ListManager
                                                        className={'DropListNew'}
                                                        items={sortedLeaseDocuments}
                                                        direction="horizontal"
                                                        maxItems={40}
                                                        render={(item) => <ListElement item={item} />}
                                                        onDragEnd={reorderList}
                                                    />
                                                </Box>
                                            )}
                                        >
                                            {LeaseDocumentList.map((item) => (
                                                <MenuItem key={item.id} value={item}>
                                                    <Checkbox
                                                        checked={
                                                            selectDocumentIds.indexOf(item.id) > -1
                                                        }
                                                    />
                                                    <ListItemText primary={item.title} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors.docume_ids && (<p className={`${styles.ErrorM}`}>{errors.docume_ids.message}</p>)}
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.ButtonDiv}`}>
                <button type="button" className="CancelPopupBU" onClick={(e) => setShowRenewForm(false)}>Cancel</button>
                <button type="submit" className="SubmitPopupBU">Add</button>
            </div>
        </form >
    </div >)
}

export default RenewLeaseForm;