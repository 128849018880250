import { Calendar, Check, DollarSign, Edit, FileText, Image, Save, Trash2, X } from "react-feather";
import styles from "../../tenantdetails.module.css";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@material-ui/pickers";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import Autocomplete from "react-google-autocomplete";
import NumberFormat from "react-number-format";
import Select from "react-select";
import UploaderComponent from "../UploaderComponent";

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const FiveDigitFieldformat = React.forwardRef((props, ref) => {
    return (
        <NumberFormat
            prefix=""
            displayType="input"
            type="text"
            thousandSeparator={false}
            decimalScale={0}
            {...props}
        />
    );
});

const PhoneField = React.forwardRef((props, ref) => {
    return (
        <NumberFormat
            prefix=""
            displayType="input"
            type="tel"
            format={"(###) ###-####"}
            {...props}
        />
    );
});

const TenDigitField = React.forwardRef((props, ref) => {
    return (
        <NumberFormat
            prefix=""
            displayType="input"
            type="text"
            thousandSeparator={false}
            decimalScale={0}
            {...props}
        />
    );
});

const MoneyField = React.forwardRef((props, ref) => {
    return (
        <NumberFormat
            prefix=""
            displayType="input"
            type="text"
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={0}
            {...props}
        />
    );
});

const TenantTab = ({ tenantAllData, tenantData, setTenantData, setLoading, setSnackbarMsg, setSnackbarOpen }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [expanded, setExpanded] = useState("");
    const [fileDeleteModal, setFileDeleteModal] = useState(false);
    const [tenantDeleteModal, setTenantDeleteModal] = useState(false);
    const [fileDeleteId, setFileDeleteId] = useState(0);
    const [isRename, setIsRename] = useState({ fileId: 0, error: false, fileName: "" });

    const { register: registerForm1, handleSubmit: handleSubmitForm1, control: controlForm1, formState: { errors: errorsForm1 }, watch: watchForm1 } = useForm();
    const { register: registerForm2, handleSubmit: handleSubmitForm2, control: controlForm2, formState: { errors: errorsForm2 }, setValue: setValue2, clearErrors: clearErrors2, watch: watchForm2 } = useForm();
    const { register: registerForm3, handleSubmit: handleSubmitForm3, control: controlForm3, formState: { errors: errorsForm3 }, watch: watchForm3 } = useForm();
    const { register: registerForm4, handleSubmit: handleSubmitForm4, control: controlForm4, formState: { errors: errorsForm4 }, setValue: setValue4, clearErrors: clearErrors4, watch: watchForm4 } = useForm();
    const { register: registerForm5, handleSubmit: handleSubmitForm5, control: controlForm5, formState: { errors: errorsForm5 }, setValue: setValue5, clearErrors: clearErrors5, watch: watchForm5 } = useForm({
        defaultValues: {
            ever_broken_lease: tenantData?.ever_broken_lease,
            ever_had_bedbugs: tenantData?.ever_had_bedbugs,
            ever_had_bankruptcy: tenantData?.ever_had_bankruptcy,
            do_you_smoke: tenantData?.do_you_smoke
        }
    });

    const onPlaceSelected = (place) => {
        let address = "";
        let city = "";
        let state = "";
        let postcode = "";

        for (let component of place.address_components) {
            let componentType = component.types[0];
            switch (componentType) {
                case "street_number": {
                    address = component.long_name;
                    break;
                }
                case "route": {
                    address += " " + component.short_name;
                    break;
                }
                case "postal_code": {
                    postcode = component.long_name;
                    break;
                }
                case "locality": {
                    if (city === "") city = component.long_name;
                    break;
                }
                case "sublocality_level_1": {
                    city = component.long_name;
                    break;
                }
                default:
                    break;
            }
        }

        setValue2("address", address);
        setValue2("city", city);
        setValue2("state", state);
        setValue2("zip", postcode);
        clearErrors2();
    }

    const onPlaceSelectedEmployAddess = (place) => {
        let address = "";
        let city = "";
        let state = "";
        let postcode = "";
        for (let component of place.address_components) {
            let componentType = component.types[0];
            switch (componentType) {
                case "street_number": {
                    address = component.long_name;
                    break;
                }
                case "route": {
                    address += " " + component.short_name;
                    break;
                }
                case "postal_code": {
                    postcode = component.long_name;
                    break;
                }
                case "locality": {
                    if (city === "") city = component.long_name;
                    break;
                }
                case "sublocality_level_1": {
                    city = component.long_name;
                    break;
                }
                case "administrative_area_level_1": {
                    state = component.short_name;
                    break;
                }
                default:
                    break;
            }
        }
        let full_address = address + ", " + city + ", " + state + " " + postcode;
        setValue4("employer_address", full_address);
        clearErrors4();
    }

    const onSubmitForm1 = (data) => {
        if (data?.birth_date) {
            data = { ...data, birth_date: moment(data?.birth_date).format("YYYY-MM-DD"), };
        }
        data = { ...data, is_submitted_personal: 1 };
        onTenantInfoSubmit(data);
    }

    const onSubmitForm2 = (data) => {
        data = { ...data, is_submitted_address: 1 };
        onTenantInfoSubmit(data);
    }

    const onSubmitForm3 = (data) => {
        data = { ...data, is_submitted_rental: 1 };
        onTenantInfoSubmit(data);
    };

    const onSubmitForm4 = (data) => {
        if (data?.employement) {
            data = { ...data, employement: data?.employement?.value };
        }
        if (data?.hire_date) {
            data = { ...data, hire_date: moment(data?.hire_date).format("YYYY-MM-DD HH:mm:ss") };
        }
        data = { ...data, is_submitted_employment: 1 };
        onTenantInfoSubmit(data);
    };

    const onSubmitForm5 = (data) => {
        data = { ...data, is_submitted_other: 1 };
        onTenantInfoSubmit(data);
    };

    const onTenantInfoSubmit = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        return axios.put(process.env.REACT_APP_TENANT_API_URL + "v1/update-tenant-info/" + tenantData?.id, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTenantData(prev => {
                let allTenants = prev?.all_tenants?.map(i => {
                    if (i?.id === tenantData?.id)
                        return { ...i, ...data };
                    return i;
                });

                return { ...prev, all_tenants: allTenants };
            })
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const onFileUploadSuccess = async (file) => {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMsg('Files uploaded successfully.');

        setTenantData(prev => {
            let allTenants = prev?.all_tenants?.map(i => {
                if (i?.id === tenantData?.id) {
                    return { ...i, files: [file].concat(i.files) };
                }
                return i;
            });

            return { ...prev, all_tenants: allTenants };
        });
    }


    const delFile = async () => {
        setLoading(true);
        setFileDeleteModal(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.delete(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/files-new/" + fileDeleteId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTenantData(prev => {
                let allTenants = prev?.all_tenants?.map(i => {
                    if (i?.id === tenantData?.id) {
                        let files = i.files?.filter((fItem) => fItem.id !== fileDeleteId);
                        return { ...i, files: files };
                    }
                    return i;
                });

                return { ...prev, all_tenants: allTenants };
            });

            setFileDeleteId(0);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const fileRenameSave = async () => {

        if (isRename?.fileName === "") {
            setIsRename({ ...isRename, error: true });
        } else {
            setLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios.put(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/files-new/" + isRename?.fileId, { file_name: isRename?.fileName }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMsg(response.data.message);

                setTenantData(prev => {
                    let allTenants = prev?.all_tenants?.map(i => {
                        if (i?.id === tenantData?.id) {
                            let files = i.files?.map((fItem) => {
                                if (fItem.id === isRename?.fileId) {
                                    return response.data.data;
                                }
                                return fItem;
                            });
                            return { ...i, files: files };
                        }
                        return i;
                    });

                    return { ...prev, all_tenants: allTenants };
                });

                setIsRename({ fileId: 0, error: false, fileName: "" });
            }).catch((error) => {
                setLoading(false);
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        }
    };

    const tenantDelConfirm = async () => {
        setLoading(true);
        setTenantDeleteModal(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.delete(process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantAllData?.id + "/delete-new/" + tenantData?.id, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setTenantData(prev => {
                return { ...prev, all_tenants: response.data.data };
            });
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const openFile = (item) => {
        console.log(item);
        window.open(item?.file_url, '_blank')
    }

    return (<>
        <div className="FormAccording Theme">
            <Accordion expanded={expanded === "panel1"} onChange={(e, isExpanded) => setExpanded(isExpanded ? "panel1" : false)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <div className={`${styles.FormAccoHead}`}>
                        <p className={`${styles.FormAccoHeadTitle}`}>Basic information</p>
                        <div className={`${styles.AccoCheck} ${styles.Active}`}><Check /></div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={`${styles.CussAccoRow}`}>
                    <div className={`${styles.AccoFormSec}`}>
                        <div className={`${styles.FormGroupFull} CusSelect`}>
                            <div className={`${styles.Width100}`}>
                                <label className={`${styles.FormLabel}`}>Apt #</label>
                                <Select
                                    options={[]}
                                    value={{ value: tenantAllData?.apartment?.id, label: tenantAllData?.apartment?.apt_no }}
                                    isDisabled={true}
                                />
                            </div>
                        </div>

                        <div className={`${styles.ToggleBUHalf}`}>
                            <label className={`${styles.FormLabel}`}>Specify the number of applicants</label>
                            <button className={`${styles.AppliFormToggleBU} ${styles.Active}`}>{tenantAllData?.all_tenants?.length}</button>
                        </div>
                        {tenantAllData?.move_in_date !== "" && tenantAllData?.move_in_date !== "null" && (<div className={`${styles.FormGroupHalf}`}>
                            <label className={`${styles.FormLabel}`}>Desired move-in date</label>
                            <div className={`${styles.Width100}`}>
                                <div className={`AppliAccoFormDate ${styles.AppliAccoFormDateTenantDE}`}>
                                    {tenantAllData?.formatted_move_in_date}
                                    <p className={`${styles.CalendarIcon}`}><Calendar /></p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "panel2"} onChange={(e, isExpanded) => setExpanded(isExpanded ? "panel2" : false)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                    <div className={`${styles.FormAccoHead}`}>
                        <p className={`${styles.FormAccoHeadTitle}`}>Personal information</p>
                        <div className={tenantData?.is_submitted_personal ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}><Check /></div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={`${styles.CussAccoRow}`}>
                    <form onSubmit={handleSubmitForm1(onSubmitForm1)}>
                        <div className={`${styles.AccoFormSec}`}>
                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>First name</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                            />
                                        )}
                                        control={controlForm1}
                                        name="first_name"
                                        ref={registerForm1}
                                        defaultValue={tenantData?.first_name}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message:
                                                    "First name cannot be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm1.first_name && (<p className={`${styles.ErrorM}`}>{errorsForm1.first_name.message}</p>)}
                                </div>
                            </div>
                            {tenantAllData?.rentalApplication?.middle_name && (<div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Middle initial <span>Optional</span></label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm1}
                                        name="middle_name"
                                        ref={registerForm1}
                                        defaultValue={tenantData?.middle_name}
                                        rules={{
                                            maxLength: {
                                                value: 50,
                                                message: "Middle initial cannot be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm1.middle_name && (<p className={`${styles.ErrorM}`}>{errorsForm1.middle_name.message}</p>)}
                                </div>
                            </div>)}

                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Last name</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm1}
                                        name="last_name"
                                        ref={registerForm1}
                                        defaultValue={tenantData?.last_name}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message: "Last name cannot be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm1.last_name && (<p className={`${styles.ErrorM}`}>{errorsForm1.last_name.message}</p>)}
                                </div>
                            </div>
                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Birth date</label>
                                <div className={`${styles.Width100}`}>
                                    <div className={`AppliAccoFormDate ${styles.AppliAccoFormDateTenantDE}`}>
                                        <Controller
                                            control={controlForm1}
                                            name="birth_date"
                                            animateYearScrolling
                                            render={(fields) => (
                                                <DatePicker
                                                    placeholder="MM/DD/YYYY"
                                                    format="MM/dd/yyyy"
                                                    value={fields.value ? fields.value : null}
                                                    onChange={(e) => {
                                                        fields.onChange(e);
                                                    }}
                                                    error={false}
                                                    maxDate={moment().subtract(18, "years")}
                                                    helperText={null}
                                                />
                                            )}
                                            rules={{ required: "This field is required." }}
                                            defaultValue={tenantData?.birth_date ? tenantData?.birth_date : null
                                            }
                                        />
                                        <p className={`${styles.CalendarIcon}`}><Calendar /></p>
                                    </div>
                                    {errorsForm1.birth_date && (<p className={`${styles.ErrorM}`}>{errorsForm1.birth_date.message}</p>)}
                                </div>
                            </div>

                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Driver's license or ID #</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm1}
                                        name="driver_license"
                                        ref={registerForm1}
                                        defaultValue={tenantData?.driver_license}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 15,
                                                message: "This field cannot be more than 15 characters.",
                                            },
                                            pattern: {
                                                value: /^[0-9a-zA-Z ]+$/,
                                                message: "Please enter a valid driver's license or id.",
                                            },
                                        }}
                                    />
                                    {errorsForm1.driver_license && (<p className={`${styles.ErrorM}`}>{errorsForm1.driver_license.message}</p>)}
                                </div>
                            </div>
                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Social security number</label>
                                <div className={`${styles.Width100}`}>
                                    <input className={`${styles.formControl}`} type="text" defaultValue={'*********'} disabled />
                                </div>
                            </div>

                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Email address</label>
                                <div className={`${styles.Width100}`}>
                                    <input className={`${styles.formControl}`} type="text" defaultValue={tenantData?.email} disabled />
                                </div>
                            </div>
                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Phone </label>
                                <div className={`${styles.Width100}`}>
                                    <input className={`${styles.formControl}`} type="text" defaultValue={tenantData?.phone} disabled />
                                </div>
                            </div>
                        </div>

                        {(tenantData?.first_name !== watchForm1('first_name') || tenantData?.last_name !== watchForm1('last_name') || moment(tenantData?.birth_date).format("MM/DD/YYYY") !== moment(watchForm1('birth_date')).format("MM/DD/YYYY") || tenantData?.driver_license !== watchForm1('driver_license')) && <button type="submit" className={`${styles.ButtonVoiletSolidSM}`}>Update</button>}

                    </form>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "panel3"} onChange={(e, isExpanded) => setExpanded(isExpanded ? "panel3" : false)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                    <div className={`${styles.FormAccoHead}`}>
                        <p className={`${styles.FormAccoHeadTitle}`}>Address</p>
                        <div className={tenantData?.is_submitted_address ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}><Check /></div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmitForm2(onSubmitForm2)}>
                        <div className={`${styles.AccoFormSec}`}>
                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Current street address</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <Autocomplete
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                fullwidth
                                                apiKey={"AIzaSyDrxtVszbIQ4U8rL90V1lDfy5HvN673fcg"}
                                                options={{
                                                    types: ["address"],
                                                    componentRestrictions: { country: "us" },
                                                    fields: ["address_components", "geometry"],
                                                }}
                                                onPlaceSelected={onPlaceSelected}
                                                placeholder=""
                                            />
                                        )}
                                        control={controlForm2}
                                        name="address"
                                        ref={registerForm2}
                                        defaultValue={tenantData?.address}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message: "Address cannot be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm2.address && (<p className={`${styles.ErrorM}`}>{errorsForm2.address.message}</p>)}
                                </div>
                            </div>
                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Street address <span>Optional</span></label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm2}
                                        name="address2"
                                        ref={registerForm2}
                                        defaultValue={tenantData?.address2}
                                        rules={{
                                            maxLength: {
                                                value: 50,
                                                message: "Address2 cannot be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm2.address2 && (<p className={`${styles.ErrorM}`}>{errorsForm2.address2.message}</p>)}
                                </div>
                            </div>

                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>City </label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm2}
                                        name="city"
                                        ref={registerForm2}
                                        defaultValue={tenantData?.city}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message: "City cannot be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm2.city && (<p className={`${styles.ErrorM}`}>{errorsForm2.city.message}</p>)}
                                </div>
                            </div>
                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>State</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm2}
                                        name="state"
                                        ref={registerForm2}
                                        defaultValue={tenantData?.state}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message: "State cannot be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm2.state && (<p className={`${styles.ErrorM}`}>{errorsForm2.state.message}</p>)}
                                </div>
                            </div>

                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Zip code </label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <FiveDigitFieldformat
                                                {...fields}
                                                format="#####"
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm2}
                                        name="zip"
                                        ref={registerForm2}
                                        defaultValue={tenantData?.zip}
                                        rules={{
                                            required: "This field is required.",
                                            pattern: {
                                                value: /\b\d{5}\b/,
                                                message: "Please enter a valid zip code.",
                                            },
                                        }}
                                    />
                                    {errorsForm2.zip && (<p className={`${styles.ErrorM}`}>{errorsForm2.zip.message}</p>)}
                                </div>
                            </div>
                        </div>

                        {(tenantData?.address !== watchForm2('address') || tenantData?.address2 !== watchForm2('address2') || tenantData?.city !== watchForm2('city') || tenantData?.state !== watchForm2('state') || tenantData?.zip !== watchForm2('zip')) && <button type="submit" className={`${styles.ButtonVoiletSolidSM}`}>Update</button>}

                    </form>
                </AccordionDetails>
            </Accordion>

            { }

            {(tenantAllData?.rental_application?.rental_application?.landlord_name || tenantAllData?.rental_application?.rental_application?.landlord_phone_no || tenantAllData?.rental_application?.rental_application?.reason_for_moving || tenantAllData?.rental_application?.rental_application?.who_live_with_you || tenantAllData?.rental_application?.rental_application?.lived_current_addrs || tenantAllData?.rental_application?.rental_application?.current_rent) && (<Accordion expanded={expanded === "panel4"} onChange={(e, isExpanded) => setExpanded(isExpanded ? "panel4" : false)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <div className={`${styles.FormAccoHead}`}>
                        <p className={`${styles.FormAccoHeadTitle}`}>Rental Information</p>
                        <div className={tenantData?.is_submitted_rental ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}><Check /></div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmitForm3(onSubmitForm3)}>
                        <div className={`${styles.AccoFormSec}`}>
                            {(tenantAllData?.rental_application?.rental_application?.landlord_name) && (<div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Name of present landlord</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm3}
                                        name="landlord_name"
                                        ref={registerForm3}
                                        defaultValue={tenantData?.landlord_name}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message: "Landlord name cannot be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm3.landlord_name && (<p className={`${styles.ErrorM}`}>{errorsForm3.landlord_name.message}</p>)}
                                </div>
                            </div>)}

                            {(tenantAllData?.rental_application?.rental_application?.landlord_phone_no) && (<div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Phone number of present landlord</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <PhoneField
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm3}
                                        name="landlord_phone_no"
                                        ref={registerForm3}
                                        defaultValue={tenantData?.landlord_phone_no}
                                        rules={{
                                            required: "This field is required.",
                                            pattern: {
                                                value: phoneRegExp,
                                                message: "Please enter a valid phone number.",
                                                excludeEmptyString: true,
                                            },
                                        }}
                                    />
                                    {errorsForm3.landlord_phone_no && (<p className={`${styles.ErrorM}`}>{errorsForm3.landlord_phone_no.message}</p>)}
                                </div>
                            </div>)}

                            {(tenantAllData?.rental_application?.rental_application?.reason_for_moving) && (<div className={`${styles.FormGroupFull}`}>
                                <label className={`${styles.FormLabel}`}>Reason for moving</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <textarea
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControlTextarea}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                                onKeyUp={(e) => {
                                                    if (e.target.value.length > 199)
                                                        e.preventDefault();
                                                }}
                                            />
                                        )}
                                        control={controlForm3}
                                        name="reason_for_moving"
                                        ref={registerForm3}
                                        defaultValue={tenantData?.reason_for_moving}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 200,
                                                message:
                                                    "Reason for moving can not be more than 200 characters.",
                                            },
                                        }}
                                    />
                                    <p className={`${styles.FormFieldMessage}`}>{200 - (watchForm3("reason_for_moving")?.length ? watchForm3("reason_for_moving")?.length : 0)} {200 - (watchForm3("reason_for_moving")?.length ? watchForm3("reason_for_moving")?.length : 0) > 1 ? "characters" : "character"} remaining</p>
                                    {errorsForm3.reason_for_moving && (<p className={`${styles.ErrorM}`}>{errorsForm3.reason_for_moving.message}</p>)}
                                </div>
                            </div>)}

                            {(tenantAllData?.rental_application?.rental_application?.who_live_with_you) && (<div className={`${styles.FormGroupFull}`}>
                                <label className={`${styles.FormLabel}`}>Who else will be living with you? Please provide their first name, last name, birth date and relationship to you</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <textarea
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControlTextarea}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                                onKeyUp={(e) => {
                                                    if (e.target.value.length > 199)
                                                        e.preventDefault();
                                                }}
                                            />
                                        )}
                                        control={controlForm3}
                                        name="who_live_with_you"
                                        ref={registerForm3}
                                        defaultValue={tenantData?.who_live_with_you}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 200,
                                                message: "Who else will be living with you ? can not be more than 200 characters.",
                                            },
                                        }}
                                    />
                                    <p className={`${styles.FormFieldMessage}`}>{200 - (watchForm3("who_live_with_you")?.length ? watchForm3("who_live_with_you")?.length : 0)} {200 - (watchForm3("who_live_with_you")?.length ? watchForm3("who_live_with_you")?.length : 0) > 1 ? "characters" : "character"} remaining</p>
                                    {errorsForm3.who_live_with_you && (<p className={`${styles.ErrorM}`}>{errorsForm3.who_live_with_you.message}</p>)}
                                </div>
                            </div>)}

                            {(tenantAllData?.rental_application?.rental_application?.lived_current_addrs) && (<div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>How many months have you lived at present address?</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <TenDigitField
                                                format="###"
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm3}
                                        name="long_lived_current_addrs"
                                        ref={registerForm3}
                                        defaultValue={tenantData?.long_lived_current_addrs}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 3,
                                                message: "Lived present address can not be more than 3 digits.",
                                            },
                                        }}
                                    />
                                    {errorsForm3.long_lived_current_addrs && (<p className={`${styles.ErrorM}`}>{errorsForm3.long_lived_current_addrs.message}</p>)}
                                </div>
                            </div>)}

                            {(tenantAllData?.rental_application?.rental_application?.current_rent) && (<div className={`${styles.FormGroupHalf} ${styles.Dollar}`}>
                                <label className={`${styles.FormLabel}`}>Current rent per month in USD</label>
                                <div className={`${styles.Width100}`}>
                                    <p className={`${styles.AccoDollarSign}`}><DollarSign /></p>
                                    <Controller
                                        render={(fields) => (
                                            <MoneyField
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm3}
                                        name="current_rent"
                                        ref={registerForm3}
                                        defaultValue={tenantData?.current_rent}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 5,
                                                message: "Current rent can not be more than 4 digits.",
                                            },
                                        }}
                                        maxLength={5}
                                    />
                                    {errorsForm3.current_rent && (<p className={`${styles.ErrorM}`}>{errorsForm3.current_rent.message}</p>)}
                                </div>
                            </div>)}
                        </div>

                        {(tenantData?.landlord_name !== watchForm3('landlord_name') || tenantData?.landlord_phone_no !== watchForm3('landlord_phone_no') || tenantData?.reason_for_moving !== watchForm3('reason_for_moving') || tenantData?.who_live_with_you !== watchForm3('who_live_with_you') || tenantData?.long_lived_current_addrs !== watchForm3('long_lived_current_addrs') || tenantData?.current_rent !== watchForm3('current_rent')) && <button type="submit" className={`${styles.ButtonVoiletSolidSM}`}>Update</button>}

                    </form>
                </AccordionDetails>
            </Accordion>)}

            {tenantAllData?.rental_application?.rental_application?.employer_information && (<Accordion expanded={expanded === "panel5"} onChange={(e, isExpanded) => setExpanded(isExpanded ? "panel5" : false)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
                    <div className={`${styles.FormAccoHead}`}>
                        <p className={`${styles.FormAccoHeadTitle}`}>Employment</p>
                        <div className={tenantData?.is_submitted_employment ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}><Check /></div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmitForm4(onSubmitForm4)}>
                        <div className={`${styles.AccoFormSec}`}>
                            <div className={`${styles.FormGroupHalf} CusSelect`}>
                                <label className={`${styles.FormLabel}`}>Employment</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        name="employement"
                                        ref={registerForm4}
                                        control={controlForm4}
                                        defaultValue={tenantData?.employement !== "" ? { label: tenantData?.employement, value: tenantData?.employement } : ""}
                                        render={(fields) => (
                                            <Select
                                                {...fields}
                                                options={[
                                                    { value: "Full-Time", label: "Full-Time" },
                                                    { value: "Part-Time", label: "Part-Time" },
                                                ]}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        rules={{ required: "This field is required." }}
                                    />
                                    {errorsForm4.employement && (<p className={`${styles.ErrorM}`}>{errorsForm4.employement.message}</p>)}
                                </div>
                            </div>
                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Employer name</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm4}
                                        name="employer_name"
                                        ref={registerForm4}
                                        defaultValue={tenantData?.employer_name}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message: "Employer name can not be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm4.employer_name && (<p className={`${styles.ErrorM}`}>{errorsForm4.employer_name.message}</p>)}
                                </div>
                            </div>

                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Employer address</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <Autocomplete
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                fullwidth
                                                apiKey={"AIzaSyDrxtVszbIQ4U8rL90V1lDfy5HvN673fcg"}
                                                options={{
                                                    types: ["address"],
                                                    componentRestrictions: { country: "us" },
                                                    fields: ["address_components", "geometry"],
                                                }}
                                                onPlaceSelected={onPlaceSelectedEmployAddess}
                                                placeholder=""
                                            />
                                        )}
                                        control={controlForm4}
                                        name="employer_address"
                                        ref={registerForm4}
                                        defaultValue={tenantData?.employer_address}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message: "Employer address can not be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm4.employer_address && (<p className={`${styles.ErrorM}`}>{errorsForm4.employer_address.message}</p>)}
                                </div>
                            </div>
                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Years employed</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <TenDigitField
                                                format="##"
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm4}
                                        name="years_employed"
                                        ref={registerForm4}
                                        defaultValue={tenantData?.years_employed}
                                        rules={{
                                            required: "This field is required.",
                                            masLength: {
                                                value: 2,
                                                message: "Years employeed can not be more than 2 digits.",
                                            },
                                        }}
                                    />
                                    {errorsForm4.years_employed && (<p className={`${styles.ErrorM}`}>{errorsForm4.years_employed.message}</p>)}
                                </div>
                            </div>

                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Job title</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <input
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm4}
                                        name="job_title"
                                        ref={registerForm4}
                                        defaultValue={tenantData?.job_title}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message: "Job title can not be more than 50 characters.",
                                            },
                                        }}
                                    />
                                    {errorsForm4.job_title && (<p className={`${styles.ErrorM}`}>{errorsForm4.job_title.message}</p>)}
                                </div>
                            </div>
                            <div className={`${styles.FormGroupHalf} ${styles.Dollar}`}>
                                <label className={`${styles.FormLabel}`}>Income per year in USD</label>
                                <div className={`${styles.Width100}`}>
                                    <p className={`${styles.AccoDollarSign}`}><DollarSign /></p>
                                    <Controller
                                        render={(fields) => (
                                            <MoneyField
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm4}
                                        name="income_per_year"
                                        ref={registerForm4}
                                        defaultValue={tenantData?.income_per_year}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 7,
                                                message: "Income per year can not be more than 6 digits.",
                                            },
                                        }}
                                        maxLength={7}
                                    />
                                    {errorsForm4.income_per_year && (<p className={`${styles.ErrorM}`}>{errorsForm4.income_per_year.message}</p>)}
                                </div>
                            </div>

                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>Hire date</label>
                                <div className={`${styles.Width100}`}>
                                    <div className={`AppliAccoFormDate ${styles.AppliAccoFormDateTenantDE}`}>
                                        <Controller
                                            control={controlForm4}
                                            ref={registerForm4}
                                            name="hire_date"
                                            animateYearScrolling
                                            render={(fields) => (
                                                <DatePicker
                                                    placeholder="MM/DD/YYYY"
                                                    format="MM/dd/yyyy"
                                                    value={fields.value ? fields.value : null}
                                                    onChange={(e) => {
                                                        fields.onChange(e);
                                                    }}
                                                    disableFuture
                                                    helperText={null}
                                                />
                                            )}
                                            rules={{ required: "This field is required." }}
                                            defaultValue={tenantData?.hire_date ? tenantData?.hire_date : null}
                                        />
                                        <p className={`${styles.CalendarIcon}`}><Calendar /></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(tenantData?.employement !== watchForm4('employement')?.value || tenantData?.employer_name !== watchForm4('employer_name') || tenantData?.employer_address !== watchForm4('employer_address') || tenantData?.years_employed !== watchForm4('years_employed') || tenantData?.job_title !== watchForm4('job_title') || tenantData?.income_per_year !== watchForm4('income_per_year') || moment(tenantData?.hire_date).format("MM/DD/YYYY") !== moment(watchForm4('hire_date')).format("MM/DD/YYYY")) && <button type="submit" className={`${styles.ButtonVoiletSolidSM}`}>Update</button>}

                    </form>
                </AccordionDetails>
            </Accordion>)}

            <Accordion expanded={expanded === "panel6"} onChange={(e, isExpanded) => setExpanded(isExpanded ? "panel6" : false)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
                    <div className={`${styles.FormAccoHead}`}>
                        <p className={`${styles.FormAccoHeadTitle}`}>Other information</p>
                        <div className={tenantData?.is_submitted_other ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}><Check /></div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmitForm5(onSubmitForm5)}>
                        <div className={`${styles.AccoFormSec}`}>
                            {tenantAllData?.rental_application?.rental_application?.ever_evicted && (<div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>How many evictions have been filed upon you?</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <TenDigitField
                                                format="##"
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm5}
                                        name="ever_evicted"
                                        ref={registerForm5}
                                        defaultValue={tenantData?.ever_evicted}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 2,
                                                message: "Evicted can not be more than 2 digits.",
                                            },
                                        }}
                                    />
                                    {errorsForm5.ever_evicted && (<p className={`${styles.ErrorM}`}>{errorsForm5.ever_evicted.message}</p>)}
                                </div>
                            </div>)}
                            {tenantAllData?.rental_application?.rental_application?.ever_case_filed && (<div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>How many cases have been filled with you in housing court?</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <TenDigitField
                                                format="##"
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm5}
                                        name="ever_case_filed"
                                        ref={registerForm5}
                                        defaultValue={tenantData?.ever_case_filed}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 2,
                                                message: "Case filed can not be more than 2 digits.",
                                            },
                                        }}
                                    />
                                    {errorsForm5.ever_case_filed && (<p className={`${styles.ErrorM}`}>{errorsForm5.ever_case_filed.message}</p>)}
                                </div>
                            </div>)}

                            {tenantAllData?.rental_application?.rental_application?.ever_broken_lease && (<div className={`${styles.ToggleBUHalf}`}>
                                <label className={`${styles.FormLabel}`}>Have you ever broken a lease?</label>
                                <div className={`${styles.CusRadioRow}`}>
                                    <label className={`${styles.CusRadio}`}>
                                        <input
                                            defaultChecked={tenantData?.ever_broken_lease === "Yes"}
                                            type="radio"
                                            name="ever_broken_lease"
                                            onChange={(e) => {
                                                setValue5("ever_broken_lease", "Yes");
                                                clearErrors5("ever_broken_lease");
                                            }}
                                            {...registerForm5("ever_broken_lease", {
                                                required: "This field is required.",
                                            })}
                                        />
                                        <div className={`${styles.checkmark}`}>Yes</div>
                                    </label>
                                    <label className={`${styles.CusRadio}`}>
                                        <input
                                            defaultChecked={tenantData?.ever_broken_lease === "No"}
                                            type="radio"
                                            name="ever_broken_lease"
                                            onChange={(e) => {
                                                setValue5("ever_broken_lease", "No");
                                                clearErrors5("ever_broken_lease");
                                            }}
                                            {...registerForm5("ever_broken_lease", {
                                                required: "This field is required.",
                                            })}
                                        />
                                        <div className={`${styles.checkmark}`}>No</div>
                                    </label>
                                </div>
                                {errorsForm5.ever_broken_lease && (<p className={`${styles.ErrorM}`}>{errorsForm5.ever_broken_lease.message}</p>)}
                            </div>)}

                            <div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>What is your credit score?</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <TenDigitField
                                                format="###"
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm5}
                                        name="credit_score"
                                        ref={registerForm5}
                                        defaultValue={tenantData?.credit_score}
                                        rules={{
                                            required: "This field is required.",
                                            pattern: {
                                                value: /\b\d{3}\b/,
                                                message: "Please enter valid credit score.",
                                            },
                                        }}
                                    />
                                    {errorsForm5.credit_score && (<p className={`${styles.ErrorM}`}>{errorsForm5.credit_score.message}</p>)}
                                </div>
                            </div>

                            {tenantAllData?.rental_application?.rental_application?.have_pets && (<div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>How many pets do you have?</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <TenDigitField
                                                format="#"
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm5}
                                        name="have_pets"
                                        ref={registerForm5}
                                        defaultValue={tenantData?.have_pets}
                                        rules={{
                                            required: "This field is required.",
                                            maxLentgh: {
                                                value: 1,
                                                message: "Pets field can not be more than 1 digit.",
                                            },
                                        }}
                                    />
                                    {errorsForm5.have_pets && (<p className={`${styles.ErrorM}`}>{errorsForm5.have_pets.message}</p>)}
                                </div>
                            </div>)}

                            {tenantAllData?.rental_application?.rental_application?.ever_had_bedbugs && (<div className={`${styles.ToggleBUHalf}`}>
                                <label className={`${styles.FormLabel}`}>Have you ever had bed bugs?</label>
                                <div className={`${styles.CusRadioRow}`}>
                                    <label className={`${styles.CusRadio}`}>
                                        <input
                                            defaultChecked={tenantData?.ever_had_bedbugs === "Yes"}
                                            type="radio"
                                            name="ever_had_bedbugs"
                                            onChange={(e) => {
                                                setValue5("ever_had_bedbugs", "Yes");
                                                clearErrors5("ever_had_bedbugs");
                                            }}
                                            {...registerForm5("ever_had_bedbugs", {
                                                required: "This field is required.",
                                            })}
                                        />
                                        <div className={`${styles.checkmark}`}>Yes</div>
                                    </label>
                                    <label className={`${styles.CusRadio}`}>
                                        <input
                                            defaultChecked={tenantData?.ever_had_bedbugs === "No"}
                                            type="radio"
                                            name="ever_had_bedbugs"
                                            onChange={(e) => {
                                                setValue5("ever_had_bedbugs", "No");
                                                clearErrors5("ever_had_bedbugs");
                                            }}
                                            {...registerForm5("ever_had_bedbugs", {
                                                required: "This field is required.",
                                            })}
                                        />
                                        <div className={`${styles.checkmark}`}>No</div>
                                    </label>
                                </div>
                                {errorsForm5.ever_had_bedbugs && (<p className={`${styles.ErrorM}`}>{errorsForm5.ever_had_bedbugs.message}</p>)}
                            </div>)}

                            {tenantAllData?.rental_application?.rental_application?.ever_had_Bankruptcy && (<div className={`${styles.ToggleBUHalf}`}>
                                <label className={`${styles.FormLabel}`}>Have you ever filed for bankruptcy?</label>
                                <div className={`${styles.CusRadioRow}`}>
                                    <label className={`${styles.CusRadio}`}>
                                        <input
                                            defaultChecked={tenantData?.ever_had_bankruptcy === "Yes"}
                                            type="radio"
                                            name="ever_had_bankruptcy"
                                            onChange={(e) => {
                                                setValue5("ever_had_bankruptcy", "Yes");
                                                clearErrors5("ever_had_bankruptcy");
                                            }}
                                            {...registerForm5("ever_had_bankruptcy", {
                                                required: "This field is required.",
                                            })}
                                        />
                                        <div className={`${styles.checkmark}`}>Yes</div>
                                    </label>
                                    <label className={`${styles.CusRadio}`}>
                                        <input
                                            defaultChecked={tenantData?.ever_had_bankruptcy === "No"}
                                            type="radio"
                                            name="ever_had_bankruptcy"
                                            onChange={(e) => {
                                                setValue5("ever_had_bankruptcy", "No");
                                                clearErrors5("ever_had_bankruptcy");
                                            }}
                                            {...registerForm5("ever_had_bankruptcy", {
                                                required: "This field is required.",
                                            })}
                                        />
                                        <div className={`${styles.checkmark}`}>No</div>
                                    </label>
                                </div>
                                {errorsForm5.ever_had_bankruptcy && (<p className={`${styles.ErrorM}`}>{errorsForm5.ever_had_bankruptcy.message}</p>)}
                            </div>)}

                            {tenantAllData?.rental_application?.rental_application?.do_you_smoke && (<div className={`${styles.ToggleBUHalf}`}>
                                <label className={`${styles.FormLabel}`}>Do you smoke?</label>
                                <div className={`${styles.CusRadioRow}`}>
                                    <label className={`${styles.CusRadio}`}>
                                        <input
                                            defaultChecked={tenantData?.do_you_smoke === "Yes"}
                                            type="radio"
                                            name="do_you_smoke"
                                            onChange={(e) => {
                                                setValue5("do_you_smoke", "Yes");
                                                clearErrors5("do_you_smoke");
                                            }}
                                            {...registerForm5("do_you_smoke", {
                                                required: "This field is required.",
                                            })}
                                        />
                                        <div className={`${styles.checkmark}`}>Yes</div>
                                    </label>
                                    <label className={`${styles.CusRadio}`}>
                                        <input
                                            defaultChecked={tenantData?.do_you_smoke === "No"}
                                            type="radio"
                                            name="do_you_smoke"
                                            onChange={(e) => {
                                                setValue5("do_you_smoke", "No");
                                                clearErrors5("do_you_smoke");
                                            }}
                                            {...registerForm5("do_you_smoke", {
                                                required: "This field is required.",
                                            })}
                                        />
                                        <div className={`${styles.checkmark}`}>No</div>
                                    </label>
                                </div>
                                {errorsForm5.do_you_smoke && (<p className={`${styles.ErrorM}`}>{errorsForm5.do_you_smoke.message}</p>)}
                            </div>)}

                            {tenantAllData?.rental_application?.rental_application?.vechicles_you_own && (<div className={`${styles.FormGroupHalf}`}>
                                <label className={`${styles.FormLabel}`}>How many vehicles do you own?</label>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <TenDigitField
                                                format="#"
                                                {...fields}
                                                className={`${styles.formControl}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm5}
                                        name="own_vehicles"
                                        ref={registerForm5}
                                        defaultValue={tenantData?.own_vehicles}
                                        rules={{
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 1,
                                                message: "Vehicles filed can not be more than 1 digit.",
                                            },
                                        }}
                                    />
                                    {errorsForm5.own_vehicles && (<p className={`${styles.ErrorM}`}>{errorsForm5.own_vehicles.message}</p>)}
                                </div>
                            </div>)}

                            <div className={`${styles.FormGroupFull}`}>
                                <label className={`${styles.FormLabel}`}>Additional information <span>OPTIONAL</span></label>
                                <p className={`${styles.FormLabelSmall}`}>Please use this space for additional information, comments or explanations.</p>
                                <div className={`${styles.Width100}`}>
                                    <Controller
                                        render={(fields) => (
                                            <textarea
                                                {...fields}
                                                type="text"
                                                className={`${styles.formControlTextarea}`}
                                                onChange={(e) => {
                                                    fields.onChange(e);
                                                }}
                                            />
                                        )}
                                        control={controlForm5}
                                        name="additional_information"
                                        ref={registerForm5}
                                        defaultValue={tenantData?.additional_information}
                                    />
                                </div>
                            </div>
                        </div>

                        {(tenantData?.ever_evicted !== watchForm5('ever_evicted') || tenantData?.ever_case_filed !== watchForm5('ever_case_filed') || tenantData?.ever_broken_lease !== watchForm5('ever_broken_lease') || tenantData?.credit_score !== watchForm5('credit_score') || tenantData?.have_pets !== watchForm5('have_pets') || tenantData?.ever_had_bedbugs !== watchForm5('ever_had_bedbugs') || tenantData?.ever_had_bankruptcy !== watchForm5('ever_had_bankruptcy') || tenantData?.do_you_smoke !== watchForm5('do_you_smoke') || tenantData?.own_vehicles !== watchForm5('own_vehicles') || tenantData?.additional_information !== watchForm5('additional_information')) && <button type="submit" className={`${styles.ButtonVoiletSolidSM}`}>Update</button>}

                    </form>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "panel7"} onChange={(e, isExpanded) => setExpanded(isExpanded ? "panel7" : false)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
                    <div className={`${styles.FormAccoHead}`}>
                        <p className={`${styles.FormAccoHeadTitle}`}>Upload files</p>
                        <div className={tenantData?.files?.length > 0 ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}><Check /></div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={`${styles.AccoFormSec}`}>
                        <div className={`${styles.DivFull}`}>
                            <p className={`${styles.FileUpTitle}`}>Please upload all the following supporting documents:</p>
                            <ul className={`${styles.FileUpList}`}>
                                <li>Copy of credit report</li>
                                <li>Recent W2s</li>
                                <li>Last 4 Pay Stubs or Bank Statement for last 3 months</li>
                                <li>Driver’s License</li>
                            </ul>
                        </div>

                        <div className={`${styles.FileIMGDiv}`}>

                            <UploaderComponent allowedExtensions={".pdf,.png,.jpg,.jpeg"} isMuliple={true} setLoading={setLoading.bind(this)} uploadUrl={process.env.REACT_APP_TENANT_API_URL + "v1/tenants/" + tenantData?.id + "/files-new/upload"} onUploadLocal={onFileUploadSuccess.bind(this)} />

                            <div className={`${styles.FileListRow}`}>
                                {tenantData?.files?.map((itemFile, indexF) => {
                                    return isRename?.fileId === itemFile.id ? (<div className={`${styles.UpFileList}`}>
                                        <div className={`${styles.UpFileListUnder} ${styles.Active}`}>
                                            <p className={`${styles.FileName}`}>
                                                <FileText />
                                                <TextField
                                                    hiddenLabel
                                                    autoFocus
                                                    error={isRename?.error}
                                                    variant="outlined"
                                                    className={`${styles.FileRenameField}`}
                                                    defaultValue={isRename.fileName}
                                                    onChange={(e) => setIsRename({ ...isRename, fileName: e.target.value })}
                                                />
                                            </p>
                                            <p className={`${styles.FileSize}`}>{moment(itemFile?.created_at).format("MM/DD/YYYY LT")}</p>
                                            <div className={`${styles.FileListRight}`}>
                                                <Button className={`${styles.FileListRename}`} onClick={(e) => fileRenameSave()}><Save /></Button>
                                                <Button className={`${styles.FileListCancel}`} onClick={(e) => setIsRename({ fileId: 0, error: false, fileName: "" })}><X /></Button>
                                            </div>
                                        </div>
                                    </div>) : (<div className={`${styles.UpFileList}`} onClick={(e) => openFile(itemFile)}>
                                        <div className={`${styles.UpFileListUnder}`}>
                                            <p className={`${styles.FileName}`}>
                                                {itemFile.file_type === "pdf" ? <FileText /> : <Image />}
                                                <span>{itemFile?.file_name}</span>
                                            </p>
                                            <p className={`${styles.FileSize}`}>{moment(itemFile?.created_at).format("MM/DD/YYYY LT")}</p>
                                            <div className={`${styles.FileListRight}`}>
                                                <Button className={`${styles.FileListRename}`} onClick={(e) => { setIsRename({ fileId: itemFile?.id, error: false, fileName: itemFile?.file_name?.replace(/\.[^/.]+$/, "") }); }}><Edit /></Button>
                                                <Button className={`${styles.FileListDelete}`} onClick={(e) => { e.stopPropagation(); setFileDeleteModal(true); setFileDeleteId(itemFile?.id); }}><Trash2 /></Button>
                                            </div>
                                        </div>
                                    </div>);
                                })}
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div >

        {tenantAllData?.all_tenants?.length > 1 && (<button className={`${styles.DelTenantBU}`} onClick={(e) => setTenantDeleteModal(true)}><Trash2 />{tenantData?.full_name}</button>)}

        < Dialog
            open={fileDeleteModal}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setFileDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">
                Delete File
                <button onClick={(e) => setFileDeleteModal(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="ModalFormGroup">
                    <label className="PopupBodyText">Are you sure you want to delete this item?</label>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="CancelPopupBU" onClick={(e) => delFile()}>Delete</Button>
                <Button className="SubmitPopupBU" onClick={(e) => setFileDeleteModal(false)}>Cancel</Button>
            </DialogActions>
        </Dialog >

        <Dialog
            onClose={(e) => setTenantDeleteModal(false)}
            aria-labelledby="customized-dialog-title"
            open={tenantDeleteModal}
            fullWidth
            maxWidth="sm"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete the Tenant Record?<button onClick={(e) => setTenantDeleteModal(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="ModalFormGroup">
                    <label className="PopupBodyText">The Tenant Record will be removed immediately. You can't undo this action.</label>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="CancelPopupBU" onClick={(e) => tenantDelConfirm()}>Delete "{tenantData?.full_name}"</Button>
                <Button onClick={(e) => setTenantDeleteModal(false)} className="SubmitPopupBU">Cancel</Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default TenantTab;