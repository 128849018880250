import { useEffect, useRef, useState } from "react";
import styles from "../dashboard.module.css";
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import { geoJson } from "./GeoJson";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { ArrowLeft, RotateCcw } from "react-feather";
import { useHistory } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SmartDeviceStatus3D = () => {
    const redirect = useHistory();
    const { getAccessTokenSilently, user } = useAuth0();
    const mapRef = useRef()
    const mapContainerRef = useRef();
    const [sourceJson, setSourceJson] = useState(null);
    const [sourceJson2, setSourceJson2] = useState(null);
    const [apartmentData, setApartmentData] = useState([]);
    const [isMapLoad, setIsMapLoad] = useState(false);
    const [isDetails, setIsDetails] = useState(false);
    const [selectedFloor, setSelectedFloor] = useState(0);
    const [selectedMap, setSelectedMap] = useState('device');

    useEffect(() => {
        async function fetchData() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-device-status/1", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setApartmentData(response.data.data);
            }).catch((error) => {
                setApartmentData([]);
            });
        }
        fetchData();
    }, [getAccessTokenSilently, user]);

    useEffect(() => {
        let geoJsonTemp = geoJson;
        let geoJsonTemp2 = geoJson;
        if (apartmentData?.length > 0) {
            let apartmentDataTemp = apartmentData?.map(item => {
                var aptNoInt = item?.apt_no?.replace(/^\D+/g, '');
                return { ...item, apt_no_int: parseInt(aptNoInt) };
            });

            let jsonFeatures = geoJsonTemp?.features?.map(item => {
                let newProperties = { is_show: 0 };
                let selectedApt = apartmentDataTemp?.filter(i => i?.apt_no_int === item?.id);
                if (selectedApt?.length > 0) {
                    let minHeight = (parseInt(selectedApt[0]?.floor) - 1) * 6;
                    let height = minHeight + 4;
                    let color = "#d5d8d5";
                    let hcolor = "#bec2be";
                    if (selectedApt[0]?.no_of_online > 0 && selectedApt[0]?.no_of_offline === 0) {
                        color = "#00ff00";
                        hcolor = "#1c961c";
                    }
                    if (selectedApt[0]?.no_of_offline > 0) {
                        color = "#FF0000";
                        hcolor = "#c11313";
                    }
                    newProperties = { ...newProperties, floor: selectedApt[0]?.floor, property_id: selectedApt[0]?.property_id, min_height: minHeight, height: height, apt_no: selectedApt[0]?.apt_no, apt_id: selectedApt[0]?.id, no_of_offline: selectedApt[0]?.no_of_offline, no_of_online: selectedApt[0]?.no_of_online, color: color, hcolor: hcolor, is_show: 1 };
                } else if (item?.id === 508) {
                    newProperties = { ...newProperties, floor: 5, property_id: 1, min_height: 24, height: 28, apt_no: 'Unit 508', apt_id: 0, no_of_offline: 0, no_of_online: 0, color: "#d5d8d5", hcolor: "#bec2be", is_show: 1 };
                }

                return { ...item, properties: newProperties };
            });

            geoJsonTemp = { ...geoJsonTemp, features: jsonFeatures };


            let jsonFeatures2 = geoJsonTemp?.features?.map(item => {
                let newProperties = { is_show: 0 };
                let selectedApt = apartmentDataTemp?.filter(i => i?.apt_no_int === item?.id);
                if (selectedApt?.length > 0) {
                    let minHeight = (parseInt(selectedApt[0]?.floor) - 1) * 6;
                    let height = minHeight + 4;
                    let color = "#d5d8d5";
                    let hcolor = "#bec2be";
                    if (selectedApt[0]?.current_status === 2) {
                        color = "#3676D1";
                        hcolor = "#2c63b2";
                    }
                    if (selectedApt[0]?.current_status === 1) {
                        color = "#42DE5C";
                        hcolor = "#3bc652";
                    }
                    if (selectedApt[0]?.current_status === 3) {
                        color = "#FF8439";
                        hcolor = "#e17431";
                    }
                    newProperties = { ...newProperties, floor: selectedApt[0]?.floor, property_id: selectedApt[0]?.property_id, apt_no: selectedApt[0]?.apt_no, apt_id: selectedApt[0]?.id, current_status: selectedApt[0]?.current_status, color: color, hcolor: hcolor, min_height: minHeight, height: height, is_show: 1 };
                } else if (item?.id === 508) {
                    newProperties = { ...newProperties, floor: 5, property_id: 1, apt_no: 'Unit 508', apt_id: 0, current_status: 2, color: "#3676D1", hcolor: "#2c63b2", min_height: 24, height: 28, is_show: 1 };
                }

                return { ...item, properties: newProperties };
            });

            geoJsonTemp2 = { ...geoJsonTemp, features: jsonFeatures2 };
        }

        setSourceJson(geoJsonTemp);
        setSourceJson2(geoJsonTemp2);
    }, [apartmentData]);

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiY29tbWh1YiIsImEiOiJjazZxNzFmcXgwaGl2M2twN3h6djV2enBwIn0.5mvVWwnQbxxkLXhCGet8ug';

        if (typeof (mapRef.current) === 'undefined') {

            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/commhub/cm2u1czn9003c01pq6u5icg3g',
                center: [-83.100067, 42.370327],
                zoom: 18.3,
                pitch: 68,
                bearing: 150
            });
        }

        mapRef.current.on('load', () => {
            if (sourceJson && sourceJson2) {
                setIsMapLoad(true);
            }
        });

    }, [sourceJson, sourceJson2]);

    useEffect(() => {
        if (selectedMap === 'device') {
            if (isMapLoad && sourceJson) {
                if (isDetails) {
                    if (mapRef.current.getLayer('floor-layer-1')) {
                        mapRef.current.removeLayer('floor-layer-1');
                    }

                    if (mapRef.current.getLayer('floor-layer-details-1')) {
                        mapRef.current.removeLayer('floor-layer-details-1');
                    }

                    if (mapRef.current.getSource('floor-source-1')) {
                        mapRef.current.removeSource('floor-source-1');
                    }

                    if (mapRef.current.getLayer('floor-layer-2')) {
                        mapRef.current.removeLayer('floor-layer-2');
                    }

                    if (mapRef.current.getLayer('floor-layer-details-2')) {
                        mapRef.current.removeLayer('floor-layer-details-2');
                    }

                    if (mapRef.current.getSource('floor-source-2')) {
                        mapRef.current.removeSource('floor-source-2');
                    }

                    mapRef.current.addSource('floor-source-1', {
                        type: 'geojson',
                        data: sourceJson
                    });

                    let hoveredPolygonIdDetails = null;

                    const popup1 = new mapboxgl.Popup({
                        closeButton: false,
                        closeOnClick: false,
                        offset: [0, -10]
                    });

                    mapRef.current.addLayer({
                        id: 'floor-layer-details-1',
                        type: 'fill',
                        source: 'floor-source-1',
                        paint: {
                            'fill-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                ['get', 'hcolor'],
                                ['get', 'color']
                            ],
                            'fill-opacity': 1,
                        },
                        filter: ['==', ['get', 'floor'], selectedFloor]
                    });

                    mapRef.current.setZoom(19);
                    mapRef.current.setCenter([-83.1003097, 42.3706527]);
                    mapRef.current.setPitch(0);

                    mapRef.current.on('mousemove', 'floor-layer-details-1', (e) => {
                        mapRef.current.getCanvas().style.cursor = 'pointer';
                        if (e.features.length > 0) {
                            if (hoveredPolygonIdDetails !== null) {
                                mapRef.current.setFeatureState(
                                    { source: 'floor-source-1', id: hoveredPolygonIdDetails },
                                    { hover: false }
                                );

                            }
                            hoveredPolygonIdDetails = e.features[0].id;

                            mapRef.current.setFeatureState(
                                { source: 'floor-source-1', id: hoveredPolygonIdDetails },
                                { hover: true }
                            );

                            //var features = points(e.features[0].geometry.coordinates[0]);

                            //var center1 = center(features);

                            let htmldiv = '<div><div><label>Apartment: </label><strong>' + (e.features[0].properties?.apt_no) + '</strong></div><div><label>No of Online Device: </label><span>' + (e.features[0].properties?.no_of_online) + '</span></div><div><label>No of Offline Device: </label><span>' + (e.features[0].properties?.no_of_offline) + '</span></div></div>';

                            popup1.setLngLat(e?.lngLat).setHTML(htmldiv).addTo(mapRef.current);
                        }
                    });

                    mapRef.current.on('mouseleave', 'floor-layer-details-1', () => {
                        mapRef.current.getCanvas().style.cursor = '';
                        popup1.remove();
                        if (hoveredPolygonIdDetails !== null) {
                            mapRef.current.setFeatureState(
                                { source: 'floor-source-1', id: hoveredPolygonIdDetails },
                                { hover: false }
                            );
                        }
                        hoveredPolygonIdDetails = null;
                    });

                    mapRef.current.on('click', 'floor-layer-details-1', (e) => {
                        e.preventDefault();
                        if (e.features.length > 0) {
                            localStorage.setItem("propertyFilterData", e.features[0]?.properties?.property_id);
                            localStorage.setItem("aptFilterData", e.features[0]?.properties?.apt_id);
                            redirect.push('/smart-devices/list');
                        }
                    });

                } else {
                    if (mapRef.current.getLayer('floor-layer-1')) {
                        mapRef.current.removeLayer('floor-layer-1');
                    }

                    if (mapRef.current.getLayer('floor-layer-details-1')) {
                        mapRef.current.removeLayer('floor-layer-details-1');
                    }

                    if (mapRef.current.getSource('floor-source-1')) {
                        mapRef.current.removeSource('floor-source-1');
                    }

                    if (mapRef.current.getLayer('floor-layer-2')) {
                        mapRef.current.removeLayer('floor-layer-2');
                    }

                    if (mapRef.current.getLayer('floor-layer-details-2')) {
                        mapRef.current.removeLayer('floor-layer-details-2');
                    }

                    if (mapRef.current.getSource('floor-source-2')) {
                        mapRef.current.removeSource('floor-source-2');
                    }

                    mapRef.current.addSource('floor-source-1', {
                        type: 'geojson',
                        data: sourceJson
                    });

                    mapRef.current.addLayer({
                        id: 'floor-layer-1',
                        type: 'fill-extrusion',
                        source: 'floor-source-1',
                        paint: {
                            'fill-extrusion-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                ['get', 'hcolor'],
                                ['get', 'color']
                            ],
                            'fill-extrusion-height': ['get', 'height'],
                            'fill-extrusion-base': ['get', 'min_height'],
                            'fill-extrusion-opacity': 1,
                        },
                        filter: ["==", ["get", "is_show"], 1]
                    });

                    mapRef.current.setZoom(18.3);
                    mapRef.current.setCenter([-83.100067, 42.370327]);
                    mapRef.current.setPitch(68);

                    let hoveredPolygonIds = [];

                    mapRef.current.on('mousemove', 'floor-layer-1', (e) => {
                        if (e.features.length > 0) {
                            if (hoveredPolygonIds?.length > 0) {
                                hoveredPolygonIds?.map(item => {
                                    mapRef.current.setFeatureState(
                                        { source: 'floor-source-1', id: item },
                                        { hover: false }
                                    );

                                    return null;
                                })

                            }
                            hoveredPolygonIds = e.features[0].id;

                            let selFloor = e.features[0]?.properties?.floor;

                            let polygonIds = sourceJson?.features?.map(item => {
                                if (item?.properties?.floor === selFloor) {
                                    return item?.id;
                                }
                                return null;
                            });

                            polygonIds = polygonIds?.filter(i => i !== null);

                            hoveredPolygonIds = polygonIds;

                            hoveredPolygonIds?.map(item => {
                                mapRef.current.setFeatureState(
                                    { source: 'floor-source-1', id: item },
                                    { hover: true }
                                );

                                return null;
                            });

                        }
                    });

                    mapRef.current.on('mouseleave', 'floor-layer-1', () => {
                        if (hoveredPolygonIds?.length > 0) {
                            hoveredPolygonIds?.map(item => {
                                mapRef.current.setFeatureState(
                                    { source: 'floor-source-1', id: item },
                                    { hover: false }
                                );

                                return null;
                            })
                        }
                        hoveredPolygonIds = [];
                    });

                    mapRef.current.on('click', 'floor-layer-1', (e) => {
                        e.preventDefault();
                        if (e.features.length > 0) {
                            setIsDetails(true);
                            setSelectedFloor(e.features[0]?.properties?.floor);
                        }
                    });
                }

            }
        }

        if (selectedMap === 'occupancy') {
            if (isMapLoad && sourceJson2) {
                if (isDetails) {
                    if (mapRef.current.getLayer('floor-layer-1')) {
                        mapRef.current.removeLayer('floor-layer-1');
                    }

                    if (mapRef.current.getLayer('floor-layer-details-1')) {
                        mapRef.current.removeLayer('floor-layer-details-1');
                    }

                    if (mapRef.current.getSource('floor-source-1')) {
                        mapRef.current.removeSource('floor-source-1');
                    }

                    if (mapRef.current.getLayer('floor-layer-2')) {
                        mapRef.current.removeLayer('floor-layer-2');
                    }

                    if (mapRef.current.getLayer('floor-layer-details-2')) {
                        mapRef.current.removeLayer('floor-layer-details-2');
                    }

                    if (mapRef.current.getSource('floor-source-2')) {
                        mapRef.current.removeSource('floor-source-2');
                    }

                    mapRef.current.addSource('floor-source-2', {
                        type: 'geojson',
                        data: sourceJson2
                    });

                    let hoveredPolygonIdDetails = null;

                    const popup = new mapboxgl.Popup({
                        closeButton: false,
                        closeOnClick: false,
                        offset: [0, -10]
                    });

                    mapRef.current.addLayer({
                        id: 'floor-layer-details-2',
                        type: 'fill',
                        source: 'floor-source-2',
                        paint: {
                            'fill-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                ['get', 'hcolor'],
                                ['get', 'color']
                            ],
                            'fill-opacity': 1,
                        },
                        filter: ['==', ['get', 'floor'], selectedFloor]
                    });

                    mapRef.current.setZoom(19);
                    mapRef.current.setCenter([-83.1003097, 42.3706527]);
                    mapRef.current.setPitch(0);

                    mapRef.current.on('mousemove', 'floor-layer-details-2', (e) => {
                        mapRef.current.getCanvas().style.cursor = 'pointer';
                        if (e.features.length > 0) {
                            if (hoveredPolygonIdDetails !== null) {
                                mapRef.current.setFeatureState(
                                    { source: 'floor-source-2', id: hoveredPolygonIdDetails },
                                    { hover: false }
                                );

                            }
                            hoveredPolygonIdDetails = e.features[0].id;

                            mapRef.current.setFeatureState(
                                { source: 'floor-source-2', id: hoveredPolygonIdDetails },
                                { hover: true }
                            );

                            //var features = points(e.features[0].geometry.coordinates[0]);

                            //var center1 = center(features);

                            let cStatus = 'Not Listed';
                            if (e.features[0].properties?.current_status === 1) {
                                cStatus = 'Available';
                            }
                            if (e.features[0].properties?.current_status === 2) {
                                cStatus = 'Rented';
                            }
                            if (e.features[0].properties?.current_status === 3) {
                                cStatus = 'Coming Soon';
                            }

                            let htmldiv = '<div><div><label>Apartment: </label><strong>' + (e.features[0].properties?.apt_no) + '</strong></div><div><label>Current Status: </label><span>' + cStatus + '</span></div></div>';

                            popup.setLngLat(e?.lngLat).setHTML(htmldiv).addTo(mapRef.current);
                        }
                    });

                    mapRef.current.on('mouseleave', 'floor-layer-details-2', () => {
                        mapRef.current.getCanvas().style.cursor = '';
                        popup.remove();
                        if (hoveredPolygonIdDetails !== null) {
                            mapRef.current.setFeatureState(
                                { source: 'floor-source-2', id: hoveredPolygonIdDetails },
                                { hover: false }
                            );
                        }
                        hoveredPolygonIdDetails = null;
                    });

                    mapRef.current.on('click', 'floor-layer-details-2', (e) => {
                        e.preventDefault();
                        if (e.features.length > 0) {
                            redirect.push('/properties/details/1');
                        }
                    });

                } else {
                    if (mapRef.current.getLayer('floor-layer-2')) {
                        mapRef.current.removeLayer('floor-layer-2');
                    }

                    if (mapRef.current.getLayer('floor-layer-details-2')) {
                        mapRef.current.removeLayer('floor-layer-details-2');
                    }

                    if (mapRef.current.getSource('floor-source-2')) {
                        mapRef.current.removeSource('floor-source-2');
                    }

                    mapRef.current.addSource('floor-source-2', {
                        type: 'geojson',
                        data: sourceJson2
                    });

                    mapRef.current.addLayer({
                        id: 'floor-layer-2',
                        type: 'fill-extrusion',
                        source: 'floor-source-2',
                        paint: {
                            'fill-extrusion-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                ['get', 'hcolor'],
                                ['get', 'color']
                            ],
                            'fill-extrusion-height': ['get', 'height'],
                            'fill-extrusion-base': ['get', 'min_height'],
                            'fill-extrusion-opacity': 1,
                        },
                        filter: ["==", ["get", "is_show"], 1]
                    });

                    mapRef.current.setZoom(18.3);
                    mapRef.current.setCenter([-83.100067, 42.370327]);
                    mapRef.current.setPitch(68);

                    let hoveredPolygonIds = [];

                    mapRef.current.on('mousemove', 'floor-layer-2', (e) => {
                        if (e.features.length > 0) {
                            if (hoveredPolygonIds?.length > 0) {
                                hoveredPolygonIds?.map(item => {
                                    mapRef.current.setFeatureState(
                                        { source: 'floor-source-2', id: item },
                                        { hover: false }
                                    );

                                    return null;
                                })

                            }
                            hoveredPolygonIds = e.features[0].id;

                            let selFloor = e.features[0]?.properties?.floor;

                            let polygonIds = sourceJson?.features?.map(item => {
                                if (item?.properties?.floor === selFloor) {
                                    return item?.id;
                                }
                                return null;
                            });

                            polygonIds = polygonIds?.filter(i => i !== null);

                            hoveredPolygonIds = polygonIds;

                            hoveredPolygonIds?.map(item => {
                                mapRef.current.setFeatureState(
                                    { source: 'floor-source-2', id: item },
                                    { hover: true }
                                );

                                return null;
                            });

                        }
                    });

                    mapRef.current.on('mouseleave', 'floor-layer-2', () => {
                        if (hoveredPolygonIds?.length > 0) {
                            hoveredPolygonIds?.map(item => {
                                mapRef.current.setFeatureState(
                                    { source: 'floor-source-2', id: item },
                                    { hover: false }
                                );

                                return null;
                            })
                        }
                        hoveredPolygonIds = [];
                    });

                    mapRef.current.on('click', 'floor-layer-2', (e) => {
                        e.preventDefault();
                        if (e.features.length > 0) {
                            setIsDetails(true);
                            setSelectedFloor(e.features[0]?.properties?.floor);
                        }
                    });
                }

            }
        }

    }, [isDetails, selectedFloor, isMapLoad, sourceJson, sourceJson2, redirect, selectedMap]);

    const reloadMap = () => {
        if (mapRef.current) {
            setIsDetails(false);
            mapRef.current.setZoom(18.3);
            mapRef.current.setCenter([-83.100067, 42.370327]);
            mapRef.current.setPitch(68);
            mapRef.current.setBearing(150);
        }
    }

    return (<div className={`${styles.IconCardDivTwo}`}>
        <div className={`${styles.IconCardFullDiv}`}>
            <div className={`${styles.IfnoCard}`}>
                <div className={`${styles.BatteryHead3D}`}>
                    <div className={`${styles.FormGroupSelect} FormGroup`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Select Type"
                                value={selectedMap}
                                onChange={(e) => {
                                    setSelectedMap(e.target.value);
                                    setIsDetails(false);
                                }}
                            >
                                <MenuItem value={'device'}>Smart Device Status</MenuItem>
                                <MenuItem value={'occupancy'}>Availability Status</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <button onClick={(e) => reloadMap()} className={`${styles.ReloadMapBU}`}><RotateCcw />Reset</button>
                </div>
                <div className={`${styles.InsideCard}`}>
                    <div className={`${styles.Map3D}`}>
                        {isDetails && <button onClick={(e) => { setIsDetails(false); setSelectedFloor(0); }}><ArrowLeft /> Back</button>}
                        <div className={`${styles.Map3SCanvas}`}>
                            <div ref={mapContainerRef} style={{ height: '100%', width: '100%' }} />
                        </div>
                    </div>
                    {selectedMap === 'occupancy' && (<div className={`${styles.MapOccupancyLagendSec}`}>
                        <div className={`${styles.MapOccuLagend}`}>
                            <div className={`${styles.OccuLagend1}`}></div>
                            <p>Rented</p>
                        </div>
                        <div className={`${styles.MapOccuLagend}`}>
                            <div className={`${styles.OccuLagend2}`}></div>
                            <p>Available</p>
                        </div>
                        <div className={`${styles.MapOccuLagend}`}>
                            <div className={`${styles.OccuLagend3}`}></div>
                            <p>Coming Soon</p>
                        </div>
                        <div className={`${styles.MapOccuLagend}`}>
                            <div className={`${styles.OccuLagend4}`}></div>
                            <p>Not Listed</p>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </div>);
}

export default SmartDeviceStatus3D;