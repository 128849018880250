import React, { useState, useEffect } from "react";
import styles from "./maintenancerequest.module.css";
import Loader from "../../components/Loader";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Footer from "../../components/Footer";
import { Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, Snackbar, TablePagination, Typography } from "@mui/material";
import { ArrowLeft, ArrowRight, ArrowUp, Calendar, ChevronDown, Filter, Home, Plus, Search, X } from "react-feather";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import DateRangePicker from "rsuite/DateRangePicker";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { TableLoader } from "../../components/LoaderC";
import relativeTime from 'dayjs/plugin/relativeTime';
import { AddLogCallAPI } from "../../components/AddLogs";
import AddMaintenanceForm from "./components/AddMaintenanceForm";

dayjs.extend(relativeTime);

const OpenedToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.Open}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    Open
  </button>
));

const ResolvedToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.Resolved}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    Resolved
  </button>
));

const InprogressToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.InProgress}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    In-Progress
  </button>
));

const CancelledToggleReadOnly = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.ReferStatusBUNew} ${styles.Cancelled}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    Cancelled
  </button>
));

const OpenedToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.Open}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    Open <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const ResolvedToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.Resolved}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    Resolved <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const InprogressToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.InProgress}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    In-Progress <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const CancelledToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.Cancelled}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    Cancelled <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const MaintenanceRequest = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [allProperties, setAllProperties] = useState([]);
  const [allAssignees, setAllAssignees] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({ searchKey: "", property: "", apartment: "", status: "", view: "", priority: "", assignee: "", created: null });
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [perPage, setPerPage] = useState(8);
  const [apartmentList, setApartmentList] = useState([]);

  useEffect(() => {
    const fetchAllProperties = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_PAYMENT_API_URL + "v1/property-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        let propertyData = response.data.data;
        propertyData = [{ value: "all", label: "Any" }].concat(propertyData);

        setAllProperties(propertyData);

        if (localStorage.getItem("propertyFilterData")) {
          let filterPropertyId = localStorage.getItem("propertyFilterData");
          localStorage.removeItem("propertyFilterData");

          let propertyTemp = propertyData.filter((i) => i.value === parseInt(filterPropertyId));
          if (propertyTemp.length) {
            setApartmentList([{ value: "all", label: "Any" }].concat(propertyTemp[0]?.apartments));
          }

          setFilterData(prev => { return { ...prev, property: parseInt(filterPropertyId) } });
        }

        if (localStorage.getItem("apartmentFilterData")) {
          let apartmentFilter = localStorage.getItem("apartmentFilterData");
          localStorage.removeItem("apartmentFilterData");
          apartmentFilter = parseInt(apartmentFilter);

          setFilterData(prev => { return { ...prev, apartment: parseInt(apartmentFilter) } });
        }

        if (localStorage.getItem("statusFilterData")) {
          let filterStatus = localStorage.getItem("statusFilterData");
          localStorage.removeItem("statusFilterData");

          setFilterData(prev => { return { ...prev, status: filterStatus } });
        }
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    };
    fetchAllProperties();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchAllAssignees = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_CASE_API_URL + "v1/technicians", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        let aData = response.data.data;
        aData = [{ value: "all", label: "Any" }].concat(aData);

        setAllAssignees(aData);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    };
    fetchAllAssignees();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const getRequests = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);

        let allDataTemp = response.data.data?.map(item => {
          if (item?.sub_category === 'Electrical Socket')
            return { ...item, image: '/static/img/ElectricalSocket.svg' };
          if (item?.sub_category === 'Light Switch/Dimmer')
            return { ...item, image: '/static/img/Dimmer.svg' };
          if (item?.sub_category === 'Leak')
            return { ...item, image: '/static/img/Leak.svg' };
          if (item?.sub_category === 'Drain clogged')
            return { ...item, image: '/static/img/DrainClogged.svg' };
          if (item?.sub_category === 'Water temperature Issue')
            return { ...item, image: '/static/img/WaterTemperature.svg' };
          if (item?.sub_category === 'Stove/Oven Issue')
            return { ...item, image: '/static/img/StoveOven.svg' };
          if (item?.sub_category === 'Refrigerator Issue')
            return { ...item, image: '/static/img/Refrigerator.svg' };
          if (item?.sub_category === 'Washer/Dryer Issue')
            return { ...item, image: '/static/img/Washer.svg' };
          if (item?.sub_category === 'Other Issue')
            return { ...item, image: '/static/img/other.svg' };
          return { ...item, image: '/static/img/other.svg' };
        })

        setAllData(allDataTemp);

      }).catch((error) => {
        setContentLoading(false);
      });
    }
    getRequests();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    let filteredDataTemp = allData;

    if (filterData?.searchKey !== "") {
      let inputVal = filterData?.searchKey;
      inputVal = inputVal.toLowerCase();
      filteredDataTemp = filteredDataTemp.filter((item) => item.title.toLowerCase().includes(inputVal) || item.request_no.toLowerCase().includes(inputVal) || item.tenant_name.toLowerCase().includes(inputVal) || item.property_name.toLowerCase().includes(inputVal) || item.apt_name.toLowerCase().includes(inputVal) || item.assignee_name.toLowerCase().includes(inputVal) || item.overdue.toLowerCase().includes(inputVal));
    }

    if (filterData.property !== "" && filterData.property !== "all") {
      filteredDataTemp = filteredDataTemp.filter((i) => i.property_id === filterData.property);
    }

    if (filterData.apartment !== "" && filterData.apartment !== "all") {
      filteredDataTemp = filteredDataTemp.filter((i) => i.apt_id === filterData.apartment);
    }

    if (filterData.status !== "" && filterData.property !== "all") {
      if (filterData.status === "Open") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.current_status === 0 && i.is_archived === 0);
      }
      if (filterData.status === "In-Progress") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.current_status === 1 && i.is_archived === 0);
      }
      if (filterData.status === "Resolved") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.current_status === 2 && i.is_archived === 0);
      }
      if (filterData.status === "Cancelled") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.current_status === 3 && i.is_archived === 0);
      }
    }

    if (filterData.priority !== "" && filterData.priority !== "all") {
      if (filterData.priority === "Low") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.priority === 1);
      }
      if (filterData.priority === "Medium") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.priority === 2);
      }
      if (filterData.priority === "High") {
        filteredDataTemp = filteredDataTemp.filter((i) => i.priority === 3);
      }
    }

    if (filterData.assignee !== "" && filterData.assignee !== "all") {
      filteredDataTemp = filteredDataTemp.filter((i) => i?.last_appointments?.assignee_id === filterData.assignee);
    }

    if (filterData.created) {
      filteredDataTemp = filteredDataTemp.filter((i) => {
        var dateR = filterData.created;
        if (dayjs(dayjs(i.created_at).format("YYYY-MM-DD")).isBetween(dayjs(dateR[0]).format("YYYY-MM-DD"), dayjs(dateR[1]).format("YYYY-MM-DD"), undefined, [])) {
          return true;
        }
        return false;
      });
    }

    setFilteredData(filteredDataTemp);
  }, [allData, filterData]);

  const changeStatus = async (item, status) => {
    if (item.current_status !== status) {
      setLoading(true);

      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios.put(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + item?.id, { current_status: status }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setAllData((prev) => {
          return prev.map((i) => {
            if (item.id === i.id) {
              return { ...i, current_status: status };
            }
            return i;
          });
        });

        let respData = response.data.data;

        let statusText = "Open";
        if (respData.current_status === 1) {
          statusText = "In-Progress";
        }
        if (respData.current_status === 2) {
          statusText = "Resolved";
        }
        if (respData.current_status === 3) {
          statusText = "Cancelled";
        }
        let logData = {
          title: "Maintenance request status changed",
          description: [
            "Name: " + respData.title,
            "Request number: " + respData.request_no,
            "Property: " + respData.property_name,
            "Apartment: " + respData.apt_name,
            "Tenant: " + respData.tenant_name,
            "Status: " + statusText,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    }
  }

  const onSubmitLocal = (data) => {
    data = { ...data, image: '/static/img/other.svg' };
    if (data?.sub_category === 'Electrical Socket')
      data = { ...data, image: '/static/img/ElectricalSocket.svg' };
    if (data?.sub_category === 'Light Switch/Dimmer')
      data = { ...data, image: '/static/img/Dimmer.svg' };
    if (data?.sub_category === 'Leak')
      data = { ...data, image: '/static/img/Leak.svg' };
    if (data?.sub_category === 'Drain clogged')
      data = { ...data, image: '/static/img/DrainClogged.svg' };
    if (data?.sub_category === 'Water temperature Issue')
      data = { ...data, image: '/static/img/WaterTemperature.svg' };
    if (data?.sub_category === 'Stove/Oven Issue')
      data = { ...data, image: '/static/img/StoveOven.svg' };
    if (data?.sub_category === 'Refrigerator Issue')
      data = { ...data, image: '/static/img/Refrigerator.svg' };
    if (data?.sub_category === 'Washer/Dryer Issue')
      data = { ...data, image: '/static/img/Washer.svg' };
    if (data?.sub_category === 'Other Issue')
      data = { ...data, image: '/static/img/other.svg' };

    setAllData((prev) => {
      return [data].concat(prev);
    });
  }

  return (<React.Fragment>
    {loading && <Loader />}
    <Helmet title="Properties" />

    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>Maintenance Request</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Cases</li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>Maintenance Request</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.BodyCon}`}>
      <div className={`${styles.mainCardDiv}`}>
        <div className={`${styles.mainCard}`}>

          {contentLoading && (<div className="Loader TableLoader">
            <TableLoader />
          </div>)}

          {!contentLoading && (<div className="control-pane">
            <div className={`${styles.Row}`}>

              <div className={`${styles.SearchFilterSec}`}>
                <div className={`${styles.SearchCon}`}>
                  <div className={`${styles.SearchConSec}`}>
                    <Search />
                    <input type="text" placeholder="Search" onChange={(e) => setFilterData((prev) => { return { ...prev, searchKey: e.target.value }; })} />
                  </div>
                </div>

                <div className={`${styles.FilterSec}`}>
                  <div className={filterOpen === true ? `${styles.FilterFieldSec} ${styles.Open}` : `${styles.FilterFieldSec}`}>
                    <p className={`${styles.FilterTitle}`}>
                      Filters
                      <span onClick={(e) => setFilterOpen(false)}><ArrowRight /></span>
                    </p>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width200} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Property</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Property"
                          value={filterData?.property}
                          onChange={(e) => {
                            setFilterData((prev) => { return { ...prev, property: e.target.value }; });

                            if (e.target.value !== "all") {
                              let propertyTemp = allProperties.filter((i) => i.value === e.target.value);
                              setApartmentList([{ value: "all", label: "Any" }].concat(propertyTemp[0]?.apartments));
                            } else {
                              setApartmentList([{ value: "all", label: "Any" }]);
                            }
                          }}
                        >
                          {allProperties?.map((i) => {
                            return (<MenuItem value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width160} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Apartment</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Apartment"
                          value={filterData?.apartment}
                          onChange={(e) => { setFilterData((prev) => { return { ...prev, apartment: e.target.value }; }); }}
                        >
                          {apartmentList?.map((i) => {
                            return (<MenuItem value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width130} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Status"
                          value={filterData?.status}
                          onChange={(e) => setFilterData((prev) => { return { ...prev, status: e.target.value }; })}
                        >
                          <MenuItem value={"all"}>{"Any"}</MenuItem>
                          <MenuItem value={"Open"}>{"Open"}</MenuItem>
                          <MenuItem value={"In-Progress"}>{"In-Progress"}</MenuItem>
                          <MenuItem value={"Resolved"}>{"Resolved"}</MenuItem>
                          <MenuItem value={"Cancelled"}>{"Cancelled"}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width130} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Priority"
                          onChange={(e) => setFilterData((prev) => { return { ...prev, priority: e.target.value }; })}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"Low"}>Low</MenuItem>
                          <MenuItem value={"Medium"}>Medium</MenuItem>
                          <MenuItem value={"High"}>High</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width180} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Technician</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Technician"
                          onChange={(e) => setFilterData((prev) => { return { ...prev, assignee: e.target.value }; })}
                        >
                          {allAssignees?.map((i) => {
                            return (<MenuItem value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard}`}>
                      <div className={`${styles.DashFilterSec} DashFilterSec Filter`}>
                        <DateRangePicker
                          format={"MM/dd/yy"}
                          placeholder={"Filter by date"}
                          placement={"bottomEnd"}
                          caretAs={Calendar}
                          showOneCalendar
                          onChange={(e) => setFilterData((prev) => { return { ...prev, created: e }; })}
                        />
                      </div>
                    </div>
                  </div>
                  <button className={`${styles.FilterBu}`} onClick={(e) => setFilterOpen(true)}><Filter /></button>
                  <button className={`${styles.AddNoteBu}`} onClick={(e) => setAddModalOpen(true)}><Plus /> <span>Maintenance</span></button>
                </div>
              </div>

              <div className={`${styles.NotesRow}`}>

                {filteredData.map((item, index) => {
                  return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Link className={`${styles.ProspectCard}`} key={item?.id} to={`/cases/maintenance-request/details/${item.id}`}>
                    <div className={`${styles.ProspectCardUnder}`}>
                      <div className={`${styles.ProspectCardHeadSection}`}>
                        <div className={`${styles.ProsIMGSec}`}>
                          <img src={item?.image} className={`${styles.ProspectCardImg}`} alt="" />
                        </div>

                        <div className={`${styles.HeadConSec}`}>
                          <div className={`${styles.prospectNameActionSec}`}>
                            <p className={`${styles.ProsUserName}`}>
                              {item?.tenant_name}
                              {item.priority === 1 && (<p className={`${styles.ReffStatus} ${styles.Low}`}>
                                <ArrowUp />
                                <span>Low</span>
                              </p>)}
                              {item.priority === 2 && (<p className={`${styles.ReffStatus} ${styles.Medium}`}>
                                <ArrowUp />
                                <span>Medium</span>
                              </p>)}
                              {item.priority === 3 && (<p className={`${styles.ReffStatus} ${styles.High}`}>
                                <ArrowUp />
                                <span>High</span>
                              </p>)}
                            </p>
                          </div>
                          <p className={`${styles.ProsUserName}`}>{item?.title}</p>
                        </div>
                      </div>
                      <div className={`${styles.ProspectCardBodySec}`}>
                        <ul>
                          <li>
                            <p className={`${styles.Title}`}>Technician:</p>
                            <p className={`${styles.Content}`}>{item?.last_appointments ? item?.last_appointments?.assignee_name : '-'}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Date & Time:</p>
                            <p className={`${styles.Content}`}>{item?.last_appointments ? dayjs(item?.last_appointments?.datetime).format('MM/DD/YYYY | h:mmA') : '-'}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Property:</p>
                            <p className={`${styles.Content}`}>{item?.property_name}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Unit:</p>
                            <p className={`${styles.Content}`}>{item?.apt_name}</p>
                          </li>
                        </ul>
                      </div>
                      <div className={`${styles.ProspectCardFootSec}`}>
                        <p className={`${styles.ProsCardDate}`}>{dayjs(item?.created_at).fromNow()}</p>
                        <div className={`${styles.MainReqConDropBU}`}>
                          {item.is_archived === 1 && <span className={`${styles.ArchivedBadge}`}>Archived</span>}
                          {!(item.is_archived === 1) && !(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("request:update") > -1) && (<Dropdown>
                            {item.current_status === 0 && (<Dropdown.Toggle as={OpenedToggleReadOnly} />)}
                            {item.current_status === 1 && (<Dropdown.Toggle as={InprogressToggleReadOnly} />)}
                            {item.current_status === 2 && (<Dropdown.Toggle as={ResolvedToggleReadOnly} />)}
                            {item.current_status === 3 && (<Dropdown.Toggle as={CancelledToggleReadOnly} />)}
                          </Dropdown>)}

                          {!(item.is_archived === 1) && isAuthenticated && user["https://propertydek.com/permissions"].indexOf("request:update") > -1 && (<Dropdown>
                            {item.current_status === 0 && (<Dropdown.Toggle as={OpenedToggle} />)}
                            {item.current_status === 1 && (<Dropdown.Toggle as={InprogressToggle} />)}
                            {item.current_status === 2 && (<Dropdown.Toggle as={ResolvedToggle} />)}
                            {item.current_status === 3 && (<Dropdown.Toggle as={CancelledToggle} />)}

                            <Dropdown.Menu className="DropDownMenuBoxCus">
                              <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeStatus(item, 0); }}>Open</Dropdown.Item>
                              <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeStatus(item, 1); }}>In-Progress</Dropdown.Item>
                              <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeStatus(item, 2); }}>Resolved</Dropdown.Item>
                              <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeStatus(item, 3); }}>Cancelled</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>)}
                        </div>
                      </div>
                    </div>
                  </Link>) : null;
                })}

                {filteredData.length > 0 && (<div className="Pagination TablePaginationNew List">
                  <TablePagination
                    count={filteredData.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                      { label: '4', value: 4 },
                      { label: '8', value: 8 },
                      { label: '16', value: 16 },
                      { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                      setPage(0);
                      setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Maintenance requests per Page:'}
                  />
                </div>)}

                {filteredData.length === 0 && (<div className={`${styles.NoDataMain}`}>
                  <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/no-request.png" alt="No Data" />
                  </div>
                  <p className={`${styles.NoDataText}`}>All caught up!</p>
                  <p className={`${styles.NoDataTextSub}`}>There are currently no requests to show.</p>
                </div>)}

              </div>
            </div>

            <Dialog
              open={addModalOpen}
              fullWidth
              maxWidth="sm"
              onClose={(e) => setAddModalOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="NewPopUp MobileForm"
            >
              <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setAddModalOpen(false)}><ArrowLeft /></button>
                New Maintenance Request
                <button onClick={(e) => setAddModalOpen(false)}><X /></button>
              </DialogTitle>
              <DialogContent className={`${styles.RequestBodyPop}`}>
                <AddMaintenanceForm setAddModalOpen={setAddModalOpen.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onSubmitLocal.bind(this)} tenant_id={null} />
              </DialogContent>
            </Dialog>
          </div>)}



        </div>
      </div>
    </div>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </React.Fragment>);
};

export default withAuthenticationRequired(MaintenanceRequest, {
  onRedirecting: () => <Loader />,
});