import React, { useEffect, useState } from "react";
import styles from './leasedetails.module.css';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, Snackbar, Tooltip, Typography } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import { ArrowLeft, Check, DownloadCloud, Home, MoreVertical, Trash2, X } from "react-feather";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { TableLoader } from "../../components/LoaderC";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LeaseDetailsCard from "./components/LeaseDetailsCard";
import LeaseHistoryCard from "./components/LeaseHistoryCard";
import fileDownload from "js-file-download";
import { Box } from "@mui/system";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Link } from "react-router-dom";
import RenewLeaseForm from "./components/RenewLeaseForm";

const LeaseDetails = ({ history, match }) => {
  const { id } = match.params;
  const redirect = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [leaseData, setLeaseData] = useState(null);
  const [expanded, setExpanded] = React.useState("panel1");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentLease, setCurrentLease] = useState(0);
  const [showRenewForm, setShowRenewForm] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0);

  useEffect(() => {
    const getLeaseDetails = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      await axios(process.env.REACT_APP_PAYMENT_API_URL + 'v1/leases-details/' + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setContentLoading(false);
        let dataTemp = response.data.data;

        let leasetitle_dd_list = dataTemp?.leasetitle_dd_list.filter(i => i.is_current === 1);
        if (leasetitle_dd_list.length > 0) {
          setCurrentLease(leasetitle_dd_list[0].value);
          setCurrentStatus(leasetitle_dd_list[0].status);
        }

        if (dataTemp?.fileContent) {
          const blob = base64toBlob(dataTemp?.fileContent);
          const url = URL.createObjectURL(blob);

          dataTemp = { ...dataTemp, LeaseFile: url };
        } else {
          dataTemp = { ...dataTemp, LeaseFile: null };
        }
        setLeaseData(dataTemp);
      }).catch(error => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);

        setContentLoading(false);
      });
    }

    getLeaseDetails();
  }, [getAccessTokenSilently, id]);

  const base64toBlob = (data) => {
    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const downloadFile = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    await axios.get(process.env.REACT_APP_PAYMENT_API_URL + 'v1/leases-download-new/' + leaseData?.id, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
      setLoading(false);
      fileDownload(response.data, 'lease_agreement.pdf');

      setSnackbarOpen(true);
      setSnackbarMsg('Lease agreement downloaded successfull');
    }).catch(error => {
      setLoading(false);
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
    });
  }

  const signLeaseNew = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    return axios(process.env.REACT_APP_PAYMENT_API_URL + 'v1/sign-lease/' + leaseData?.id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(response => {
      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      axios(process.env.REACT_APP_PAYMENT_API_URL + 'v1/leases-details/' + leaseData?.tenant_id, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setLoading(false);
        let dataTemp = response.data.data;

        let leasetitle_dd_list = dataTemp.leasetitle_dd_list.filter(i => i.is_current === 1);
        if (leasetitle_dd_list.length > 0) {
          setCurrentLease(leasetitle_dd_list[0].value);
          setCurrentStatus(leasetitle_dd_list[0].status);
        }

        if (dataTemp.fileContent) {
          const blob = base64toBlob(dataTemp.fileContent);
          const url = URL.createObjectURL(blob);

          dataTemp = { ...dataTemp, LeaseFile: url };
        } else {
          dataTemp = { ...dataTemp, LeaseFile: null };
        }
        setLeaseData(dataTemp);
      }).catch(error => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }).catch(error => {
      setLoading(false);
      toast.error('Error occured. Try again.');
    });
  }

  const confirmLeaseNew = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    return axios(process.env.REACT_APP_PAYMENT_API_URL + 'v1/review-lease/' + leaseData?.id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(response => {
      setLoading(false);
      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      axios(process.env.REACT_APP_PAYMENT_API_URL + 'v1/leases-details/' + leaseData?.tenant_id, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setLoading(false);
        let dataTemp = response.data.data;

        let leasetitle_dd_list = dataTemp.leasetitle_dd_list.filter(i => i.is_current === 1);
        if (leasetitle_dd_list.length > 0) {
          setCurrentLease(leasetitle_dd_list[0].value);
          setCurrentStatus(leasetitle_dd_list[0].status);
        }

        if (dataTemp.fileContent) {
          const blob = base64toBlob(dataTemp.fileContent);
          const url = URL.createObjectURL(blob);

          dataTemp = { ...dataTemp, LeaseFile: url };
        } else {
          dataTemp = { ...dataTemp, LeaseFile: null };
        }
        setLeaseData(dataTemp);
      }).catch(error => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }).catch(error => {
      setLoading(false);
      toast.error('Error occured. Try again.');
    });
  }

  const deleteConfirm = () => {
    alert(leaseData?.id);
  }

  useEffect(() => {
    const getLeaseData = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      setLoading(true);
      axios(process.env.REACT_APP_PAYMENT_API_URL + 'v1/leases-document-data/' + currentLease, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setLoading(false);
        let dataTemp = response.data.data;

        if (dataTemp.fileContent) {
          const blob = base64toBlob(dataTemp.fileContent);
          const url = URL.createObjectURL(blob);

          setLeaseData(prev => {
            return { ...prev, LeaseFile: url };
          });
        } else {
          setLeaseData(prev => {
            return { ...prev, LeaseFile: null };
          });
        }
      }).catch(error => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }

    if (currentLease > 0) {
      getLeaseData();
    }
  }, [currentLease, getAccessTokenSilently]);

  const onSubmitLocal = async (data) => {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    await axios(process.env.REACT_APP_PAYMENT_API_URL + 'v1/leases-details/' + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
      setLoading(false);
      let dataTemp = response.data.data;

      let leasetitle_dd_list = dataTemp?.leasetitle_dd_list.filter(i => i.is_current === 1);
      if (leasetitle_dd_list.length > 0) {
        setCurrentLease(leasetitle_dd_list[0].value);
        setCurrentStatus(leasetitle_dd_list[0].status);
      }

      if (dataTemp?.fileContent) {
        const blob = base64toBlob(dataTemp?.fileContent);
        const url = URL.createObjectURL(blob);

        dataTemp = { ...dataTemp, LeaseFile: url };
      } else {
        dataTemp = { ...dataTemp, LeaseFile: null };
      }
      setLeaseData(dataTemp);
    }).catch(error => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);

      setLoading(false);
    });
  }

  return (<>
    {loading && <Loader />}
    <Helmet title={"Lease: " + leaseData?.tenant_name} />



    <div className={`${styles.mainCard}`}>
      {contentLoading && <div className="Loader TableLoader"><TableLoader /></div>}
      {!contentLoading && <div className={`${styles.SMProCusRow}`}>

        <div className={`${styles.BreadcrumbHeadSec}`}>
          <nav aria-label="breadcrumb">
            <ol className={`${styles.breadcrumb}`}>
              <li className={`${styles.breadcrumbItem}`}><span><Home /></span> Payment</li>
              <li className={`${styles.breadcrumbItem} ${styles.active}`}>Lease: {leaseData?.tenant_name}</li>
            </ol>
          </nav>
          <div className={`${styles.PageTitleSec}`}>
            <div>
              <button onClick={() => redirect.goBack()} className={`${styles.BackArrow}`}><ArrowLeft /></button>
              <Typography display="inline" className={`${styles.pageTitle}`}>Lease: {leaseData?.tenant_name}</Typography>
            </div>
          </div>
        </div>

        {(currentStatus !== 1) && <div className={`${styles.StepperCardMainSec}`}>
          <div className={`${styles.StepperSec}`}>
            {leaseData?.lease_steps?.map((item, index) => {
              if (item.status === 2) {
                return (<div className={`${styles.StepperCard} ${styles.Failed}`}>
                  <div className={`${styles.StepIcon}`}><Check /></div>
                  <p className={`${styles.StepTitle}`}><span>{item.text}</span></p>
                </div>);
              } else if (item.status === 1) {
                return (<div className={`${styles.StepperCard} ${styles.Completed}`}>
                  <div className={`${styles.StepIcon}`}><Check /></div>
                  <p className={`${styles.StepTitle}`}><span>{item.text}</span></p>
                </div>);
              } else if (item.status === -1) {
                return (<div className={`${styles.StepperCard} ${styles.Active}`}>
                  <div className={`${styles.StepIcon}`}><Check /></div>
                  <p className={`${styles.StepTitle}`}><span>{item.text}</span></p>
                </div>);
              } else {
                return (<div className={`${styles.StepperCard}`}>
                  <div className={`${styles.StepIcon}`}><Check /></div>
                  <p className={`${styles.StepTitle}`}><span>{item.text}</span></p>
                </div>);
              }
            })}
          </div>
        </div>}

        <div className={`${styles.SMProCusSmallDiv}`}>

          <div className={`${styles.CardSmall}`}>
            <div className={`${styles.SMProCardHead}`}>
              <div className={`${styles.SMProPicMain}`}>
                <Tooltip title={leaseData?.tenant_name} placement="bottom">
                  <div className={`${styles.avatar} ${styles.active}`}>
                    {leaseData?.tenant_image !== "" ? <img src={leaseData?.tenant_image} className={`${styles.ProspectCardImg}`} alt="" /> : <p>{leaseData?.tenant_initial}</p>}
                  </div>
                </Tooltip>
              </div>
              <Dropdown className={`${styles.ProEditSec}`}>
                <Dropdown.Toggle className={`${styles.EditActionsBU} ProsDrBU`} id="dropdown-basic">
                  <MoreVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                  {(leaseData?.status === 1) && <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => downloadFile()}>
                    <DownloadCloud /> Download
                  </Dropdown.Item>}
                  {/* {(leaseData?.is_esign === 1 && leaseData?.status === 2 && leaseData?.no_of_sign > 0 && (leaseData?.no_of_sign >= leaseData?.no_of_tenanat)) && <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => signLeaseNew()}>
                    <Edit3 /> Sign Lease
                  </Dropdown.Item>}
                  {(leaseData?.is_esign === 0 && leaseData?.status === 2 && leaseData?.no_of_sign > 0 && (leaseData?.no_of_sign >= leaseData?.no_of_tenanat)) && <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => confirmLeaseNew()}>
                    <Edit3 /> Confirm Lease
                  </Dropdown.Item>} */}
                  {(leaseData?.status !== 1) && <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => setOpenDeleteModal(true)}>
                    <Trash2 /> Delete
                  </Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="HistoryAccording Theme">
              <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <div className={`${styles.FormAccoHead}`}>
                    <p className={`${styles.FormAccoHeadTitle}`}>Lease details</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <LeaseDetailsCard data={leaseData} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} />
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                  <div className={`${styles.FormAccoHead}`}>
                    <p className={`${styles.FormAccoHeadTitle}`}>History</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <LeaseHistoryCard data={leaseData} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} />
                </AccordionDetails>
              </Accordion>
            </div>

          </div>
        </div>

        <div className={`${styles.SMProCusBigDiv}`}>

          <div className={`${styles.Card}`}>

            <div className={`${styles.ActionRefMain}`}>
              <div className={`${styles.LeaseDDSec} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Lease</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Lease"
                    value={currentLease}
                    onChange={(e) => {
                      setCurrentLease(e.target.value);

                      let curLease = leaseData?.leasetitle_dd_list?.filter(i => i?.value === e.target.value);
                      if (curLease.length) {
                        setCurrentStatus(curLease[0].status);
                      }
                    }}
                  >
                    {leaseData?.leasetitle_dd_list?.map(i => {
                      return <MenuItem value={i?.value}>{i.label}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className={`${styles.InsideConCheck}`}>
                {(leaseData?.is_esign === 1 && leaseData?.status === 2 && leaseData?.no_of_sign > 0 && (leaseData?.no_of_sign >= leaseData?.no_of_tenanat)) && <button className={`${styles.SignLeaseBU}`} onClick={(e) => signLeaseNew()}>Sign Lease</button>}
                {(leaseData?.is_esign === 0 && leaseData?.status === 2 && leaseData?.no_of_sign > 0 && (leaseData?.no_of_sign >= leaseData?.no_of_tenanat)) && <button className={`${styles.SignLeaseBU}`} onClick={(e) => confirmLeaseNew()}>Confirm Lease</button>}

                {leaseData?.status === 1 && <Link className={`${styles.AddNoteBu}`} onClick={(e) => setShowRenewForm(true)}>
                  <img src="/static/img/reload.png" alt="Renew" /> <span>Renew</span>
                </Link>}
              </div>
            </div>

            {(leaseData?.LeaseFile) && <div className={`${styles.ConArea}`}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Box style={{ height: "100%", width: "100%" }}>
                  <Viewer
                    defaultScale={1}
                    fileUrl={leaseData?.LeaseFile}
                  />
                </Box>
              </Worker>
            </div>}

            {(!(leaseData?.LeaseFile)) && <div className={`${styles.ConArea}`}>
              {leaseData?.document_data?.map(item => {
                return <div className={`${styles.ContentBlock}`}><div dangerouslySetInnerHTML={{ __html: item?.description }} /></div>
              })}
            </div>}

          </div>
        </div>

      </div>}
    </div>

    <Dialog
      open={openDeleteModal}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setOpenDeleteModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to delete the Lease Record?<button onClick={(e) => setOpenDeleteModal(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The Lease Record will be removed immediately.You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => deleteConfirm()} className="CancelPopupBU">Delete</Button>
        <Button onClick={(e) => setOpenDeleteModal(false)} className="SubmitPopupBU">Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      fullWidth
      maxWidth="sm"
      open={showRenewForm}
      onClose={(e) => setShowRenewForm(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        {/* <button className={`${styles.BackAddBU}`} onClick={(e) => setShowRenewForm(false)}><ArrowLeft /></button> */}
        Renew Lease
        <button onClick={(e) => setShowRenewForm(false)}><X /></button>
      </DialogTitle>
      <DialogContent className={`${styles.RequestBodyPop}`}>
        <RenewLeaseForm setShowRenewForm={setShowRenewForm.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onSubmitLocal.bind(this)} renewData={leaseData?.renew_option} />
      </DialogContent>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </>)
}

export default withAuthenticationRequired(LeaseDetails, {
  onRedirecting: () => <Loader />,
});