import styles from '../../maintenancerequestdetails.module.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { X } from "react-feather";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from '../../../../components/AddLogs';
import { useForm } from 'react-hook-form';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const SummaryTab = ({ requestData, setRequestData, setSnackbarOpen, setSnackbarMsg, setLoading }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [requestImage, setRequestImage] = useState('/static/img/other.svg');
    const [oldData, setOldData] = useState({ description: '', resolution: '' });
    const [newData, setNewData] = useState({ description: '', resolution: '' });
    const [charsLeft, setCharsLeft] = useState({ description: 200, resolution: 200 });
    const [isUpdate, setIsUpdate] = useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(1);

    const { handleSubmit, register, setValue } = useForm();

    useEffect(() => {
        if (requestData) {
            if (requestData?.sub_category === 'Electrical Socket') {
                setRequestImage("/static/img/ElectricalSocket.svg");
            } else if (requestData?.sub_category === 'Light Switch/Dimmer') {
                setRequestImage("/static/img/Dimmer.svg");
            } else if (requestData?.sub_category === 'Leak') {
                setRequestImage("/static/img/Leak.svg");
            } else if (requestData?.sub_category === 'Drain clogged') {
                setRequestImage("/static/img/DrainClogged.svg");
            } else if (requestData?.sub_category === 'Water temperature Issue') {
                setRequestImage("/static/img/WaterTemperature.svg");
            } else if (requestData?.sub_category === 'Stove/Oven Issue') {
                setRequestImage("/static/img/StoveOven.svg");
            } else if (requestData?.sub_category === 'Refrigerator Issue') {
                setRequestImage("/static/img/Refrigerator.svg");
            } else if (requestData?.sub_category === 'Washer/Dryer Issue') {
                setRequestImage("/static/img/Washer.svg");
            } else if (requestData?.sub_category === 'Other Issue') {
                setRequestImage("/static/img/other.svg");
            }

            setOldData({ description: requestData?.description, resolution: requestData?.resolution ? requestData?.resolution : '' });
            setNewData({ description: requestData?.description, resolution: requestData?.resolution ? requestData?.resolution : '' });

            setValue('description', requestData?.description);
            setValue('resolution', requestData?.resolution);

            if (requestData?.description?.length) {
                setCharsLeft(prev => {
                    return { ...prev, description: 200 - requestData?.description?.length };
                })
            }
            if (requestData?.resolution?.length) {
                setCharsLeft(prev => {
                    return { ...prev, resolution: 200 - requestData?.resolution?.length };
                })
            }
        }
    }, [requestData, setValue]);

    useEffect(() => {
        if (oldData) {
            if (oldData?.description === newData?.description && oldData?.resolution === newData?.resolution) {
                setIsUpdate(false);
            } else {
                setIsUpdate(true);
            }
        }
    }, [newData, oldData])

    const deleteConfirm = async () => {
        setLoading(true);
        setOpenDeleteModal(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        return axios.delete(process.env.REACT_APP_CASE_API_URL + 'v1/maintenance-requests/' + requestData?.id + '/files/' + deleteId, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            setLoading(false);

            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setRequestData(prev => {
                let images = prev?.images?.filter(i => i?.id !== deleteId);
                return { ...prev, images: images };
            });

            let logData = {
                'title': 'Maintenance request image deleted',
                'description': [
                    'Name: ' + requestData?.title,
                    'Request number: ' + requestData?.request_no,
                    'Property: ' + requestData?.property_name,
                    'Apartment: ' + requestData?.apt_name,
                    'Tenant: ' + requestData?.tenant_name,
                ]
            }
            AddLogCallAPI(logData, token);
        }).catch(error => {
            if (typeof error.response !== 'undefined')
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const fileHandler = async (e) => {
        let extErr = false;
        let sizeErr = false;
        let files = e.target.files;
        let allowedExtensionArr = ".jpg,.png,.jpeg,.svg".split(',');

        if (files.length > 0) {
            if (allowedExtensionArr.length > 0) {
                let fileName = files[0].name;
                let fileSize = files[0].size;
                let fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
                fileNameExt = '.' + fileNameExt;

                if (allowedExtensionArr.indexOf(fileNameExt) === -1) {
                    extErr = true;
                }

                if (fileSize >= 5242880) {
                    sizeErr = true;
                }
            }
        }

        if (extErr) {
            toast.error("File type is not allowed.");
            return false;
        }

        if (sizeErr) {
            toast.error("File size should not be greater than 5 MB.");
            return false;
        }

        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        setLoading(true);

        let formData = new FormData();
        formData.append("image", files[0]);

        axios.post(process.env.REACT_APP_CASE_API_URL + 'v1/maintenance-requests/' + requestData?.id + '/files/upload', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            setLoading(false);

            setSnackbarOpen(true);
            setSnackbarMsg('Files uploaded successfully.');

            setRequestData(prev => {
                let images = response.data.data.concat(prev?.images);
                return { ...prev, images: images };
            });

            let logData = {
                'title': 'Maintenance request image uploaded',
                'description': [
                    'Name: ' + requestData?.title,
                    'Request number: ' + requestData?.request_no,
                    'Property: ' + requestData?.property_name,
                    'Apartment: ' + requestData?.apt_name,
                    'Tenant: ' + requestData?.tenant_name,
                ]
            }
            AddLogCallAPI(logData, token);

        }).catch(error => {
            setLoading(false);
        });

    }

    const onUpdate = async (data) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        return axios.put(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);

            setRequestData(prev => {
                return { ...prev, description: response.data.data?.description, resolution: response.data.data?.resolution };
            });

            let logData = {
                title: "Maintenance request is updated",
                description: [
                    "Name: " + response.data.data.title,
                    "Request number: " + response.data.data.request_no,
                    "Property: " + response.data.data.property_name,
                    "Apartment: " + response.data.data.apt_name,
                    "Tenant: " + response.data.data.tenant_name,
                ],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<>
        <button className={`${styles.SummeryTitle}`}>
            <img src={requestImage} alt="" />
            {requestData?.title}
        </button>
        <form onSubmit={handleSubmit(onUpdate)}>
            <div className={`${styles.SummFormFull}`}>
                <p className={`${styles.SummFormLabel}`}>Maintenance Issue Description</p>
                <textarea name="description" ref={register} className={`${styles.formControlTextArea}`} maxLength={200} onChange={(e) => {
                    setCharsLeft(prev => {
                        return { ...prev, description: 200 - e.target.value?.length };
                    });

                    setNewData(prev => {
                        return { ...prev, description: e.target.value };
                    });
                }}></textarea>
                <p className={`${styles.RemainingText}`}>{charsLeft?.description} {charsLeft?.description > 1 ? "characters" : "character"} remaining</p>
            </div>
            <div className={`${styles.SummFormFull}`}>
                <p className={`${styles.SummFormLabel}`}>Resolution</p>
                <textarea name="resolution" ref={register} className={`${styles.formControlTextArea}`} maxLength={200} onChange={(e) => {
                    setCharsLeft(prev => {
                        return { ...prev, resolution: 200 - e.target.value?.length };
                    });

                    setNewData(prev => {
                        return { ...prev, resolution: e.target.value };
                    });
                }}></textarea>
                <p className={`${styles.RemainingText}`}>{charsLeft?.resolution} {charsLeft?.resolution > 1 ? "characters" : "character"} remaining</p>
            </div>
            {isUpdate && <div>
                <button type='submit' className={`${styles.SummaryUpdateBU}`}>Update</button>
            </div>}
        </form>

        <div className={`${styles.BorderDivSumm}`}>
            <p className={`${styles.SummFormLabel}`}>Uploaded Photos</p>

            <div className={`${styles.Row}`}>
                <div className={`${styles.FileUpDuBUDiv}`}>
                    <label for="file-upload" className={`${styles.UploadLabel}`}>
                        <span>
                            <div className={`${styles.UpIcon}`}>
                                <img src="/static/img/uploadIMG.svg" alt="" />
                            </div>{" "}
                            Upload Photos
                        </span>
                        <p className={`${styles.UploadText}`}>
                            File size upto 5 MB <br /> JPEG, JPG, PNG file only
                        </p>
                    </label>
                    <input id="file-upload" type="file" onChange={fileHandler} accept={".jpg,.png,.jpeg,.svg"} />
                </div>
                {requestData?.images?.map((item, index) => {
                    return (<div className={`${styles.SummFormSemiHalf}`}>
                        <button onClick={(e) => { setOpenDeleteModal(true); setDeleteId(item?.id); }}><X /></button>
                        <img src={item?.file_public_url} alt="" onClick={(e) => { setLightboxOpen(true); setLightboxIndex(index); }} />
                    </div>)
                })}
            </div>
        </div>

        <Dialog
            open={openDeleteModal}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setOpenDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete the image?<button onClick={(e) => setOpenDeleteModal(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The image will be removed immediately.You can't undo this action.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="CancelPopupBU" onClick={(e) => deleteConfirm()}>Delete</Button>
                <Button className="SubmitPopupBU" onClick={(e) => setOpenDeleteModal(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>

        <Lightbox
            open={lightboxOpen}
            close={() => setLightboxOpen(false)}
            index={lightboxIndex}
            carousel={{ finite: true, preload: 2, padding: "16px", spacing: "30%", imageFit: "contain" }}
            slides={requestData?.images?.map(i => {
                return { src: i?.file_public_url };
            })}
        />
    </>);
}

export default SummaryTab;