import React, { useEffect, useState } from "react";
import { CardContent } from "@mui/material";
import { Line } from "react-chartjs-2";
import styles from "../dashboard.module.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoader } from "../../../../components/LoaderC";

const MonthlyRentRollData = ({ selectedProperty }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [noData, setNoData] = useState(true);
    const [contentLoading, setContentLoading] = useState(true);
    const [monthlyRentRoll, setMonthlyRentRoll] = useState(null);

    const data = (canvas) => {
        const ctx = canvas.getContext("2d");

        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(1, "rgba(255, 255, 255, .3)");
        gradient.addColorStop(0, "rgba(170, 102, 204, .3)");

        return {
            labels: monthlyRentRoll ? monthlyRentRoll?.labels : [],
            datasets: [
                {
                    label: "Rent",
                    fill: true,
                    backgroundColor: gradient,
                    borderColor: "#502688",
                    data: monthlyRentRoll ? monthlyRentRoll?.data : []
                },
            ],
        };
    };

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: "bottom",
            labels: {
                padding: 30,
            },
        },
        hover: {
            intersect: true,
        },
        plugins: {
            filler: {
                propagate: false,
            },
        },
        scales: {
            xAxes: [
                {
                    reverse: true,
                    gridLines: {
                        color: "rgba(0,0,0,0.0)",
                    },
                    ticks: {
                        fontColor: "rgba(0, 0, 0, 0.54)",
                    },
                },
            ],
            yAxes: [
                {
                    display: true,
                    borderDash: [5, 5],
                    gridLines: {
                        color: "rgba(0,0,0,0.0)",
                        fontColor: "#fff",
                    },
                    ticks: {
                        stepSize: 1500,
                        fontColor: "rgba(0, 0, 0, 0.54)",
                        userCallback: function (value, index, values) {
                            value = value.toString();
                            value = value.split(/(?=(?:...)*$)/);
                            value = value.join(',');
                            return '$' + value;
                        }
                    }
                },
            ]
        },
        tooltips: {
            intersect: false,
            callbacks: {
                label: function (t, d) {
                    var xLabel = d.datasets[t.datasetIndex].label;
                    var yLabel = t.yLabel >= 1000 ? '$' + t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '$' + t.yLabel;
                    return xLabel + ': ' + yLabel;
                }
            }
        }
    };

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-monthly-rent-data/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setMonthlyRentRoll(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-monthly-rent-data", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setMonthlyRentRoll(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                });
            }
        }


        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, getAccessTokenSilently]);

    useEffect(() => {
        if (monthlyRentRoll) {
            if (monthlyRentRoll?.data[0] === 0 && monthlyRentRoll?.data[1] === 0 && monthlyRentRoll?.data[2] === 0 && monthlyRentRoll?.data[3] === 0 && monthlyRentRoll?.data[4] === 0 && monthlyRentRoll?.data[5] === 0) {
                setNoData(true);
            } else {
                setNoData(false);
            }
        } else {
            setNoData(true);
        }
    }, [monthlyRentRoll]);

    return (<div className={`${styles.ChartFullDiv} ${styles.Full} LineChart`}>
        {contentLoading && (<div className={`${styles.LoaderDash}`}>
            <DashCardLoader />
        </div>)}
        {!contentLoading && (<div className={`${styles.IfnoCard}`}>
            <div className={`${styles.InsideCardTwo}`}>
                <div className={`${styles.BatteryHead}`}>
                    <h6 className={`${styles.BatteryCardTitle}`}>Monthly Rent Roll</h6>
                </div>
            </div>
            <CardContent style={{ display: noData ? 'none' : 'block' }}>
                <div style={{ height: '378px' }}>
                    <Line data={data} options={options} />
                </div>
            </CardContent>

            {noData && (<div className={`${styles.NoDataSec}`} style={{ height: '378px' }}>
                <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-rent.png" alt="" /></div>
                <p className={`${styles.NoDataText}`}>Move in ready!</p>
                <p className={`${styles.NoDataTextSub}`}>Rent Roll is Currently Empty.</p>
            </div>)}
        </div>)}
    </div>);
};

export default MonthlyRentRollData;
