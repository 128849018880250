import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, TablePagination } from "@mui/material";
import styles from "../smartdevicedetails.module.css";
import { Trash2 } from "react-feather";
import { useEffect, useState } from "react";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DeviceAlerts = ({ deviceDetails, setLoading, setSnackbarMsg, setSnackbarOpen, setDeviceDetails }) => {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const [page, setPage] = useState(0);
    const [alertList, setAlertList] = useState([]);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [selectedAlertLength, setSelectedAlertLength] = useState(0);
    const [perPage, setPerPage] = useState(6);
    const [taskExpanded, setTaskExpanded] = useState("");


    useEffect(() => {
        if (deviceDetails?.device_alerts?.length > 0) {
            setAlertList(deviceDetails?.device_alerts);
        } else {
            setAlertList([]);
        }

    }, [deviceDetails]);

    const checkboxAllCng = (e) => {
        let selectedAlertsTemp = [];
        if (e.target.checked) {
            selectedAlertsTemp = alertList?.filter((i) => i.is_archived === 0);
            selectedAlertsTemp = selectedAlertsTemp?.map((item) => {
                return parseInt(item?.id);
            });
        }

        setSelectedAlertLength(selectedAlertsTemp.length);
        setSelectedAlerts(selectedAlertsTemp);
    };

    const checkboxCng = (e) => {
        let selectedAlertsTemp = selectedAlerts;
        let chkValue = parseInt(e.target.value);
        if (e.target.checked) {
            if (selectedAlertsTemp.indexOf(chkValue) === -1)
                selectedAlertsTemp = selectedAlertsTemp.concat([chkValue]);
        } else {
            let indexOf = selectedAlertsTemp.indexOf(chkValue);
            if (indexOf > -1) selectedAlertsTemp.splice(indexOf, 1);
        }
        setSelectedAlertLength(selectedAlertsTemp.length);
        setSelectedAlerts(selectedAlertsTemp);
    }

    const deleteAlerts = async () => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        await axios.post(process.env.REACT_APP_COMMUNICATION_MAIN_API_URL + "v1/smart-devices/smart-devices-alerts-archive", { alertIds: selectedAlerts }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLoading(false);

            setSnackbarMsg(selectedAlertLength > 1 ? selectedAlertLength + " alerts archived successfully!" : selectedAlertLength + " alert archived successfully!"
            );
            setSnackbarOpen(true);

            setDeviceDetails(prev => {
                let device_alerts = prev?.device_alerts.filter((i) => selectedAlerts.indexOf(parseInt(i.id)) === -1);
                return { ...prev, device_alerts: device_alerts };
            })

            setSelectedAlerts([]);
            setSelectedAlertLength(0);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const handleChange3 = (panel) => (event, isTaskExpanded) => {
        setTaskExpanded(isTaskExpanded ? panel : false);
    };

    return (<div className={`${styles.AlertsRow}`}>
        {alertList?.length > 0 && <>
            {isAuthenticated && (user["https://propertydek.com/permissions"].indexOf("device:update") > -1) && <div className={`${styles.AlertMainCheckSec}`}>
                <FormControlLabel className="CustomCheck"
                    control={
                        <Checkbox
                            onChange={checkboxAllCng}
                            indeterminate={
                                selectedAlertLength > 0 &&
                                alertList?.length !== selectedAlertLength
                            }
                            checked={
                                alertList?.length > 0 &&
                                alertList?.length === selectedAlertLength
                            }
                        />
                    }
                    label="Select All Alerts"
                />
                {selectedAlertLength > 0 && (<button className={`${styles.AlertDeleteBU}`} onClick={(e) => deleteAlerts()}>
                    <Trash2 />Delete {selectedAlertLength > 1 ? selectedAlertLength + " Alerts" : selectedAlertLength + " Alert"}
                </button>)}
            </div>}

            <div className={`${styles.NotesRow}`}>
                <div className="FormAccording DeviceAlert Theme">
                    {/*alertList?.map((item, index) => {
                        return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<div className={`${styles.AlertsCard}`}>
                            <div className={`${styles.AlertsCardUnder}`}>
                                <div className={`${styles.AlertsCardHead}`}>
                                    {isAuthenticated && (user["https://propertydek.com/permissions"].indexOf("device:update") > -1) && <FormControlLabel className={`${styles.CustomCheck} CustomCheck`}
                                        control={
                                            <Checkbox
                                                checked={selectedAlerts?.indexOf(parseInt(item.id)) > -1}
                                                onChange={checkboxCng}
                                                value={item?.id}
                                            />
                                        }
                                    />}

                                    {item?.title === "Water Leak" && (<div className={`${styles.AlertsCardImg} ${styles.Offline}`}>
                                        <img src="/static/img/water-dash.svg" width="24px" height="24px" alt="" />
                                    </div>)}

                                    {item?.title === "Low Battery" && (<div className={`${styles.AlertsCardImg} ${styles.Offline}`}>
                                        <img src="/static/img/battery-dash.svg" width="24px" height="24px" alt="" />
                                    </div>)}

                                    {item?.title === "Device Offline" && (<div className={`${styles.AlertsCardImg} ${styles.Offline}`}>
                                        <img src="/static/img/wifi-dash.svg" width="24px" height="24px" alt="" />
                                    </div>)}

                                    {(item?.title?.toLowerCase().includes('door unlocked') || item?.title?.toLowerCase().includes('door is unlocked') || item?.title?.toLowerCase().includes('door was unlocked')) && (<div className={`${styles.AlertsCardImg} ${styles.Online}`}>
                                        <img src="/static/img/lock-dash.svg" width="24px" height="24px" alt="" />
                                    </div>)}

                                    <div className={`${styles.AlertsCardConSec}`}>
                                        <p className={`${styles.AlertsCardTitle}`}>{item?.title}</p>
                                        <p className={`${styles.AlertsCardTitleSm}`}>{deviceDetails?.product_type}/{deviceDetails?.location}</p>
                                    </div>
                                </div>
                                <div className={`${styles.AlertsCardBody}`}>
                                    <ul>
                                        <li>
                                            <p className={`${styles.Title}`}>Device Name:</p>
                                            <p className={`${styles.Content}`}>{deviceDetails?.name}</p>
                                        </li>
                                        <li>
                                            <p className={`${styles.Title}`}>Property:</p>
                                            <p className={`${styles.Content}`}>{deviceDetails?.property?.name}</p>
                                        </li>
                                        <li>
                                            <p className={`${styles.Title}`}>Unit:</p>
                                            <p className={`${styles.Content}`}>{deviceDetails?.apartment ? deviceDetails?.apartment?.apt_no : deviceDetails?.apt_text}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`${styles.AlertsCardFoot}`}>
                                    <p className={`${styles.CardDate}`}>{moment.utc(item?.created_at).fromNow()}</p>
                                </div>
                            </div>
                        </div>) : null;
                    })*/}

                    {alertList?.map((item, index) => {
                        return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Accordion expanded={taskExpanded === "panel" + index} onChange={handleChange3("panel" + index)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                                <div className={`${styles.NotesCard}`}>
                                    <div className={`${styles.AlertCardFirstSec}`}>
                                        {isAuthenticated && (user["https://propertydek.com/permissions"].indexOf("device:update") > -1) && <FormControlLabel
                                            className="CustomCheck"
                                            control={
                                                <Checkbox
                                                    checked={selectedAlerts?.indexOf(parseInt(item.id)) > -1}
                                                    onChange={checkboxCng}
                                                    value={item?.id}
                                                />
                                            }
                                        />}
                                        {item?.title === "Water Leak" && (<div className={`${styles.AlertsCardImg} ${styles.Waterleak}`}>
                                            <img src="/static/img/water-dash.svg" width="24px" height="24px" alt="" />
                                        </div>)}

                                        {item?.title === "Low Battery" && (<div className={`${styles.AlertsCardImg} ${styles.Lowbatter}`}>
                                            <img src="/static/img/battery-dash.svg" width="24px" height="24px" alt="" />
                                        </div>)}

                                        {item?.title === "Device Offline" && (<div className={`${styles.AlertsCardImg} ${styles.Offline}`}>
                                            <img src="/static/img/wifi-dash.svg" width="24px" height="24px" alt="" />
                                        </div>)}

                                        {item?.title === "Device Online" && (<div className={`${styles.AlertsCardImg} ${styles.Online}`}>
                                            <img src="/static/img/online.svg" width="24px" height="24px" alt="" />
                                        </div>)}

                                        {(item?.title?.toLowerCase().includes('door unlocked') || item?.title?.toLowerCase().includes('door is unlocked') || item?.title?.toLowerCase().includes('door was unlocked')) && (<div className={`${styles.AlertsCardImg} ${styles.Online}`}>
                                            <img src="/static/img/lock-dash.svg" width="24px" height="24px" alt="" />
                                        </div>)}

                                        <div className={`${styles.AccoTitleHead}`}>
                                            <p className={`${styles.AlertTitle}`}>{item?.title}</p>
                                            <p className={`${styles.AlertText}`}>{moment.utc(item?.created_at).fromNow()}</p>
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className={`${styles.CussAccoRow}`}>
                                <div className={`${styles.AccoMainSec}`}>
                                    <ul>
                                        <li>
                                            <p className={`${styles.AlertText}`}>
                                                <span>Device Location:</span>
                                                {deviceDetails?.product_type}/{deviceDetails?.location}
                                            </p>
                                        </li>
                                        <li>
                                            <p className={`${styles.AlertText}`}>
                                                <span>Device Name:</span>
                                                {deviceDetails?.name}
                                            </p>
                                        </li>
                                        <li>
                                            <p className={`${styles.AlertText}`}>
                                                <span>Property:</span>
                                                {deviceDetails?.property?.name}
                                            </p>
                                        </li>
                                        <li>
                                            <p className={`${styles.AlertText}`}>
                                                <span>Unit:</span>
                                                {deviceDetails?.apartment ? deviceDetails?.apartment?.apt_no : deviceDetails?.apt_text}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </AccordionDetails>
                        </Accordion>) : null;
                    })}
                </div>
            </div>

            {alertList.length > 0 && (<div className="Pagination TablePaginationNew">
                <TablePagination
                    count={alertList.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                        { label: '6', value: 6 },
                        { label: '12', value: 12 },
                        { label: '24', value: 24 },
                        { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                        setPage(0);
                        setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Alerts per Page:'}
                />
            </div>)}
        </>}

        {alertList?.length === 0 && (<div className={`${styles.NoDataMain}`}>
            <div className={`${styles.NoDataIMG}`}>
                <img src="/static/img/no-device.png" alt="No Data" />
            </div>
            <p className={`${styles.NoDataText}`}>Don't Worry!</p>
            <p className={`${styles.NoDataTextSub}`}>Device Alerts is empty.</p>
        </div>)}

    </div>);
}

export default DeviceAlerts;