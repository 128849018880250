import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import styles from "./smartdevicedetails.module.css";
import Footer from "../../components/Footer";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Snackbar, Tooltip, Typography } from "@mui/material";
import { ArrowLeft, Edit2, Home, MoreVertical, Trash2, X } from "react-feather";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { TableLoader } from "../../components/LoaderC";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { AddLogCallAPI } from "../../components/AddLogs";
import EditDeviceForm from "./components/EditDeviceForm";
import PubNubChannel from "./components/pubnub/pubnub-channel";
import SimpleTabs from "./componentnew/Tabs";
import Dimmercontroller from "./componentnew/dimmer/controller";
import SmartDeviceDetailsService from "./smartdevicedetails.service";
import Thermostatcontroller from "./componentnew/thermostat/controller";
import DoorLockcontroller from "./componentnew/doorlock/controller";
import TTDoorLockcontroller from "./componentnew/doorlock/ttlock-controller";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TTDoorLockcontrollerPin from "./componentnew/doorlock/ttlock-controller-pin";
import TTDoorLockcontrollerFingerprint from "./componentnew/doorlock/ttlock-controller-fingerprint";

const SmartDeviceDetails = ({ history, match }) => {
  const { id } = match.params;
  const timezone_offset_minutesdata = new Date().getTimezoneOffset() === 0 ? 0 : -new Date().getTimezoneOffset();
  const redirect = useHistory();
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [smartDeviceData, setSmartDeviceData] = useState(null);
  const [ttLockDeviceData, setTTLockDeviceData] = useState(null);
  const [expanded, setExpanded] = useState("panel1");


  useEffect(() => {
    const getDeviceDetails = async (productId) => {
      try {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        const headers = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await SmartDeviceDetailsService.getDeviceDetails(
          productId,
          headers
        );

        setSmartDeviceData(response.data.data.result);
      } catch (error) {
        console.log(error);
      }
    };

    const getTTLockDetails = async (deviceId) => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/ttlock/devices/" + deviceId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        console.log(response.data.data);
        setTTLockDeviceData(response.data.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });

      await axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/ttlock/get-gateway-details/" + deviceId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        let gatewayData = response.data.data;
        if (gatewayData) {
          setDeviceDetails(prev => {
            return { ...prev, online: gatewayData?.isOnline };
          })
        }
      });
    }

    const fetchDetails = async () => {
      setContentLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/devices/" + id + "?timezone_offset=" + timezone_offset_minutesdata, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setContentLoading(false);

        let devDet = response.data.data;
        if (devDet?.image === '') {
          let image1 = '/static/img/router.svg';
          if (devDet?.product_type === "Propertydek-Door Lock") {
            image1 = "/static/img/Door.svg"
          }
          if (devDet?.product_type === "Propertydek-Light") {
            image1 = "/static/img/bulb.svg"
          }
          if (devDet?.product_type === "Propertydek-LED Light") {
            image1 = "/static/img/LED-light.svg"
          }
          if (devDet?.product_type === "Propertydek-Thermostat") {
            image1 = "/static/img/temperature.svg"
          }
          if (devDet?.product_type === "Propertydek-Device Gateway") {
            image1 = "/static/img/router.svg"
          }
          if (devDet?.product_type === "Propertydek-Water Meter") {
            image1 = "/static/img/watermeter.svg"
          }
          if (devDet?.product_type === "Propertydek-Lock Gateway") {
            image1 = "/static/img/router.svg"
          }

          devDet = { ...devDet, image: image1 };
        }

        setDeviceDetails(devDet);

        if (devDet?.device_type === "TTLock") {
          getTTLockDetails(devDet?.id);
        } else {
          getDeviceDetails(devDet?.deviceID);
        }

      }).catch((error) => {
        setContentLoading(false);
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    };

    fetchDetails();
  }, [getAccessTokenSilently, id, timezone_offset_minutesdata]);

  const deleteConfirm = async () => {
    setLoading(true);
    setDeleteModalOpen(false);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    axios.delete(process.env.REACT_APP_DEVICE_API_URL + "v1/devices/" + deviceDetails?.id, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let respData = response.data.data;

      let logData = {
        title: "Smart device is deleted",
        description: [
          "Device name: " + respData.name,
          "Device id: " + respData.deviceID,
          "Product type: " + respData.product_type,
          "Location: " + respData.location,
        ],
      };
      AddLogCallAPI(logData, token);
      setTimeout(() => {
        redirect.push("/smart-devices/list");
      }, 2000);
    }).catch((error) => {
      setLoading(false);
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
    });
  }

  const updateDeviceStatus = (deviceId, deviceStatus, bizCode, data) => {
    try {
      let deviceData
      if (smartDeviceData) {
        deviceData = smartDeviceData;

        if (bizCode) {
          const isOnline = deviceStatus === "online" ? true : false;
          deviceData.online = isOnline;
        } else {
          const deviceIndex = deviceData?.status.findIndex((x) => x.code === deviceStatus.code);
          if (deviceIndex >= 0) {
            if (deviceDetails?.product_type === "Propertydek-LED Light") {
              deviceData.status[deviceIndex].value = JSON.parse(deviceStatus.value);
            } else {
              deviceData.status[deviceIndex].value = deviceStatus.value;
            }
          }
        }
      } else {
        deviceData = ttLockDeviceData;
      }

      if (deviceDetails?.deviceID !== deviceId) {
        return;
      }

      if (data) {
        setDeviceDetails(prev => {
          let alerts = [data].concat(prev?.device_alerts);
          return { ...prev, device_alerts: alerts };
        })
      }

      if (smartDeviceData) {
        setSmartDeviceData({ ...deviceData });
      } else {
        setTTLockDeviceData({ ...deviceData });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (<React.Fragment>
    {loading && <Loader />}

    <Helmet title={deviceDetails?.name} />

    {deviceDetails?.product_type && (<PubNubChannel ownerId={deviceDetails?.ownerId} aptId={deviceDetails?.apt_id} updateDeviceStatus={updateDeviceStatus.bind(this)}></PubNubChannel>)}




    <div className={`${styles.mainCard}`}>
      {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}
      {!contentLoading && <div className={`${styles.SMProCusRow}`}>
        <div className={`${styles.BreadcrumbHeadSec}`}>
          <nav aria-label="breadcrumb">
            <ol className={`${styles.breadcrumb}`}>
              <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Smart Devices</li>
              <li className={`${styles.breadcrumbItem} ${styles.active}`}>{deviceDetails?.name}</li>
            </ol>
          </nav>
          <div className={`${styles.PageTitleSec}`}>
            <div>
              <button onClick={() => redirect.goBack()} className={`${styles.BackArrow}`}><ArrowLeft /></button>
              <Typography display="inline" className={`${styles.pageTitle}`}>{deviceDetails?.name}</Typography>
            </div>
          </div>
        </div>

        <div className={`${styles.SMProCusSmallDiv}`}>
          <div className={`${styles.CardSmall}`}>
            <div className={`${styles.SMProCardHead}`}>
              <div className={`${styles.SMProPicMain}`}>
                <Tooltip title="Dimmer" placement="bottom">
                  <div className={`${styles.avatar} ${styles.active}`}>
                    <img src={deviceDetails?.image} alt="" />
                  </div>
                </Tooltip>
              </div>
              <h5>{deviceDetails?.name}</h5>
              {isAuthenticated && (user["https://propertydek.com/permissions"].indexOf("device:update") > -1) && <Dropdown className={`${styles.ProEditSec}`}>
                <Dropdown.Toggle className={`${styles.EditActionsBU} ProsDrBU`} id="dropdown-basic">
                  <MoreVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                  <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setEditModalOpen(true)}>
                    <Edit2 /> Edit
                  </Dropdown.Item>
                  <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => setDeleteModalOpen(true)}>
                    <Trash2 /> Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>}
            </div>

            <div className="HistoryAccording Theme">
              <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <div className={`${styles.FormAccoHead}`}>
                    <p className={`${styles.FormAccoHeadTitle}`}>Device details</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`${styles.SMProCardDe}`}>
                    <ul>
                      <li>
                        <span className={`${styles.SMProDETag}`}>Property:</span>
                        <span className={`${styles.SMProDE}`}>{deviceDetails?.property.name}</span>
                      </li>
                      <li>
                        <span className={`${styles.SMProDETag}`}>Apartment No:</span>
                        <span className={`${styles.SMProDE}`}>{deviceDetails?.apartment ? deviceDetails?.apartment?.apt_no : deviceDetails?.apt_text}</span>
                      </li>
                      <li>
                        <span className={`${styles.SMProDETag}`}>Location:</span>
                        <span className={`${styles.SMProDE}`}>{deviceDetails?.location}</span>
                      </li>
                      <li>
                        <span className={`${styles.SMProDETag}`}>Real Name:</span>
                        <span className={`${styles.SMProDE}`}>{deviceDetails?.real_name !== '' ? deviceDetails?.real_name : '-'}</span>
                      </li>
                      <li>
                        <span className={`${styles.SMProDETag}`}>Product Type:</span>
                        <span className={`${styles.SMProDE}`}>{deviceDetails?.product_type}</span>
                      </li>
                      <li>
                        <span className={`${styles.SMProDETag}`}>Product ID:</span>
                        <span className={`${styles.SMProDE}`}>{deviceDetails?.deviceID}</span>
                      </li>
                      {(deviceDetails?.product_type === "Propertydek-Door Lock") && <li>
                        <span className={`${styles.SMProDETag}`}>Gateway name:</span>
                        <span className={`${styles.SMProDE}`}>{deviceDetails?.gateway_name !== '' ? deviceDetails?.gateway_name : ' - '}</span>
                      </li>}
                      {(deviceDetails?.product_type === "Propertydek-Lock Gateway") && <li>
                        <span className={`${styles.SMProDETag}`}>Status:</span>
                        <span className={`${styles.SMProDE}`}>{deviceDetails?.online === 1 ? 'Online' : 'Offline'}</span>
                      </li>}
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
              {((deviceDetails?.product_type !== "Propertydek-Lock Gateway") && !(deviceDetails?.product_type === "Propertydek-Door Lock" && deviceDetails?.device_type === "TTLock")) && <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                  <div className={`${styles.FormAccoHead}`}>
                    <p className={`${styles.FormAccoHeadTitle}`}>Control Panel</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {deviceDetails?.product_type === "Propertydek-Light" && smartDeviceData?.status?.length && (<Dimmercontroller smartDeviceData={smartDeviceData}></Dimmercontroller>)}

                  {deviceDetails?.product_type === "Propertydek-Thermostat" && smartDeviceData?.status?.length && (<Thermostatcontroller smartDeviceData={smartDeviceData}></Thermostatcontroller>)}

                  {(deviceDetails?.product_type === "Propertydek-Door Lock" && deviceDetails?.device_type === "Tuya" && smartDeviceData?.status?.length) && (<DoorLockcontroller smartDeviceData={smartDeviceData} deviceDetails={deviceDetails} setLoading={setLoading.bind(this)}></DoorLockcontroller>)}
                </AccordionDetails>
              </Accordion>}

              {(deviceDetails?.product_type === "Propertydek-Door Lock" && deviceDetails?.device_type === "TTLock") && <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                  <div className={`${styles.FormAccoHead}`}>
                    <p className={`${styles.FormAccoHeadTitle}`}>Control Panel</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {ttLockDeviceData && (<TTDoorLockcontroller ttLockDeviceData={ttLockDeviceData} deviceDetails={deviceDetails} setLoading={setLoading.bind(this)}></TTDoorLockcontroller>)}
                </AccordionDetails>
              </Accordion>}

              {(deviceDetails?.product_type === "Propertydek-Door Lock" && deviceDetails?.device_type === "TTLock") && <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                  <div className={`${styles.FormAccoHead}`}>
                    <p className={`${styles.FormAccoHeadTitle}`}>Pin Codes</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {ttLockDeviceData && (<TTDoorLockcontrollerPin ttLockDeviceData={ttLockDeviceData} deviceDetails={deviceDetails} setLoading={setLoading.bind(this)}></TTDoorLockcontrollerPin>)}
                </AccordionDetails>
              </Accordion>}

              {(deviceDetails?.product_type === "Propertydek-Door Lock" && deviceDetails?.device_type === "TTLock") && <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                  <div className={`${styles.FormAccoHead}`}>
                    <p className={`${styles.FormAccoHeadTitle}`}>Fingerprints</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {ttLockDeviceData && (<TTDoorLockcontrollerFingerprint ttLockDeviceData={ttLockDeviceData} deviceDetails={deviceDetails} setLoading={setLoading.bind(this)}></TTDoorLockcontrollerFingerprint>)}
                </AccordionDetails>
              </Accordion>}
            </div>

            {/* <div className={`${styles.SMProCardDe}`}>
              <ul>
                <li>
                  <span className={`${styles.SMProDETag}`}>Property:</span>
                  <span className={`${styles.SMProDE}`}>{deviceDetails?.property.name}</span>
                </li>
                <li>
                  <span className={`${styles.SMProDETag}`}>Apartment No:</span>
                  <span className={`${styles.SMProDE}`}>{deviceDetails?.apartment ? deviceDetails?.apartment?.apt_no : deviceDetails?.apt_text}</span>
                </li>
                <li>
                  <span className={`${styles.SMProDETag}`}>Location:</span>
                  <span className={`${styles.SMProDE}`}>{deviceDetails?.location}</span>
                </li>
                <li>
                  <span className={`${styles.SMProDETag}`}>Product Type:</span>
                  <span className={`${styles.SMProDE}`}>{deviceDetails?.product_type}</span>
                </li>
                <li>
                  <span className={`${styles.SMProDETag}`}>Product ID:</span>
                  <span className={`${styles.SMProDE}`}>{deviceDetails?.deviceID}</span>
                </li>
                {(deviceDetails?.product_type === "Propertydek-Door Lock") && <li>
                  <span className={`${styles.SMProDETag}`}>Gateway name:</span>
                  <span className={`${styles.SMProDE}`}>{deviceDetails?.gateway_name !== '' ? deviceDetails?.gateway_name : ' - '}</span>
                </li>}
                {(deviceDetails?.product_type === "Propertydek-Lock Gateway") && <li>
                  <span className={`${styles.SMProDETag}`}>Status:</span>
                  <span className={`${styles.SMProDE}`}>{deviceDetails?.status === 1 ? 'Online' : 'Offline'}</span>
                </li>}
              </ul>
            </div> */}

            {/* {deviceDetails?.product_type === "Propertydek-Light" && smartDeviceData?.status?.length && (<Dimmercontroller smartDeviceData={smartDeviceData}></Dimmercontroller>)}

            {deviceDetails?.product_type === "Propertydek-Thermostat" && smartDeviceData?.status?.length && (<Thermostatcontroller smartDeviceData={smartDeviceData}></Thermostatcontroller>)}

            {(deviceDetails?.product_type === "Propertydek-Door Lock" && deviceDetails?.device_type === "Tuya" && smartDeviceData?.status?.length) && (<DoorLockcontroller smartDeviceData={smartDeviceData} deviceDetails={deviceDetails} setLoading={setLoading.bind(this)}></DoorLockcontroller>)}

            {(deviceDetails?.product_type === "Propertydek-Door Lock" && deviceDetails?.device_type === "TTLock" && ttLockDeviceData) && (<TTDoorLockcontroller ttLockDeviceData={ttLockDeviceData} deviceDetails={deviceDetails} setLoading={setLoading.bind(this)}></TTDoorLockcontroller>)} */}
          </div>
        </div>

        <div className={`${styles.SMProCusBigDiv}`}>
          <div className={`${styles.card}`}>
            <div className={`${styles.cardbody}`}>
              <div className={`${styles.SMBigSideBody} TabMainClientProfile ClientDetails`}>
                <SimpleTabs deviceDetails={deviceDetails} setLoading={setLoading.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setDeviceDetails={setDeviceDetails.bind(this)} />
              </div>
            </div>
          </div>
        </div>

      </div>}
    </div>

    <Dialog
      open={deleteModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setDeleteModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to delete the Device?<button onClick={(e) => setDeleteModalOpen(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The Device will be removed immediately.You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => deleteConfirm()}>Delete</Button>
        <Button onClick={(e) => setDeleteModalOpen(false)} className="SubmitPopupBU">Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={editModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setEditModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setEditModalOpen(false)}><ArrowLeft /></button>
        <span>Edit Device</span>
        <button onClick={(e) => setEditModalOpen(false)}>
          <X />
        </button>
      </DialogTitle>
      <DialogContent>
        <EditDeviceForm deviceDetails={deviceDetails} setLoading={setLoading.bind(this)} setEditModalOpen={setEditModalOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setDeviceDetails={setDeviceDetails.bind(this)} />
      </DialogContent>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </React.Fragment >);
}


export default withAuthenticationRequired(SmartDeviceDetails, {
  onRedirecting: () => <Loader />,
});