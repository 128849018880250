import { Plus, Trash2 } from "react-feather";
import styles from "../tenantlist.module.css";
import moment from "moment";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-smart-toaster";
import axios from "axios";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import NumberFormat from "react-number-format";
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Button } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ListManager } from "react-beautiful-dnd-grid";
import { FromLoader } from "../../../components/LoaderC";
import Loader from "../../../components/Loader";

function sortList(list) {
  let listTemp = list
    .slice()
    .sort((first, second) => first.order - second.order);
  listTemp = listTemp.map((i, index) => {
    return { ...i, index: index };
  });
  return listTemp;
}

function ListElement({ item: { id, title, index } }) {
  return (
    <Chip className={"DropListNew"} key={id} label={index + 1 + ". " + title} />
  );
}

function MoneyField(props) {
  return (
    <NumberFormat
      customInput={TextField}
      prefix=""
      displayType="input"
      type="text"
      thousandSeparator={true}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      {...props}
    />
  );
}

function PhoneField(props) {
  return (
    <NumberFormat
      prefix=""
      displayType="input"
      type="tel"
      format={"(###) ###-####"}
      {...props}
    />
  );
}

const phoneRegExp = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;

const tenantSchema = {
  first_name: yup.string().required("This field is Required").max(50, "First name cannot be more than 50 characters"),
  last_name: yup.string().required("This field is Required")
    .max(50, "Last name cannot be more than 50 characters"),
  email: yup.string().required("This field is Required").email("Invalid E-mail").max(50, "Email cannot be more than 50 characters"),
  phone: yup.string().required("This field is Required").matches(phoneRegExp, "Invalid phone number"),
};

const AddForm = ({ closeAddForm, addTenant }) => {
  const { getAccessTokenSilently } = useAuth0();

  const schema = yup.object().shape({
    property_id: yup.mixed().required("This field is Required"),
    apt_id: yup.mixed().required("This field is Required"),
    security_amount: yup.string().required("This field is Required").test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0
    ).test("max-4-digit", "Security Amount can not be more than 6 digits.", (value) => {
      if (typeof (value) !== 'undefined')
        return parseInt(value.replace(/,/g, "")) < 10000;
    }),
    rent: yup.string().required("This field is Required").test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0).test("max-4-digit", "Rent can not be more than 6 digits.", (value) => {
      if (typeof (value) !== 'undefined')
        return parseInt(value.replace(/,/g, "")) < 10000;
    }),
    tenants: yup.array().of(yup.object().shape(tenantSchema)),
    start_date: yup.date().required("This field is Required"),
    end_date: yup.date().required("This field is Required"),
    lease_term: yup.mixed().required("This field is Required"),
    lease_type: yup.mixed().required("This field is Required"),
    is_existing: yup.mixed().required("This field is Required"),
    docume_ids: yup.array().when("is_existing", (is_existing) => {
      if (is_existing && is_existing === "No")
        return yup.array().required("This field is Required").nullable();
      else return yup.array();
    }),
    document_files: yup.array().when("is_existing", (is_existing) => {
      if (is_existing && is_existing === "Yes")
        return yup.mixed().test("required", "Please upload documents.", (file) => {
          if (uploadFiles.length !== 0) return true;
          return false;
        });
      else return yup.array();
    }),
    new_security_amount: yup.string().when("lease_type", (lease_type) => {
      if (lease_type && lease_type === "Renewal")
        return yup.string().required("This field is Required").test("non-zero-validate", "This field should be $1 or greater.", (value) => parseInt(value) > 0).test("max-4-digit", "New Security Amount can not be more than 6 digits.", (value) => {
          if (typeof (value) !== 'undefined')
            return parseInt(value.replace(/,/g, "")) < 10000;
        });
      else return yup.string();
    }),
    payment_received: yup.string().when("lease_type", (lease_type) => {
      if (lease_type && lease_type === "Renewal")
        return yup.string().required("This field is Required");
      else return yup.string();
    }),
    payment_date: yup.string().when("payment_received", (payment_received) => {
      if (payment_received && payment_received === "Yes")
        return yup.string().required("This field is Required");
      else return yup.string();
    }),
  });

  const [loading, setLoading] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [apartmentList, setApartmentList] = React.useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isPaymentReceived, setIsPaymentReceived] = useState(false);
  const [openUploadArea, setOpenUploadArea] = useState(false);
  const [openSelectArea, setOpenSelectArea] = useState(false);
  const [LeaseDocumentList, setLeaseDocumentList] = useState([]);
  const [LeaseDocumentOptions, setLeaseDocumentOptions] = useState([]);
  const [selectDocument, setSelectDocument] = React.useState([]);
  const [selectDocumentIds, setSelectDocumentIds] = React.useState([]);
  const [selectLeaseType, setSelectLeaseType] = React.useState("New");
  const [selectedProperty, setSelectedProperty] = React.useState(0);
  const [selectedApartment, setSelectedApartment] = React.useState(0);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [propertyList, setPropertyList] = React.useState([]);

  const [sortedLeaseDocuments, setSortedLeaseDocuments] = React.useState(
    sortList([])
  );

  const { register, handleSubmit, control, errors, setValue, getValues, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      tenants: [{ first_name: "", last_name: "", email: "", phone: "" }],
      lease_term: "",
      lease_type: ""
    },
  });

  const { fields, append, remove } = useFieldArray({ control, name: "tenants", rules: { maxLength: 3 } });

  useEffect(() => {
    async function fetchPropertyList() {
      setFormLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_TENANT_API_URL + "v1/property-apt-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setFormLoading(false);
        setPropertyList(response.data.data);
      }).catch((error) => {
        setFormLoading(false);
      });
    }
    fetchPropertyList();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    async function fetchData() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios.get(process.env.REACT_APP_PAYMENT_API_URL + "v1/document-list/add-lease", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setLeaseDocumentList(response.data.data);
      }).catch((error) => {
        setLeaseDocumentList([]);
      });
    }
    fetchData();
  }, [getAccessTokenSilently]);

  const onSubmit = async (data) => {
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    let docume_ids = "";
    if (data.is_existing === "No") {
      let dIds = sortedLeaseDocuments?.map((i) => {
        return i.id;
      });
      docume_ids = dIds.join();
    }

    let newSecurityAmount = 0;

    if (data.new_security_amount) {
      newSecurityAmount =
        data.new_security_amount.replace(/,/g, "") -
        data.security_amount.replace(/,/g, "");
      if (newSecurityAmount < 0) {
        newSecurityAmount = 0;
      }
    }

    const formData = new FormData();

    Object.keys(data).map((key) => {
      if (key === "lease_term") {
        formData.append("lease_term", data?.lease_term);
      } else if (key === "rent") {
        formData.append("rent", data.rent.replace(/,/g, ""));
      } else if (key === "security_amount") {
        formData.append("security_amount", data.security_amount.replace(/,/g, ""));
      } else if (key === "new_security_amount") {
        formData.append("new_security_amount", newSecurityAmount);
      } else if (key === "start_date") {
        formData.append("start_date", moment(data.start_date).format("YYYY-MM-DD"));
      } else if (key === "end_date") {
        formData.append("end_date", moment(data.end_date).format("YYYY-MM-DD"));
      } else if (key === "end_date") {
        formData.append("end_date", moment(data.end_date).format("YYYY-MM-DD"));
      } else if (key === "property_id") {
        formData.append("property_id", data?.property_id);
      } else if (key === "apt_id") {
        formData.append("apt_id", data?.apt_id);
      } else if (key === "tenants") {
        formData.append("tenants", JSON.stringify(data.tenants));
      }
      return false;
    });

    formData.append("is_esign", data.is_existing === "Yes" ? 0 : 1);
    formData.append("is_renew", data.lease_type === "Renewal" ? 1 : 0);

    if (data?.is_existing === "Yes") {
      formData.append("uploadFile", uploadFiles[0]);
    } else {
      formData.append("docume_ids", docume_ids);
    }

    if (data?.lease_type === "Renewal") {
      formData.append("is_payment", data?.payment_received === "Yes" ? 1 : 0);
      if (data?.payment_received === "Yes") {
        formData.append("payment_date", moment(data.payment_date).format("YYYY-MM-DD"));
      }
    }

    axios.post(process.env.REACT_APP_TENANT_API_URL + "v1/tenants", formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setLoading(false);

      if (response.data?.status) {
        addTenant(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const fileHandler = (e) => {
    var validExtensions = ["pdf"]; //array of valid extensions
    var fileName = e.target.files[0].name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
      toast.error("File type is not allowed.");
    } else if (e.target.files[0].size > 10485760) {
      toast.error("Image size should not be greater than 10 mb.");
    } else if (uploadFiles.length >= 5) {
      toast.error("You cann't upload more than 5 files.");
    } else {
      setUploadFiles((prevFiles) => {
        return [e.target.files[0]];
      });
    }
  };

  const removeFile = (index) => {
    setUploadFiles([]);
  };

  const selectProperty = (pId) => {
    const propObjArr = propertyList?.filter((i) => i.value === pId);
    if (propObjArr.length) {
      const propObj = propObjArr[0];

      if (propObj?.apartments) {
        setApartmentList(propObj?.apartments);
        setValue("apt_id", null);
        setValue("security_amount", "");
        setValue("rent", "");
      }

      let documents = LeaseDocumentList.filter((i) => i.property_id === pId);

      setLeaseDocumentOptions(documents);
    }
  };

  const selectApartment = (aId) => {
    const aptObjArr = apartmentList?.filter((i) => i.value === aId);
    if (aptObjArr.length) {
      const aptObj = aptObjArr[0];

      setValue("security_amount", aptObj?.rent);
      setValue("rent", aptObj?.rent);

      clearErrors(`security_amount`);
      clearErrors(`rent`);
    }
  };

  useEffect(() => {
    let ids = selectDocument.map((i) => {
      return i.id;
    });

    let docs = selectDocument.map((i, index) => {
      return { ...i, order: index, index: index };
    });

    setSelectDocumentIds(ids);

    setSortedLeaseDocuments(docs);
  }, [selectDocument]);

  const selectDocuments = (event) => {
    const {
      target: { value },
    } = event;
    setSelectDocument(value);
    setValue("docume_ids", value);
    clearErrors(`docume_ids`);
  };

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = sortedLeaseDocuments;

    if (destinationIndex === 0) {
      list[sourceIndex].order = list[0].order - 1;

      setSortedLeaseDocuments(sortList(list));
      return;
    }

    if (destinationIndex === list.length - 1) {
      list[sourceIndex].order = list[list.length - 1].order + 1;
      setSortedLeaseDocuments(sortList(list));
      return;
    }

    if (destinationIndex < sourceIndex) {
      list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
      setSortedLeaseDocuments(sortList(list));
      return;
    }

    list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
    setSortedLeaseDocuments(sortList(list));
  };

  return (<>
    {loading && <Loader />}
    {formLoading && (<div className="Loader FromLoader"><FromLoader /></div>)}
    {!formLoading && (<form onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`${styles.AddTenantRow}`}>
          <div className={`${styles.AddTenantHalfSec}`}>
            <div className={`${styles.AddTenantHalfSecRow}`}>
              <div className={`${styles.HalfSecHalf} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Property*</InputLabel>
                  <Controller
                    name="property_id"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Property*"
                        onChange={(e) => {
                          field.onChange(e);
                          selectProperty(e.target.value);
                          setSelectedProperty(e.target.value);
                        }}
                      >
                        {propertyList?.map((item) => {
                          return (<MenuItem value={item.value} disabled={(item?.apartments.length === 0) ? true : false}>{item.label} {(item?.apartments.length === 0) && <span className={`${styles.MenuItemSub}`}>(No vacancies)</span>}</MenuItem>);
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.property_id && (<p className={`${styles.ErrorM}`}>{errors.property_id.message}</p>)}
              </div>
              <div className={`${styles.HalfSecHalf} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel className={selectedProperty ? `` : `DisabledLabel`}>Apartment*</InputLabel>
                  <Controller
                    name="apt_id"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Apartment*"
                        onChange={(e) => {
                          field.onChange(e);
                          selectApartment(e.target.value);
                          setSelectedApartment(e.target.value);

                          setValue("lease_term", "1 Year");
                          setValue("lease_type", "New");

                        }}
                        disabled={!selectedProperty}
                      >
                        {apartmentList?.map((item) => {
                          return (<MenuItem value={item.value}>{item.label}</MenuItem>);
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.apt_id && (<p className={`${styles.ErrorM}`}>{errors.apt_id.message}</p>)}
              </div>
            </div>
            {fields.map((item, index) => {
              return (<div className={`${styles.AddTenantHalfSecRow}`} key={index}>
                <div className={`${styles.SecFull}`}>
                  <p className={`${styles.AddTenantFormLabel}`}>Tenant # {index + 1}</p>
                </div>
                <div className={`${styles.HalfSecHalf} FormGroup`}>
                  <Controller
                    name={`tenants.${index}.first_name`}
                    control={control}
                    render={(field) => (
                      <TextField
                        onChange={(e) => field.onChange(e)}
                        id="outlined-basic"
                        label="First name*"
                        value={field.value}
                      />
                    )}
                  />
                  {errors.tenants && errors?.tenants[index]?.first_name && (<p className={`${styles.ErrorM}`}>{errors?.tenants[index]?.first_name?.message}</p>)}
                </div>
                <div className={`${styles.HalfSecHalf} FormGroup`}>
                  <Controller
                    name={`tenants.${index}.last_name`}
                    control={control}
                    render={(field) => (
                      <TextField
                        onChange={(e) => field.onChange(e)}
                        id="outlined-basic"
                        label="Last name*"
                        value={field.value}
                      />
                    )}
                  />
                  {errors.tenants && errors?.tenants[index]?.last_name && (<p className={`${styles.ErrorM}`}>{errors?.tenants[index]?.last_name?.message}</p>)}
                </div>
                <div className={`${styles.HalfSecHalf} FormGroup`}>
                  <Controller
                    name={`tenants.${index}.email`}
                    control={control}
                    render={(field) => (
                      <TextField
                        onChange={(e) => field.onChange(e)}
                        id="outlined-basic"
                        label="Email*"
                        value={field.value}
                      />
                    )}
                  />
                  {errors.tenants && errors?.tenants[index]?.email && (<p className={`${styles.ErrorM}`}>{errors?.tenants[index]?.email?.message}</p>)}
                </div>
                <div className={`${styles.HalfSecHalf} FormGroup`}>
                  <Controller
                    name={`tenants.${index}.phone`}
                    control={control}
                    render={(field) => (
                      <TextField
                        onChange={(e) => field.onChange(e)}
                        id="outlined-basic"
                        label="Phone No*"
                        value={field.value}
                        InputProps={{
                          inputComponent: PhoneField,
                        }}
                      />
                    )}
                  />
                  {errors.tenants && errors?.tenants[index]?.phone && (<p className={`${styles.ErrorM}`}>{errors?.tenants[index]?.phone?.message}</p>)}
                </div>
              </div>);
            })}
            {fields.length <= 3 && (<div className={`${styles.AddTenantBU}`}>
              {fields.length > 1 && (<button type="button" className={`${styles.Delete}`} onClick={remove}><Trash2 /> Delete Tenant</button>)}
              {fields.length < 3 && (<button type="button" onClick={() => append({ first_name: "", last_name: "", email: "", phone: "" })}><Plus /> Add Another Tenant</button>)}
            </div>)}
          </div>
          <div className={`${styles.AddTenantHalfSecBig}`}>
            <div className={`${styles.AddTenantHalfSecRow}`}>
              <div className={`${styles.HalfSecHalf} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel className={selectedApartment ? `` : `DisabledLabel`}>Lease type*</InputLabel>
                  <Controller
                    name="lease_type"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Lease type*"
                        onChange={(e) => {
                          field.onChange(e);
                          setSelectLeaseType(e.target.value);
                        }}
                        disabled={!selectedApartment}
                      >
                        <MenuItem value="New">New Lease</MenuItem>
                        <MenuItem value="Renewal">Lease Renewal</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.lease_type && (<p className={`${styles.ErrorM}`}>{errors.lease_type.message}</p>)}
              </div>
              <div className={`${styles.HalfSecHalf} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel className={selectedApartment ? `` : `DisabledLabel`}>Lease term*</InputLabel>
                  <Controller
                    name="lease_term"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Lease term*"
                        onChange={(e) => {
                          field.onChange(e);

                          if (e.target.value === "6 Months") {
                            setValue("end_date", dayjs(getValues("start_date")).add(6, "months").subtract(1, "day"));
                          }
                          if (e.target.value === "1 Year") {
                            setValue("end_date", dayjs(getValues("start_date")).add(1, "year").subtract(1, "day"));
                          }
                          if (e.target.value === "2 Years") {
                            setValue("end_date", dayjs(getValues("start_date")).add(2, "years").subtract(1, "day"));
                          }
                          if (e.target.value === "3 Years") {
                            setValue("end_date", dayjs(getValues("start_date")).add(3, "years").subtract(1, "day"));
                          }
                        }}
                        disabled={!selectedApartment}
                      >
                        <MenuItem value="6 Months">6 Months</MenuItem>
                        <MenuItem value="1 Year">1 Year</MenuItem>
                        <MenuItem value="2 Years">2 Years</MenuItem>
                        <MenuItem value="3 Years">3 Years</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.lease_term && (<p className={`${styles.ErrorM}`}>{errors.lease_term.message}</p>)}
              </div>
              {selectLeaseType === "New" && (
                <div className={`${styles.HalfSecHalf} FormGroup`}>
                  <Controller
                    name="security_amount"
                    control={control}
                    render={(field) => (
                      <MoneyField
                        {...field}
                        id="outlined-basic"
                        variant="outlined"
                        label="Security Amount*"
                        value={field.value ? field.value : ""}
                        disabled={!selectedApartment}
                      />
                    )}
                  />
                  {errors.security_amount && (<p className={`${styles.ErrorM}`}>{errors.security_amount.message}</p>)}
                </div>
              )}
              {selectLeaseType === "Renewal" && (<>
                <div className={`${styles.HalfSecHalfRow}`}>
                  <div className={`${styles.HalfSecHalf} FormGroup`}>
                    <Controller
                      name="security_amount"
                      control={control}
                      render={(field) => (
                        <MoneyField
                          {...field}
                          id="outlined-basic"
                          variant="outlined"
                          label="Old Security Amount*"
                          value={field.value ? field.value : ""}
                          disabled={!selectedApartment}
                        />
                      )}
                    />
                    {errors.security_amount && (<p className={`${styles.ErrorM}`}>{errors.security_amount.message}</p>)}
                  </div>
                  <div className={`${styles.HalfSecHalf} FormGroup`}>
                    <Controller
                      name="new_security_amount"
                      control={control}
                      render={(field) => (
                        <MoneyField
                          {...field}
                          id="outlined-basic"
                          variant="outlined"
                          label="New Security Amount*"
                          value={field.value ? field.value : ""}
                          disabled={!selectedApartment}
                        />
                      )}
                    />
                    {errors.new_security_amount && (<p className={`${styles.ErrorM}`}>{errors.new_security_amount.message}</p>)}
                  </div>
                </div>
              </>)}
              <div className={getValues("lease_type") === "Renewal" ? `${styles.HalfSecHalf} FormGroup ${styles.HalfSecHalfRes}` : `${styles.HalfSecHalf} FormGroup`}>
                <Controller
                  name="rent"
                  control={control}
                  render={(field) => (
                    <MoneyField
                      {...field}
                      id="outlined-basic"
                      variant="outlined"
                      label={getValues("lease_type") === "Renewal" ? "New Monthly Rent*" : "Monthly Rent*"}
                      value={field.value ? field.value : ""}
                      disabled={!selectedApartment}
                    />
                  )}
                />
                {errors.rent && (<p className={`${styles.ErrorM}`}>{errors.rent.message}</p>)}
              </div>
              <div className={`${styles.HalfSecHalf} ${styles.PaddCus} ${styles.CusWidth}`}>
                <div className={`${styles.AddTenantHalfSecRow} ${styles.PaddCus}`}>
                  <div className={`${styles.HalfSecHalf} FormGroup`}>
                    <Controller
                      name="start_date"
                      control={control}
                      render={(field) => (
                        <DatePicker
                          {...field}
                          label="Lease Start Date*"
                          renderInput={(params) => (
                            <TextField {...params} />
                          )}
                          onChange={(e) => {
                            field.onChange(e);
                            if (getValues("lease_term") === "6 Months") {
                              setValue("end_date", dayjs(e).add(6, "months").subtract(1, "day"));
                            } else if (getValues("lease_term") === "1 Year") {
                              setValue("end_date", dayjs(e).add(1, "year").subtract(1, "day"));
                            } else if (getValues("lease_term") === "2 Years") {
                              setValue("end_date", dayjs(e).add(2, "years").subtract(1, "day"));
                            } else if (getValues("lease_term") === "3 Years") {
                              setValue("end_date", dayjs(e).add(3, "years").subtract(1, "day"));
                            } else {
                              setValue("end_date", dayjs(e).add(1, "year").subtract(1, "day"));
                            }
                            clearErrors(`end_date`);
                          }}
                          disabled={!selectedApartment}
                        />
                      )}
                    />
                    {errors.start_date && (<p className={`${styles.ErrorM}`}>{errors.start_date.message}</p>)}
                  </div>
                  <div className={`${styles.HalfSecHalf} FormGroup`}>
                    <Controller
                      name="end_date"
                      control={control}
                      render={(field) => (
                        <DatePicker
                          {...field}
                          label="Lease End Date*"
                          renderInput={(params) => (
                            <TextField {...params} />
                          )}
                          onChange={(e) => {
                            field.onChange(e);
                            if (getValues("lease_term") === "6 Months") {
                              setValue("start_date", dayjs(e).subtract(6, "months").add(1, "day"));
                            } else if (getValues("lease_term") === "1 Year") {
                              setValue("start_date", dayjs(e).subtract(1, "year").add(1, "day"));
                            } else if (getValues("lease_term") === "2 Years") {
                              setValue("start_date", dayjs(e).subtract(2, "years").add(1, "day"));
                            } else if (getValues("lease_term") === "3 Years") {
                              setValue("start_date", dayjs(e).subtract(3, "years").add(1, "day"));
                            } else {
                              setValue("start_date", dayjs(e).subtract(1, "year").add(1, "day"));
                            }

                            clearErrors(`start_date`);
                          }}
                          disabled={!selectedApartment}
                        />
                      )}
                    />
                    {errors.end_date && (<p className={`${styles.ErrorM}`}>{errors.end_date.message}</p>)}
                  </div>
                </div>
              </div>
              <div className={`${styles.HalfSecHalf} ${styles.PaddCus} ${styles.CusWidth}`}>
                <div className={`${styles.AddTenantHalfSecRow} ${styles.PaddCus}`}>
                  {selectLeaseType === "Renewal" && (<>
                    <div className={`${styles.HalfSecHalf} FormGroup`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" className={selectedApartment ? `` : `DisabledLabel`}>Payment received for Security Increase & Rent?*</InputLabel>
                        <Controller
                          name="payment_received"
                          control={control}
                          render={(field) => (
                            <Select
                              {...field}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Payment received for Security Increase & Rent?*"
                              onChange={(e) => {
                                field.onChange(e);

                                if (e?.target?.value === "Yes") {
                                  setIsPaymentReceived(true);
                                }
                                if (e?.target?.value === "No") {
                                  setIsPaymentReceived(false);
                                }
                              }}
                              disabled={!selectedApartment}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                      {errors.payment_received && (<p className={`${styles.ErrorM}`}>{errors.payment_received.message}</p>)}
                    </div>
                    <div className={`${styles.HalfSecHalf} FormGroup`}>
                      <Controller
                        name="payment_date"
                        control={control}
                        render={(field) => (
                          <DatePicker
                            {...field}
                            label="Payment Date*"
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            disabled={!(selectedApartment && isPaymentReceived)}
                          />
                        )}
                      />
                      {errors.payment_date && (<p className={`${styles.ErrorM}`}>{errors.payment_date.message}</p>)}
                    </div>
                  </>)}
                </div>
              </div>
              <div className={`${styles.HalfSecFull} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel className={selectedApartment ? `` : `DisabledLabel`}>Do you want to upload an existing lease?*</InputLabel>
                  <Controller
                    name="is_existing"
                    control={control}
                    render={(field) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Do you want to upload an existing lease?*"
                        onChange={(e) => {
                          field.onChange(e);

                          if (e?.target?.value === "Yes") {
                            setOpenUploadArea(true);
                            setOpenSelectArea(false);
                          }
                          if (e?.target?.value === "No") {
                            setOpenUploadArea(false);
                            setOpenSelectArea(true);
                          }
                        }}
                        disabled={!selectedApartment}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.is_existing && (<p className={`${styles.ErrorM}`}>{errors.is_existing.message}</p>)}
              </div>
              {openSelectArea && (<div className={`${styles.HalfSecFull} FormGroup`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select property lease documents*</InputLabel>
                  <Controller
                    name="docume_ids"
                    control={control}
                    render={(field) => (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectDocument}
                        onChange={selectDocuments}
                        onClick={(e) => {
                          if ((typeof e.target?.parentElement?.classList?.contains("MenuListNew") !== "undefined" && e.target?.parentElement?.classList.contains("MenuListNew")) || (typeof e.target?.parentElement?.parentElement?.classList?.contains("MenuListNew") !== "undefined" && e.target?.parentElement?.parentElement?.classList.contains("MenuListNew"))) {
                            setDropdownOpen(true);
                          } else if (e.target.localName === "span" && e.target.parentElement.classList.contains("DropListNew")) {
                            setDropdownOpen(false);
                          } else if (e.target.localName === "div" && ((typeof e.target?.parentElement?.classList?.contains("DropListNew") !== "undefined" && e.target?.parentElement?.classList.contains("DropListNew")) || (typeof e.target?.parentElement?.parentElement?.classList?.contains("DropListNew") !== "undefined" && e.target?.parentElement?.parentElement?.classList.contains("DropListNew")))) {
                            setDropdownOpen(false);
                          } else {
                            setDropdownOpen((prev) => !prev);
                          }
                        }}
                        multiple
                        input={<OutlinedInput label="Select property lease documents*" />}
                        open={dropdownOpen}
                        renderValue={(e) => (
                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }} className={"DropListNew"}>
                            <ListManager
                              className={"DropListNew"}
                              items={sortedLeaseDocuments}
                              direction="horizontal"
                              maxItems={40}
                              render={(item) => (
                                <ListElement item={item} />
                              )}
                              onDragEnd={reorderList}
                            />
                          </Box>
                        )}
                      >
                        {LeaseDocumentOptions.map((item) => (
                          <MenuItem key={item.id} value={item} className={"MenuListNew"}>
                            <Checkbox checked={selectDocumentIds.indexOf(item.id) > -1} />
                            <ListItemText primary={item.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.docume_ids && (<p className={`${styles.ErrorM}`}>{errors.docume_ids.message}</p>)}
              </div>)}

              {openUploadArea && (<div className={`${styles.HalfSecFull}`}>
                <div className={`${styles.FileUpDuBUDiv}`}>
                  <label for="file-upload" className={`${styles.UploadLabel}`}>
                    <span>
                      <div className={`${styles.UpIcon}`}>
                        <img src="/static/img/upload-floor.svg" alt="" />
                      </div>
                      <p>Upload Existing Lease</p>
                      <p className={`${styles.UploadText}`}>File size upto 10 MB <br /> PDF File only</p>
                    </span>
                  </label>
                  <input id="file-upload" type="file" onChange={fileHandler} accept=".pdf" />
                  <input className="inputBtn" type="hidden" {...register("document_files")} />
                </div>
                {errors.document_files && (<p className={`${styles.ErrorM}`}>{errors.document_files.message}</p>)}
                <div className={`${styles.UpIMGSec}`}>
                  {uploadFiles?.map((item, index) => {
                    return (<div className={`${styles.ImgFile}`} id={index}>
                      <div className={`${styles.ImgFileUnder}`}>
                        <p className={`${styles.FileName}`}>
                          <img src="/static/img/pdf-file.png" alt="" />
                          <span>{item?.name}</span>
                        </p>
                        <p className={`${styles.FileSize}`}>{moment().format("MM/DD/YYYY hh:mm A")}</p>
                        <div className={`${styles.FileListRight}`}>
                          <Button className={`${styles.FileListDelete}`} onClick={removeFile.bind(this, index)}><Trash2 /></Button>
                        </div>
                      </div>
                    </div>);
                  })}
                </div>
              </div>)}
            </div>
          </div>
          <div className={`${styles.ButtonAction}`}>
            <button type="button" className="CancelPopupBU" onClick={(e) => closeAddForm(false)}>Cancel</button>
            <button className="SubmitPopupBU">Add</button>
          </div>
        </div>
      </LocalizationProvider>
    </form>)}
  </>);
};

export default AddForm;
